import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Add.css";
import Dashboard from "../../Dashboard/Dashboard";
import { useLocation, Link } from "react-router-dom";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  Tooltip ,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const View = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [siaTypes, setSiaTypes] = useState({});

  useEffect(() => {
    fetchEmployees();
    fetchSiaTypes();
  }, []);

  useEffect(() => {
    if (location.state && location.state.newEmployee) {
      setEmployees((prevEmployees) => [
        location.state.newEmployee,
        ...prevEmployees,
      ]);
    }
  }, [location.state]);

  useEffect(() => {
    const filtered = employees.filter((employee) =>
      Object.values(employee).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredEmployees(filtered);
    setCurrentPage(0);
  }, [searchTerm, employees]);


  const fetchEmployees = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/employee_show",
        {},
        {
          headers: {
            Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
          },
        }
      );
      if (response.data && response.data.success) {
        setEmployees(response.data.data);
        setFilteredEmployees(response.data.data);
      } else {
        setError("No employee data found");
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching employees:", err);
      setError("Error fetching employees: " + err.message);
      setLoading(false);
    }
  };

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sia_types_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      if (response.data && response.data.success) {
        const siaTypeArray = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const siaTypesMap = siaTypeArray.reduce((acc, type) => {
          acc[type.id] = type.employee_sia_type;
          return acc;
        }, {});
        setSiaTypes(siaTypesMap);
      } else {
        console.error('Failed to fetch SIA types');
      }
    } catch (error) {
      console.error('Error fetching SIA types:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/employee_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Employee has been deleted.',
            'success'
          );
          fetchEmployees();
        } else {
          throw new Error(response.data.message || "Failed to delete Employee");
        }
      }
    } catch (error) {
      console.error("Delete Employee Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Employee.'
      });
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEmployees);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");
    XLSX.writeFile(workbook, "employees_data.xlsx");
  };

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'View', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };


  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;

};
  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <div className="">
          <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
            {navigationPath.map((part, index) => (
              <React.Fragment key={index}>
                <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                  {part.name}
                </span>
                {index < navigationPath.length - 1 && " / "}  
              </React.Fragment>
            ))}
          </h3>        
        </div>
      </div>

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>PIN</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Full Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  {/* <TableCell sx={{ fontSize: '15px'}}>Post Code</TableCell> */}
                  <TableCell sx={{ fontSize: '15px'}}>SIA Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Expiry Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Type</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Job Title</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Department</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{padding:'8px'}}>
                {filteredEmployees
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((employee, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell  sx={{ padding: '5px',paddingLeft:'5px'}}>{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{employee.employee_staff_pin}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>
                      {employee.employee_forename}
                      {employee.employee_middname && ` ${employee.employee_middname}`}
                      {employee.employee_surname && ` ${employee.employee_surname}`}
                    </TableCell>
                    <TableCell sx={{ padding: '5px' }}>{employee.employee_contactno}</TableCell>
                    {/* <TableCell sx={{ padding: '5px' }}>{employee.employee_postcode}</TableCell> */}
                    <TableCell sx={{ padding: '5px' }}>{employee.employee_sia_no}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{formatDate(employee.employee_expdate)}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{siaTypes[employee.employee_sia_type] || employee.employee_sia_type}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{employee.employee_jobname}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{employee.employee_department}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>
  <Tooltip 
    title="Employee View" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      component={Link} 
      to="/viewemployeeinfo" 
      size="small" 
      sx={{ 
        color: 'white', 
        backgroundColor: 'green', 
        '&:hover': { backgroundColor: 'darkblue' },
        marginRight: '8px'
      }}
    >
      <VisibilityIcon />
    </IconButton>
  </Tooltip>

  <Tooltip 
    title="Edit Employee" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      component={Link} 
      to="/employeeform" 
      size="small" 
      sx={{ 
        color: 'white', 
        backgroundColor: 'blue', 
        '&:hover': { backgroundColor: 'darkgreen' },
        marginRight: '8px'
      }}
    >
      <EditIcon />
    </IconButton>
  </Tooltip>

  <Tooltip 
    title="Employee Checklist" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      component={Link} 
      to="/employeechecklist" 
      size="small" 
      sx={{ 
        color: 'white', 
        backgroundColor: 'orange', 
        '&:hover': { backgroundColor: 'darkorange' },
        marginRight: '8px'
      }}
    >
      <ListAltIcon />
    </IconButton>
  </Tooltip>

  <Tooltip 
    title="Delete Employee" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      onClick={() => handleDelete(employee.id)} 
      size="small" 
      sx={{ 
        color: 'white', 
        backgroundColor: 'red', 
        '&:hover': { backgroundColor: 'darkred' }
      }}
    >
      <DeleteIcon />
    </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEmployees.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default View;