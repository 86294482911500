import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from "axios";
import * as XLSX from 'xlsx';
import { Container, Row, Col, Form } from 'react-bootstrap';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  TextField,
} from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import Dashboard from "../../../Dashboard/Dashboard";
import PrintLayout from "./Print";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const LogReport = () => {
  const [logData, setLogData] = useState([]);
  const [filteredLogData, setFilteredLogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const componentRef = useRef();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [eventType, setEventType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchLogData();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [startDate,endDate]);

  const fetchLogData = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/get_log_report',
        {},
        {
          headers: {
            'Authorization': '115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setLogData(response.data.data);
      setFilteredLogData(response.data.data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    }
  };

  const handleFilter = (e) => {
    const filtered = logData.filter(log => {
      const eventDate = new Date(log.date);
      const typeMatch = log.type.toLowerCase().includes(eventType.toLowerCase());
      const dateMatch = (!startDate || eventDate >= startDate) && (!endDate || eventDate <= endDate);
      const searchMatch = Object.values(log).some(value => 
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      return typeMatch && dateMatch && searchMatch;
    });
    setFilteredLogData(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setDateRange([null, null]);
    setEventType("");
    setSearchTerm("");
    setFilteredLogData(logData);
    setCurrentPage(0);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Log Report',
    onAfterPrint: () => alert('Print successful!'),
  });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredLogData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Log Report");
    XLSX.writeFile(workbook, "LogReport.xlsx");
  };

  const getNavigationPath = () => [
    { name: 'Report', bold: false },
    { name: 'Log Report', bold: true },
  ];

  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {getNavigationPath().map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < getNavigationPath().length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <Container className="w-100">
        <Form onSubmit={handleFilter}>
          <Row className="mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Date</Form.Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            
            <Col md={3} className="d-flex align-items-end">
            <Button type="button" variant="contained" color="secondary" className="me-2" style={{padding:'5px 20px'}}>Filter</Button>
              <Button type="button" variant="contained" color="secondary" className="me-2" style={{padding:'5px 20px'}} onClick={handleReset}>Reset</Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'84%', mb: 2 }} ref={componentRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  {['S.NO','Reference Id', 'Date','Type','Message'].map((header) => (
                    <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogData
                  .slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage)
                  .map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{currentPage * entriesPerPage + index + 1}</TableCell>
                      <TableCell>{log.ref_id}</TableCell>
                      <TableCell>{log.date}</TableCell>
                      <TableCell>{log.type}</TableCell>
                      <TableCell>{log.msg}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={filteredLogData.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>

      <div style={{ display: 'none' }}>
        <PrintLayout ref={componentRef} filteredLogData={filteredLogData}/>
      </div>
    </>
  );
};

export default LogReport;