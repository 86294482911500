import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button as BootstrapButton } from 'react-bootstrap';
import axios from "axios";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
} from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import Dashboard from "../../../Dashboard/Dashboard";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';


const SubEmployeeDocumentReport = () => {
  const [documentData, setDocumentData] = useState([]);
  const [filteredDocumentData, setFilteredDocumentData] = useState([]);
  const [employee, setEmployee] = useState("");
  const [date, setDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const componentRef = useRef();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    fetchDocumentData();
  }, []);

  
  const fetchDocumentData = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/subcon_document_report',
        {},
        {
          headers: {
            'Authorization': '115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setDocumentData(response.data.data);
      setFilteredDocumentData(response.data.data);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filtered = documentData.filter(doc => {
      const employeeMatch = employee === "" || doc.employee.toLowerCase().includes(employee.toLowerCase());
      
      let dateMatch = true;
      if (startDate && endDate) {
        const docDate = new Date(doc.created_at);
        dateMatch = docDate >= startDate && docDate <= endDate;
      }
      
      return employeeMatch && dateMatch;
    });
    setFilteredDocumentData(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setEmployee("");
    setDateRange([null, null]);
    setFilteredDocumentData(documentData);
    setCurrentPage(0);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'SubEmployee Document Report',
    onAfterPrint: () => alert('Print successful!'),
  });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredDocumentData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SubEmployee Document Report");
    XLSX.writeFile(workbook, "SubEmployeeDocumentReport.xlsx");
  };

  const getNavigationPath = () => [
    { name: 'Report', bold: false },
    { name: 'Hr Report', bold: false },
    { name: 'SubEmployee Document Report', bold: true },
  ];

  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {getNavigationPath().map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < getNavigationPath().length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <Container className="w-100">
      <Form onSubmit={handleFilter}>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Employee</Form.Label>
                <Form.Control 
                  type="text" 
                  value={employee}
                  onChange={(e) => setEmployee(e.target.value)}
                  style={{width:'200px',padding:'5px'}} 
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Date </Form.Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  style={{width:'200px'}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootstrapButton type="submit" variant="primary" className="me-2" style={{padding:'5px 20px'}}>Filter</BootstrapButton>
              <BootstrapButton type="button" variant="secondary" className="me-2" style={{padding:'5px 20px'}} onClick={handleReset}>Reset</BootstrapButton>
            </Col>
          </Row>
        </Form>
      </Container>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'84%', mb: 2 }} ref={componentRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
             
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  {['S.NO', 'Employee', 'Document Type', 'Copy Type', 'Comments', 'Created At'].map((header) => (
                    <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocumentData
                  .slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage)
                  .map((doc, index) => (
                    <TableRow key={doc.id} hover>
                      <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.employee}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.type_document}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.copy_type}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.comments}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.created_at}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={filteredDocumentData.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default SubEmployeeDocumentReport;