import React, { useState ,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import { Modal, Form,Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons'; 
import Swal from 'sweetalert2';
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Checkbox } from '@mui/material';
  import Select from 'react-select';
  import { Visibility } from '@mui/icons-material';
  import { styled } from '@mui/material/styles';

const PayrollPayments = () => {
  const [showPayments, setShowPayments] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [selectedPayroll, setSelectedPayroll] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showPayrollTable, setShowPayrollTable] = useState(false);
  const [showEmployeeTable, setShowEmployeeTable] = useState(false);
  const location = useLocation();

const [formData,setFormData] = useState({
  shift_mode:""
})

const [selectPayroll, setSelectPayroll] = useState(null);
const [payrollOptions, setPayrollOptions] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [payrollData, setPayrollData] = useState(null);
const [openModal, setOpenModal] = useState(false);
const [selectedTimeData, setSelectedTimeData] = useState(null);
console.log("Timestamp View Data:",selectedTimeData)
 const tooltipStyles = {
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: '#fff',
    fontSize: '14px',
    padding: '8px 12px',
    borderRadius: '4px',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};

const [showImageModal, setShowImageModal] = useState(false);
const [selectedImage, setSelectedImage] = useState(null);

const handleImageClick = (imageUrl, latitude, longitude) => {
  if (imageUrl) {
    const fullImageUrl = imageUrl.startsWith('http') 
      ? imageUrl 
      : `https://srltd.megworld.in/${imageUrl}`;
    setSelectedImage(fullImageUrl);
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    setShowImageModal(true);
  } else {
    console.error("Image URL is undefined or null");
  }
};

const [selectedRows, setSelectedRows] = useState([]);

const [netAmounts, setNetAmounts] = useState({});
const [extraAmounts, setExtraAmounts] = useState({});
const [totalAmounts, setTotalAmounts] = useState({});
const [overallTotalAmount, setOverallTotalAmount] = useState(0);
const [paidAmounts, setPaidAmounts] = useState({});

// Calculate net amount based on the formula: (total_time * bill_amount) + bill_expense
const calculateNetAmount = (staffData) => {
  const totalTime = parseFloat(staffData.total_time) || 0;
  const billAmount = parseFloat(staffData.shift_pay_amount) || 0;
  const billExpense = parseFloat(staffData.shift_pay_expenses) || 0;
  return (totalTime * billAmount) + billExpense;
};

// Initialize calculations when payroll data changes
useEffect(() => {
  if (payrollData?.staffData) {
    const newNetAmounts = {};
    const newTotalAmounts = {};
    let newOverallTotal = 0;

    payrollData.staffData.forEach((staff, index) => {
      // Calculate net amount using the correct formula
      const netAmount = calculateNetAmount(staff);
      newNetAmounts[index] = netAmount;
      
      // Calculate total amount by adding extra amount (if any)
      const totalAmount = netAmount + (extraAmounts[index] || 0);
      newTotalAmounts[index] = totalAmount;
      
      // Add to overall total
      newOverallTotal += totalAmount;
    });

    setNetAmounts(newNetAmounts);
    setTotalAmounts(newTotalAmounts);
    setOverallTotalAmount(newOverallTotal);
  }
}, [payrollData, extraAmounts]);

// Handle extra amount changes
const handleExtraAmountChange = (e, index) => {
  const value = parseFloat(e.target.value) || 0;
  setExtraAmounts(prev => {
    const newExtraAmounts = { ...prev, [index]: value };
    return newExtraAmounts;
  });
};



const [selectedStaffIds, setSelectedStaffIds] = useState([]);
const [isProcessingPayment, setIsProcessingPayment] = useState(false);

// Update your handleRowSelect function to also store staff IDs
const handleRowSelect = (event, rowIndex, staffId) => {
  setSelectedRows(prevSelected => {
    const selectedIndex = prevSelected.indexOf(rowIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...prevSelected, rowIndex];
    } else if (selectedIndex === 0) {
      newSelected = prevSelected.slice(1);
    } else if (selectedIndex === prevSelected.length - 1) {
      newSelected = prevSelected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...prevSelected.slice(0, selectedIndex),
        ...prevSelected.slice(selectedIndex + 1),
      ];
    }
    const newStaffIds = newSelected.map(index => payrollData.staffData[index].staff_id);
    setSelectedStaffIds(newStaffIds);

    return newSelected;
  });
};

const handlePaidAmountChange = (index, value) => {
  setPaidAmounts(prev => ({
    ...prev,
    [index]: parseFloat(value) || 0
  }));
};



const handleAddPayment = async () => {
  if (selectedRows.length === 0) {
    Swal.fire({
      title: 'Selection Required',
      text: 'Please select at least one row to process payment.',
      icon: 'warning',
    });
    return;
  }

  const result = await Swal.fire({
    title: 'Confirm Payment',
    text: `Are you sure you want to process payment for ${selectedRows.length} selected staff?`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, process payment'
  });

  if (!result.isConfirmed) return;

  setIsProcessingPayment(true);

  try {
    const amountArray = selectedRows.map(index => paidAmounts[index] || 0);
    
    const payroll_id = payrollData.id;
    const payroll_no = payrollData.payroll_no;
    
    const staff_ids = [];
    const amounts = [];
    const staff_names = [];
    const total_amounts = [];
    
    // Populate these arrays based on selected rows
    selectedRows.forEach(index => {
      staff_ids.push(payrollData.staffData[index].id);
      amounts.push(paidAmounts[index] || 0);
      staff_names.push(payrollData.staffData[index].shift_assign_staff);
      total_amounts.push(parseFloat(totalAmounts[index]?.toFixed(2)) || 0);
    });
    
    // Create the paymentData object with flat arrays
    const paymentData = {
      payroll_id: payroll_id,
      payroll_no: payroll_no,
      staff_id: staff_ids,
      amount: amounts,
      staff: staff_names,
      total_amount: total_amounts
    };

    console.log('Payment Data:', paymentData);

    const response = await axios.post(
      'https://srltd.megworld.in/api/save_payroll_data',
      paymentData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data.success) {
      Swal.fire({
        title: 'Success!',
        text: 'Payment processed successfully',
        icon: 'success',
      });
      setSelectedRows([]);
      setSelectedStaffIds([]);
      setPaidAmounts({});
    } else {
      throw new Error(response.data.message || 'Failed to process payment');
    }
  } catch (error) {
    console.error('Error processing payment:', error);
    Swal.fire({
      title: 'Error!',
      text: error.message || 'An error occurred while processing payment',
      icon: 'error',
    });
  } finally {
    setIsProcessingPayment(false);
  }
};

const handleReset = async () => {
  if (selectedRows.length === 0) return;

  const result = await Swal.fire({
    title: 'Confirm Reset',
    text: 'Are you sure you want to clear all selections?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, clear selections'
  });

  if (result.isConfirmed) {
    setSelectedRows([]);
    Swal.fire({
      title: 'Reset Complete',
      text: 'All selections have been cleared',
      icon: 'success',
      confirmButtonColor: '#3085d6'
    });
  }
};



const handleSearchChange = (e) => {
  setSearchBy(e.target.value);
  setSelectPayroll(null);
};

const handleChange = (selectedOption) => {
  setSelectPayroll(selectedOption);
};

const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";


const fetchPayrollOptions = async (payroll) => {
  setIsLoading(true);
  try {
    const response = await fetch('https://srltd.megworld.in/api/payroll_no_auto', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
      },
    });
    const data = await response.json();
    if (data.success) {
      const options = data.data.map(item => ({
        value: item.id,
        label: item.payroll_no
      }));
      setPayrollOptions(options);
      console.log("Payroll Auto Response:",options)
    } else {
      console.error('Failed to fetch payroll options:', data.message);
    }
  } catch (error) {
    console.error('Error fetching payroll options:', error);
  }
  setIsLoading(false);
};

useEffect(() => {
  if (searchBy === "payrolls") {
    fetchPayrollOptions();
  }
}, [searchBy]);

  const handleShowPayments = () => setShowPayments(true);
  const handleClosePayments = () => {
    setShowPayments(false);
    setSearchBy("");
    setSelectedPayroll(null);
    setShowPayrollTable(false);
    setSelectedEmployee("");
    setStartDate("");
    setEndDate("");
  };

  const handleSearchByChange = (e) => {
    setSearchBy(e.target.value);
    setSelectedPayroll(null);
    // setSelectedEmployee("");
    setShowPayrollTable(false);
    // setShowEmployeeTable(false);
  };

  const handlePayrollChange = (selectedOption) => {
    setSelectPayroll(selectedOption);
  };

  const handleSearch = async () => {
    if (searchBy === "payrolls" && selectPayroll) {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/get_payroll_data",
          { payroll_no: selectPayroll.value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.success) {
          setPayrollData(response.data.data[0]);
          setShowPayrollTable(true);
          handleClosePayments();
        }
      } catch (error) {
        console.error('Error fetching payroll data:', error);
      }
    }
  };


  const handleOpenModal = (timeData) => {
    setOpenModal(true);
    console.log("Timestamp Data:",timeData)
    setSelectedTimeData(timeData);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };



  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  // const handleSearch = () => {
  //   if (searchBy === "payrolls" && selectedPayroll) {
  //     setShowPayrollTable(true);
  //     setShowEmployeeTable(false);
  //     handleClosePayments();
  //   } else if (searchBy === "employees" && selectedEmployee && startDate && endDate) {
  //     setShowEmployeeTable(true);
  //     setShowPayrollTable(false);
  //     handleClosePayments();
  //   }
  // };

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Payroll', bold: false },
      { name: 'Manage Staff Payments', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();

 
 
  const [shiftModes, setShiftModes] = useState([]);
  const [selectedMode,setSelectedMode] = useState([])
     useEffect(() => {
      fetchShiftModes();
    }, []);
  
    const fetchShiftModes = async (id) => {
      try {
        const response = await axios.post(
          'https://srltd.megworld.in/api/shift_mode_auto',
          { id:3},
          {
            headers: {
              'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
            }
          }
        );
        console.log("Shift Mode:",response)
        const modes = response.data.map(item => ({
          value: item.shift_mode,
          label: item.shift_mode
        }));
        setShiftModes(modes);
      } catch (error) {
        console.error('Error fetching shift modes:', error);
      }
    };
  
    const handleShiftModeChange = (selectedSubOptions) => {
      setSelectedMode(selectedSubOptions);
      setFormData(prevState => ({
        ...prevState,
        shift_mode: selectedSubOptions.map(option => option.label).join(', ')
      }));
    };
  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2 d-flex justify-content-between align-items-center" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}
        </h3>         
        <div className="d-flex" style={{marginRight:'90px'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowPayments}
            sx={{ padding: '8px', marginLeft: '130px' }}
          >
            Show Records
          </Button>
        </div>
      </div>

      <Modal show={showPayments} onHide={handleClosePayments} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Search Payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Search By</Form.Label>
              <Form.Select onChange={handleSearchByChange} value={searchBy}>
                <option value="">Select...</option>
                <option value="payrolls">Payrolls</option>
z                {/* <option value="employees">Employees</option> */}
              </Form.Select>
            </Form.Group>
            {searchBy === "payrolls" && (
        <Form.Group className="mb-3">
          <Form.Label style={{ float: "left" }}>Payrolls</Form.Label>
          <Select
           styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}  
value={selectPayroll}
onChange={handlePayrollChange}
options={payrollOptions}
isSearchable
            isLoading={isLoading}
            placeholder="Select or search for a payroll..."
            noOptionsMessage={() => "No payrolls found"}
          />
        </Form.Group>
        
      )}
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button  className="me-2" variant="contained" color="primary" onClick={handleSearch}>SEARCH</Button>
          <Button variant="contained" color="secondary" onClick={handleClosePayments}>CLOSE</Button>
        </Modal.Footer>
      </Modal>

      {payrollData && (
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
      <Paper sx={{width:'90%', mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
      <Typography variant="subtitle1">
              Shift Date: {payrollData.shiftData.shift_start_date} - {payrollData.shiftData.shift_end_date}
            </Typography>
            <Typography variant="subtitle1">
              Payroll No: {payrollData.payroll_no}
            </Typography>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>Shift Title</TableCell>
                  <TableCell>Shift Mode</TableCell>
                  <TableCell>Staff Name</TableCell>
                  <TableCell>Total Time</TableCell>
                  <TableCell>Shift Pay Amount</TableCell>
                  <TableCell>Shift Pay Expenses</TableCell>
                  <TableCell>Net Amount</TableCell>
                  <TableCell>Extra Amount</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Enter Paid Amount</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selectedRows.length > 0 && selectedRows.length < payrollData.staffData.length}
                        checked={selectedRows.length === payrollData.staffData.length}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedRows(payrollData.staffData.map((_, index) => index));
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                      />
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payrollData.staffData.map((staff, index) => (
                    <TableRow key={index}>
                  <TableCell>{payrollData.shift_title}</TableCell>
                  <TableCell>{payrollData.shift_mode}</TableCell>
                  <TableCell>{payrollData.staffData[0].shift_assign_staff}</TableCell>
                  <TableCell>{payrollData.shiftData.total_time}</TableCell>
                  <TableCell>{payrollData.staffData[0].shift_pay_amount}</TableCell>
                  <TableCell>{payrollData.staffData[0].shift_pay_expenses}</TableCell>
                  <TableCell>{netAmounts[index]?.toFixed(2) || '0.00'}</TableCell>
                  <TableCell>
                        <TextField
                          type="number"
                          value={extraAmounts[index] || 0}
                          onChange={(e) => handleExtraAmountChange(e, index)}
                          size="small"
                        />
                      </TableCell>
                     
                      <TableCell>{totalAmounts[index]?.toFixed(2) || '0.00'}</TableCell>
                                        <TableCell>{payrollData.staffData[0].amount}</TableCell>

                      <TableCell>
                      <TextField
            type="number"
            value={paidAmounts[index] || ''}
            onChange={(e) => handlePaidAmountChange(index, e.target.value)}
            size="small"
          />
                      </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={payrollData.if_pay ? "success" : "error"}
                    >
                      {payrollData.if_pay ? "Paid" : "Unpaid"}
                    </Button>
                  </TableCell>
                  <TableCell>
                  <Tooltip title="TimeStamp Data View" 
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: { sx: tooltipStyles.tooltip },
                          arrow: { sx: tooltipStyles.arrow },
                        }}>
                          <IconButton
                            onClick={() => handleOpenModal(payrollData.timeData)}
                            size="small"
                             sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                            '&:hover': {
                              backgroundColor: "primary.dark",
                            },
                            padding: "6px",
                            marginRight: "8px",
                          }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                  </TableCell>
                  <TableCell padding="checkbox">
                  <Checkbox
            checked={selectedRows.indexOf(index) !== -1}
            onChange={(event) => handleRowSelect(event, index, staff.staff_id)}
          />
        </TableCell>
                </TableRow>
                 ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ padding: '10px', borderTop: '1px solid #ddd', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Typography variant="subtitle1" component="span" sx={{ marginRight: '10px' }}>
                Overall Total Amount (including Extra):{overallTotalAmount.toFixed(2)}
              </Typography>
              <Typography variant="subtitle1" component="span" sx={{ marginRight: '10px' }}>Payment Date</Typography>
              <TextField
                type="date"
                variant="outlined"
                size="small"
                sx={{ marginRight: '20px' }}
              />


              {/* <TextField
                type="number"
                label="Extra Amount"
                variant="outlined"
                size="small"
                value={extraAmount}
                onChange={handleExtraAmountChange}
                sx={{ marginRight: '20px' }}
              />
              <Typography variant="subtitle1" component="span" sx={{ marginRight: '10px' }}>
              Total Amount: {totalAmount.toFixed(2)}
              </Typography> */}
                <Button
      variant="contained"
      color="primary"
      onClick={handleAddPayment}
      disabled={isProcessingPayment || selectedRows.length === 0}
      sx={{ marginRight: '20px' }}
    >
      {isProcessingPayment ? 'Processing...' : 'ADD PAYMENT'}
    </Button>
    <Button
      variant="contained"
      color="secondary"
      onClick={() => setSelectedRows([])}
    >
      RESET
    </Button>
            </Box>
          </Paper>
        </Box>
      )}

<Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Captured Image</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Captured Image"
              style={{
                maxWidth: '100%',
                maxHeight: '70vh',
                objectFit: 'contain'
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

   <Modal
        show={openModal}
        onHide={handleCloseModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Timestamp Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTimeData && selectedTimeData.length > 0 ? (
            <Table bordered className="table">
              <tbody>
                {selectedTimeData.map((time, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{width: '30%',fontSize:'16px',fontWeight:'bold'}}>Date</td>
                      <td>{time.date}</td>
                    </tr>
                    <tr>
                      <td style={{fontSize:'16px',fontWeight:'bold'}}>In Time</td>
                      <td>{time.in_time}</td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Out Time</td>
                      <td>{time.out_time}</td>
                    </tr>
                    <tr>
                      <td style={{fontSize:'16px', fontWeight:'bold'}}>Image</td>
                      <td>
                     
                        <FontAwesomeIcon icon={faImage} 
                          color="primary"
                          size="small"  onClick={() => handleImageClick(time.file)}/>
                        {/* {time.file ? time.file.split('/').pop() : 'No image'} */}
                      </td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Site</td>
                      <td>{time.site}</td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Shift Title</td>
                      <td>{time.shift_title}</td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Shift Mode</td>
                      <td>{time.shift_mode}</td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Name</td>
                      <td>{time.name}</td>
                    </tr>
                    <tr>
                      <td  style={{fontSize:'16px',fontWeight:'bold'}}>Status</td>
                      <td>{time.status === 1 ? 'Active' : 'Inactive'}</td>
                    </tr>
                    {index < selectedTimeData.length - 1 && (
                      <tr className="bg-light">
                        <td colSpan="2" style={{height: '10px'}}></td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="m-3">No time stamp data available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Form.Check 
            type="checkbox"
            label="Confirm Payment"
            className="me-auto"
          /> */}
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
     
    </>
  );
};

export default PayrollPayments;