import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import Swal from 'sweetalert2';
import Dashboard from "../Dashboard/Dashboard";
import { Control } from "leaflet";
import { format, parse, isValid } from 'date-fns';
import { useLocation,Link } from 'react-router-dom';
import Select from 'react-select';

const EditClientRoster = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [staffOptions, setStaffOptions] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [subOptions, setSubOptions] = useState([]);
    const [selectedSubStaff, setSelectedSubStaff] = useState([]);
  
    useEffect(() => {
        if (location.state && location.state.id) {
            console.log("Initial formData:", location.state.id);
            setFormData(location.state.id);
            fetchStaffData();
          fetchSubStaffData();        } else {
          Swal.fire('Error', 'No data to edit', 'error');
          navigate('/clientroster');
        }
      }, [location, navigate]);

      const parseOptions = (values, options) => {
        const valuesArray = values ? values.split(', ').map(value => value.trim()) : [];
        return options.filter(option => valuesArray.includes(option.value));
    };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const [shiftType, setShiftType] = useState('sameMultipleShifts');
      const [shifts, setShifts] = useState([{ shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);
    
   
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let shiftData = { ...formData };

        if (shiftType === 'sameMultipleShifts') {
          // Create arrays for the date range
          const startDate = new Date(formData.shift_start_date);
          const endDate = new Date(formData.shift_end_date);
          const dateArray = [];
          for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            dateArray.push(new Date(date).toISOString().split('T')[0]);
          }

          shiftData.shift_start_date = dateArray;
          shiftData.shift_end_date = dateArray;
          shiftData.shift_start_time = new Array(dateArray.length).fill(formData.shift_start_time);
          shiftData.shift_end_time = new Array(dateArray.length).fill(formData.shift_end_time);
          shiftData.shift_break = new Array(dateArray.length).fill(formData.shift_break);
        } else if (shiftType === 'differentMultipleShifts') {
          shiftData.shift_start_date = shifts.map(shift => shift.shift_start_date);
          shiftData.shift_start_time = shifts.map(shift => shift.shift_start_time);
          shiftData.shift_end_time = shifts.map(shift => shift.shift_end_time);
          shiftData.shift_break = shifts.map(shift => shift.shift_break);
          shiftData.shift_end_date = shiftData.shift_start_date; 
        }

          const response = await axios.post('https://srltd.megworld.in/api/add_shift_new', 
            formData,
            {
              headers: {
                'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
              }
            }
          );
          if (response.data.success) {
            Swal.fire('Success', 'Shift updated successfully', 'success');
            // Pass the updated data back to ClientRoster
            navigate('/clientroster', { state: { updatedShift: formData } });
          } else {
            throw new Error(response.data.message || 'Update failed');
          }
        } catch (error) {
          console.error('Error updating shift:', error);
          Swal.fire('Error', 'Failed to update shift', 'error');
        }
      };

      const getNavigationPath = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);

        const breadcrumbPaths = [
          { name: 'Roster', bold: false },
          { name: 'Client Roster',path:"/clientroster", bold: false },
          { name: 'Edit Client Roster',path: location.pathname, bold: true },

        ];
    

      return breadcrumbPaths.map((part, index) => ({
        ...part,
        bold: index === breadcrumbPaths.length - 1
      }));
    };
  
    const navigationPath = getNavigationPath();
  
    const handleClick = (path, e) => {
      e.preventDefault();
      navigate(path);
    };
  

    const handleCheckboxChange = (e) => {
  const { name, checked } = e.target;
  setFormData(prevData => ({
    ...prevData,
    [name]: checked ? 'yes' : 'no'
  }));
};

const formatDate = (dateString) => {
    if (!dateString) return '';
    const parsed = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(parsed) ? format(parsed, 'yyyy-MM-dd') : '';
  };
  const [clientName,setClientName] = useState([])


  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.client_name,
          label: type.client_name,
        }
      ));
 
    setClientName(options);
        console.log("Client Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };
  const [siteName,setSiteName] = useState([])

  useEffect(() => {
    fetchSite();
  }, []);

  const fetchSite = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.site_name,
            label: type.site_name,
        }
      ));
 
      setSiteName(options);
        console.log("Site Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };
  const handleSiaTypeChange = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

 
  const fetchStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/shift_staff",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Staff API response:", response.data);

      const formattedStaffOptions = response.data.data.map(staff => ({
        value:staff.name,
        label: staff.name, 
      }));
      console.log("Formatted staff options:", formattedStaffOptions);
      setStaffOptions(formattedStaffOptions);

      // Set selected staff based on the formData
      if (location.state && location.state.id && location.state.id.shift_assign_staff) {
        const selectedStaffNames = location.state.id.shift_assign_staff.split(',').map(name => name.trim());
        console.log("Selected staff names from formData:", selectedStaffNames);
        
        const selectedStaffOptions = formattedStaffOptions.filter(option => 
          selectedStaffNames.includes(option.value)
        );
        console.log("Matched staff options:", selectedStaffOptions);
        
        setSelectedStaff(selectedStaffOptions);
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };


  

  
  const fetchSubStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      const formattedOptions = response.data.data.map(staff => ({
        value: staff.subcon_company_name,
        label: staff.subcon_company_name,
      }));
      setSubOptions(formattedOptions);

      // Set selected subcontractors based on the formData
      if (location.state && location.state.id && location.state.id.shift_assign_subemp) {
        const selectedSubNames = location.state.id.shift_assign_subemp.split(', ');
        const selectedSubOptions = formattedOptions.filter(option => 
          selectedSubNames.includes(option.label)
        );
        setSelectedSubStaff(selectedSubOptions);
      }
    } catch (error) {
      console.error("Error fetching subcontractor data:", error);
    }
  };
  
  const handleStaffChange = (selectedStaffOptions) => {
    console.log("handleStaffChange called with:", selectedStaffOptions);
    setSelectedStaff(selectedStaffOptions);
    setFormData(prevState => ({
      ...prevState,
      shift_assign_staff: selectedStaffOptions.map(option => option.value).join(', ')
    }));
  };

  const handleSubStaffChange = (selectedOptions) => {
    setSelectedSubStaff(selectedOptions);
    setFormData(prevState => ({
      ...prevState,
      shift_assign_subemp: selectedOptions.map(option => option.label).join(', ')
    }));
  };
  return (
    <>
    {/* <Dashboard /> */}
    <div
      className="toolbar py-2"
      id="kt_toolbar"
      style={{backgroundColor: '#BF0404' }}>
       <h3 className="text-white" style={{ fontSize: '15px' }}>
      {navigationPath.map((part, index) => (
        <React.Fragment key={index}>
          <Link
            to={part.path}
            onClick={(e) => handleClick(part.path, e)}
            style={{
              fontWeight: part.bold ? 'bold' : 'normal',
              color: 'white',
              fontSize: part.bold ? '18px' : '15px',
              textDecoration: 'none'
            }}
          >
            {part.name}
          </Link>
          {index < navigationPath.length - 1 && " / "}
        </React.Fragment>
      ))}
    </h3>  
        </div>
    
        <div className="container mt-4">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md={3} controlId="clientName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Client Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                name="shift_client_name"
                value={clientName.find(option => option.value === formData.shift_client_name || '')}
                onChange={handleSiaTypeChange}
                options={clientName}
 placeholder="Client Name..."
          
              />
            </Form.Group>
            <Form.Group as={Col} md={3} controlId="payableRates"></Form.Group>
             <Form.Group as={Col} md={3} controlId="staffRate">
               <Form.Label style={{ float: "left" }} className="text-warning">
                Payable Rates
              </Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                name="shift_pay_rate"
                value={formData.shift_pay_rate || ''}
                onChange={handleInputChange}
              
              />
            </Form.Group>
            {/* <Form.Group as={Col} md={3} controlId="staffRateSelected">
              <Form.Label style={{ float: "left" }}>
                Staff Rate Selected
              </Form.Label>
              <Form.Control
                //  name="shift_client_name"
                //  value={formData.shift_client_name}
                //  onChange={handleInputChange}
                placeholder="Staff Rate Selected"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group> */}
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={3} controlId="siteName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Site Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                 name="shift_site_name"
                value={siteName.find(option => option.value === formData.shift_site_name || '')}
                onChange={handleSiaTypeChange}
                options={siteName}
                placeholder="Site Name..."
                
              />
            </Form.Group>
            <Form.Group as={Col} md={3} controlId="amount"></Form.Group>
            <Form.Group as={Col} md={6} controlId="payableRate">
              <Form.Label style={{ float: "left" }} className="text-warning">
                Amount
              </Form.Label>
              <Form.Control
                name="shift_pay_amount"
                value={formData.shift_pay_amount || ''}
                onChange={handleInputChange}
                placeholder="Amount"
                style={{ width: "100%" }}
                type="number"
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={3} controlId="staff">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Staff</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}       name="shift_assign_staff"
value={selectedStaff}
onChange={handleStaffChange}
options={staffOptions}
isMulti
                placeholder="Staff Name..."
              />
            </Form.Group>
            
         

            <Form.Group
              as={Col}
              md={3}
              controlId="payableExpenses"></Form.Group>

            <Form.Group as={Col} md={6} controlId="payableExpensesInput">
              <Form.Label style={{ float: "left" }} className="text-warning">
                Payable Expenses
              </Form.Label>
              <Form.Control
                name="shift_pay_expenses"
                value={formData.shift_pay_expenses || ''}
                onChange={handleInputChange}
                placeholder="Payable Expenses"
                style={{ width: "100%" }}
                type="number"
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="payableRates"></Form.Group>

            <Form.Group as={Col} md={6} controlId="billableRates">
              <Form.Label
                style={{ float: "left" }}
                className="text-success mt-3">
                Billable Rates
              </Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                name="shift_bill_rate"
                value={formData.shift_bill_rate || ''}
                onChange={handleInputChange}
               
               
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} md={3} controlId="SubContractor">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>SubContractor</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}      

name="shift_assign_subemp"
value={selectedSubStaff}
onChange={handleSubStaffChange}
options={subOptions}
isMulti
                placeholder="SubcontractorName..."
              />
            </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group>
            <Form.Group as={Col} md={6} controlId="billableExpenses">
              <Form.Label style={{ float: "left" }} className="text-success">
                Amount
              </Form.Label>
              <Form.Control
                name="shift_bill_amount"
                value={formData.shift_bill_amount || ''}
onChange={handleInputChange}
                placeholder="Site Billlable Rate"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group>
            <Form.Group as={Col} md={6} controlId="billableExpenses">
              <Form.Label
                style={{ float: "left" }}
                className="text-success mt-3">
                Billable Expenses
              </Form.Label>
              <Form.Control
                name="shift_bill_expenses"
                value={formData.shift_bill_expenses || ''}
                onChange={handleInputChange}
                placeholder="Billable Expenses"
                style={{ width: "100%" }}
                type="number"
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} md={4} controlId="shiftType">
              <Form.Label
                style={{ float: "left" }}
                className="mt-3">
                Shift Type
              </Form.Label>
              <Form.Control
                name="shift_type"
                value={formData.shift_type || ''}
                onChange={handleInputChange}
                placeholder="Shift Type"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
          <Row className="mb-3 mt-8">
          <Col md={6}>
  <Form.Check
    type="radio"
    label="Same Multiple Shifts"
    name="shift_type"
    checked={formData.shift_type === 'yes'}
    onChange={handleCheckboxChange}
      id="sameMultipleShifts"
    inline
  />
  <Form.Check
    type="radio"
    label="Different Multiple Shifts"
    name="shift_type"
    checked={formData.shift_type === 'yes'}
    onChange={handleCheckboxChange}    id="differentMultipleShifts"
    inline
  />
</Col>

            <Col md={6}>
              <Form.Group controlId="shiftPenalty">
                <Form.Label className="text-danger" style={{ float: "left" }}>
                  Penalty / Deduction
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="number"
                  value={formData.shift_penalty || ''}
                  onChange={handleInputChange}
                  name="shift_penalty"
                  placeholder="Penalty/Deduction"
                  min="0"
                  step="0.01"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="shiftDates">
                <Form.Label style={{ float: "left" }}>Shift Dates</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    placeholder="Start Date" 
                    name="shift_start_date"
                    value={formatDate(formData.shift_start_date)}                    onChange={handleInputChange}
                />
                  <InputGroup.Text>to</InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="End Date"
                    name="shift_end_date"
                    value={formatDate(formData.shift_end_date)}                    onChange={handleInputChange}
                            />
                </InputGroup>
              </Form.Group>
            </Col>
            <Row className="mb-3">
              <Col md={6}>
                <Row className="mb-3 mt-8">
                  <Col md={6}>
                    <Form.Group controlId="shiftStartTime">
                      <Form.Label style={{ float: "left" }}>
                        Shift Start Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        name="shift_start_time"
                        value={formData.shift_start_time || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="shiftEndTime">
                      <Form.Label style={{ float: "left" }}>
                        Shift End Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        name="shift_end_time"
                        value={formData.shift_end_time || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col md={6} className="text-right" style={{ marginTop: "-50px" }}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Check
                      style={{ marginTop: "50px", marginLeft: "100px" }}
                      type="checkbox"
                      name="shift_unpaid"
  checked={formData.shift_unpaid === 'yes'}
  onChange={handleCheckboxChange}
                      label="Unpaid Shift"
                      id="unpaidShift"
                      className="d-block"
                    />
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="Training Shift"
                      checked={formData.shift_training === 'yes'}
                      onChange={handleCheckboxChange}
                      name="shift_training"
                      id="trainingShift"
                      className="d-block"
                    />
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="Confirm Shift"
                      checked={formData.shift_confirm === 'yes'}
                      onChange={handleCheckboxChange}
                      id="confirmShift"
                      name="shift_confirm"
                      className="d-block"
                    />
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="Completed"
                      checked={formData.shift_completed === 'yes'}
                      onChange={handleCheckboxChange}
                      id="completedShift"
                      name="shift_completed"
                      className="d-block"
                    />
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="Cancel"
                      checked={formData.shift_cancelled === 'yes'}
                      onChange={handleCheckboxChange}
                      id="cancelShift"
                      name="shift_cancelled"
                      className="d-block"
                    />
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="Unconfirm Shift"
                      checked={formData.shift_unconfirm === 'yes'}
                      onChange={handleCheckboxChange}      name="shift_unconfirm"
                      id="unconfirmShift"
                      className="d-block"
                    />

                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Check
                      style={{ marginTop: "10px", marginLeft: "100px" }}
                      type="checkbox"
                      label="PO Number Received?"
                      checked={formData.shift_unconfirm === 'yes'}
                      onChange={handleCheckboxChange}      name="shift_unconfirm"
                      id="poNumberReceived"
                      className="d-block"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: "-20px" }}>
        <Col md={6}>
          <Form.Check
            type="checkbox"
            label="Add Break to Shift?"
            name="checkshift"
            checked={formData.checkshift === 'yes'}
            onChange={handleCheckboxChange}               id="addBreakToShift"
          />
          {formData.checkshift && (
            <Form.Check
              type="text"
              placeholder="Enter break details"
              name="shift_break"
              checked={formData.shift_break === 'yes'}
              onChange={handleCheckboxChange}                     style={{ marginTop: '10px' }}
            />
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Check
            type="checkbox"
            name="checkCallDetails"
            checked={formData.checkCallDetails === 'yes'}
            onChange={handleCheckboxChange}                  label="Add Check Calls to Shift?"
            id="addCheckCallsToShift"
          />
          {formData.checkCallDetails && (
            <Form.Check
              type="text"
              placeholder="Enter check call details"
              name="shift_check_call"
              checked={formData.shift_check_call === 'yes'}
              onChange={handleCheckboxChange}    
              style={{ marginTop: '10px' }}
            />
          )}
        </Col>
      </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="comments">
                <Form.Label style={{ float: "left" }}>Comments</Form.Label>
                <Form.Control
                  name="shift_comments"
                  value={formData.shift_comments || ''}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="shiftInstructions">
                <Form.Label style={{ float: "left" }}>
                  Shift Instructions
                </Form.Label>
                <Form.Control
                  name="shift_instructions"
                  value={formData.shift_instructions || ''}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="text-end">
              <button
                variant="success"
                style={{
                  padding: "10px 20px",
                  color: "white",
                  backgroundColor: "deepskyblue",
                  border: "0",
                  borderRadius: "5px",
                }}>
                SAVE
              </button>
            </Col>
          </Row>
        </Form>
      </div>
      </>
  )
}

export default EditClientRoster
