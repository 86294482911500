import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Form } from "react-bootstrap";
import "./Payroll.css";
import Dashboard from "../../Dashboard/Dashboard";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import NewSGSLogo from "../Payroll/patterns/logos/NewSGSLogo.jpeg"
import {useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Visibility, Edit, Delete, Email, PictureAsPdf } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import Select from 'react-select';



const ManagePayroll = () => {
  const [payrolls, setPayrolls] = useState([]);
  const [filteredPayroll,setFilteredPayroll] = useState([]);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    payroll_client_wise: "",
    payrroll_client_name: "",
    shift_mode:"",
    shift_title: "",
    payroll_start_date: "",
    payroll_end_date: "",
    payroll_no: "",
    company_branch: "1",
  });
  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchPayrolls();
  }, []);

  const fetchPayrolls = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_payroll_list",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        setPayrolls(response.data.data);
        setFilteredPayroll(response.data.data);
      } else {
        setError("Failed to fetch payrolls: " + response.data.message);
      }
    } catch (error) {
      console.error("Error fetching payroll:", error);
      setError("Error fetching payroll: " + error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePayrollPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filtered = payrolls.filter((payroll) =>
      Object.values(payroll).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredPayroll(filtered);
    setCurrentPage(0);
  }, [searchTerm, payrolls]);
  


  const handleCreatePayroll = async (e) => {
    e.preventDefault();
    
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_payroll_list",
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Payroll Updated',
            text: 'Payroll has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          setFormData([response.data.data]);
          handleCloseAddEventDetail();
          fetchPayrolls()
        } else {
          console.error("Error adding payroll:", response.data.message);
          alert("Error adding payroll: " + response.data.message);
        }
      } catch (error) {
        console.error("Error adding payroll:", error);
        alert("An error occurred while adding payroll. Please try again.");
      }
    
  };

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);

  const filteredPayrolls = payrolls.filter((payroll) => {
    if (!payroll)return false; 
      return Object.keys(payroll).some((key) =>{
        const value = payroll[key];
        return value &&  value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      });
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentPayroll =payrolls.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  
  
const generatePDF = (data) => {
  if (!data) {
    console.error('Invalid Payroll data');
    return;
  }

  const doc = new jsPDF();
 const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471',margin,45)

    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('PAYROLL', pageWidth - margin, 25, { align: 'right' });

    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Payroll Number: ${data.payroll_no  || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Start Date: ${data.payroll_start_date ||  'N/A' }`, pageWidth - margin, 45, { align: 'right' });
    doc.text(`End Date: ${data.payroll_end_date || 'N/A'}`, pageWidth - margin, 50, { align: 'right' });

    doc.autoTable({
      startY: 90,
      head: [['Client','Payroll Number','Guard Group','Start Date','End Date']],
      body: [
        [ `${data.payrroll_client_name || 'N/A'}`, `${data.payroll_no  || 'N/A'}`,`${data.shift_title || 'N/A'}`, `${data.payroll_start_date || 'N/A'}`,
`${data.payroll_end_date  || 'N/A'}`
        ],
      

      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
 

  return doc.output('blob');};


  const previewPDF = (data) => {
    const pdfBlob = generatePDF(data);
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Payroll', bold: false },
      { name: 'Manage Payroll', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(payrolls);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll");
    XLSX.writeFile(workbook, "ManagePayroll_data.xlsx");
  }

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [shiftModes, setShiftModes] = useState([]);
  const [selectedMode,setSelectedMode] = useState([])
     useEffect(() => {
      fetchShiftModes();
    }, [selectedShiftId]);
  
    const fetchShiftModes = async () => {
      try {
        const response = await axios.post(
          'https://srltd.megworld.in/api/client_roaster',
          { id: selectedShiftId },
          {
            headers: {
              'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
            }
          }
        );
        console.log("Shift Mode:",response)
        const modes = response.data.data.map(item => ({
          value: item.shift_mode,
          label: item.shift_mode
        }));
        setShiftModes(modes);
      } catch (error) {
        console.error('Error fetching shift modes:', error);
      }
    };
  
    // const handleShiftModeChange = (selectedSubOptions) => {
    //   setSelectedMode(selectedSubOptions);
    //   setFormData(prevState => ({
    //     ...prevState,
    //     shift_mode: selectedSubOptions.map(option => option.label).join(', ')
    //   }));
    // };

    const handleShiftModeChange = (selectedOption, { name }) => {
      setFormData((prevState) => ({
        ...prevState,
        [name]: selectedOption ? selectedOption.value : '',
      }));
    };

    const [clientName,setClientName] = useState([])

    useEffect(() => {
      fetchSiaTypes();
    }, []);
  
    const fetchSiaTypes = async () => {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/client_show",
          {},
          {
            headers: {
              Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
            },
          }
        );
        console.log("Response:", response.data);
        if (response.data && response.data.success) {
          const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
          const options = successData.map(type => ({
            value: type.client_name,
            label: type.client_name,
          }
        ));
   
      setClientName(options);
          console.log("Client Options:", options);    
  
        } else {
          console.error('Failed to fetch Site types');
        }
      } catch (error) {
        console.error('Error fetching Site types:', error);
      }
    };
  
    const handleSiaTypeChange = (selectedOption, { name }) => {
      setFormData((prevState) => ({
        ...prevState,
        [name]: selectedOption ? selectedOption.value : '',
      }));
    };

    const [siteGroups, setSiteGroups] = useState([]);

  useEffect(() => {
    fetchShiftTitles();
  }, []);

  const fetchShiftTitles = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_roaster",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Shift Title Response:", response.data);

      if (response.data && Array.isArray(response.data.data)) {
        const shiftTitleOptions = response.data.data.map(item => ({
          value: item.id,
          label: item.shift_title
        }));
        setSiteGroups(shiftTitleOptions);
        setSelectedShiftId(shiftTitleOptions.value)
      } else {
        console.error('Unexpected API response format');
      }
    } catch (error) {
      console.error('Error fetching Shift Titles:', error);
    }
  };

  const handleShiftTitleChange = (selectedOption, { name }) => {
    setFormData(prevInvoice => ({
      ...prevInvoice,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2 d-flex justify-content-between align-items-center"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>             
           
          <div className="d-flex" style={{marginRight:'90px'}}>
          
            <Button
  variant="contained"
  color="primary"
  onClick={handleShowAddEventDetail}
  sx={{ padding: '8px', marginLeft: '130px' }}
>
Create Payroll
</Button>
          </div>

      <Modal
        show={showAddEventDetail}
        onHide={handleCloseAddEventDetail}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create New Payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-4 mt-5">
              <Form.Label style={{ float: "left" }}>Client Wise</Form.Label>
              <Form.Check
                type="checkbox"
                name="payroll_client_wise"
                // value={formData.payroll_client_wise}
                onChange={handleInputChange}
              />
            
            </Form.Group>
            
            <Form.Group  controlId="staff">
            <Form.Group className="mb-4 mt-5">
              <Form.Label style={{ float: "left" }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Client Name</Form.Label>
             
                   <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                
name="payrroll_client_name"

                value={clientName.find(option => option.value === formData.credit_inv_name)}
                onChange={handleSiaTypeChange}
                options={clientName}
                isSearchable
                placeholder="Select Client Name..."
          
              />
            </Form.Group>

            <Form.Group className="mb-4 mt-5">
              <Form.Label style={{ float: "left" }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Shift Title</Form.Label>
             
                   <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                
name="shift_title"
value={siteGroups.find(option => option.value === setFormData?.shift_title)}
onChange={handleShiftTitleChange}
options={siteGroups}        isSearchable
placeholder="Select Shift Title..."

          
              />
   
            </Form.Group>
              <Form.Label style={{float:'left' }}>Shift Mode</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}       
  name="shift_mode"
  value={shiftModes.find(option => option.value === setFormData?.shift_mode)}
onChange={handleShiftModeChange}
options={shiftModes}
                isSearchable
                placeholder="Select Shift Mode..."
              />
            </Form.Group>
          
           
          
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                style={{ width: "100%" }}
                name="payroll_start_date"
                // value={formData.payroll_start_date}
                onChange={handleInputChange}
              />
          
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="End date"
                style={{ width: "100%" }}
                name="payroll_end_date"
                // value={formData.payroll_end_date}
                onChange={handleInputChange}
              />
             
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Payroll Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dec2017"
                style={{ width: "100%" }}
                name="payroll_no"
                // value={formData.payroll_no}
                onChange={handleInputChange}
              />
           
            </Form.Group>
         
          
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button
            className="me-2"
              variant="contained"
  color="primary"
              type="submit"
              onClick={handleCreatePayroll}>
              CREATE
            </Button>
            <Button    variant="contained"
  color="secondary" onClick={handleCloseAddEventDetail}>
              CLOSE
            </Button>
        </Modal.Footer>
      </Modal>
</div>
      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={handlePayrollPerPageChange}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
              <Button
                  variant="contained"
  color="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  // style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Client Name</th>
                    <th>Guard Group</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Payroll Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
  {isLoading ? (
    <tr>
      <td colSpan={7}>Loading...</td>
    </tr>
  ) : error ? (
    <tr>
      <td colSpan={7}>{error}</td>
    </tr>
  ) : currentPayroll.length > 0 ? (
    currentPayroll.map((data, index) => (
      <tr key={index} style={{ fontSize: "13px" }}>
        <td>{(currentPage - 1) * entriesPerPage + index + 1}</td>
        <td>{data.payrroll_client_name}</td>
        <td>{data.shift_title}</td>
     <td>{data.payroll_start_date}</td>
        <td>{data.payroll_end_date}</td>
        <td>{data.payroll_no}</td>
<td>
<button className="btn-primary" onClick={() => previewPDF(data)}>
    <i
      className="fas fa-file-pdf"
      style={{
        color: "white",
        padding:'7px'
      }}></i>
  </button> 

</td>
          </tr>
    ))
  ) : (
    <tr>
      <td colSpan={7}>
        <p>No data available in table</p>
      </td>
    </tr>
  )}
</tbody>

              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p style={{ marginLeft: "30px" }}>
                Showing {indexOfFirstEntry + 1} to{" "}
                {Math.min(indexOfLastEntry, filteredPayrolls.length)} of{" "}
                {filteredPayrolls.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  {Array.from({
                    length: Math.ceil(filteredPayrolls.length / entriesPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}>
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

        
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Shift Title</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Shift Mode</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Start Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>End Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Payroll Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredPayroll
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((data, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell >{data.payrroll_client_name}</TableCell>
                    <TableCell >{data.shift_title}</TableCell>
                    <TableCell >{data.shift_mode}</TableCell>
                    <TableCell >{data.payroll_start_date}</TableCell>
                    <TableCell >{data.payroll_end_date}</TableCell>
                    <TableCell >{data.payroll_no}</TableCell>
                    <TableCell >
                    <Tooltip 
    title="View PDF" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{color: 'white', 
      backgroundColor: 'darkviolet',  '&:hover': { backgroundColor: 'darkviolet' }  }}

      onClick={() => previewPDF(data)}  >
    <PictureAsPdf sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>

</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredPayroll.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ManagePayroll;
