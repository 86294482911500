import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const PolicyDeclare = () => {
  const [formData, setFormData] = useState({
    employee_id: "1",
    equality: "",
    details: "",
    directive: "",
    applicant: "",
    criminal_check: "",
    history: "",
    screening: "",
    date: "",
    company_branch: "1"
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the request payload
    const payload = {
      employee_id: formData.employee_id,
      equality: formData.equality ? "1" : "0",
      details: formData.details,
      directive: formData.directive === "Yes" ? "1" : "0",
      applicant: formData.applicant ? "1" : "0",
      criminal_check: formData.criminal_check ? "1" : "0",
      history: formData.history ? "1" : "0",
      screening: formData.screening ? "1" : "0",
      date: formData.date,
      company_branch: formData.company_branch
    };

    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_policy_and_declaration', payload, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      
      console.log('Data submitted successfully:', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Policy and Declaration data Updated',
        text: 'Policy and Declaration data has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error submitting data. Please try again.');
    }
  };
  return (
    <Container className="px-4 py-5">
      <Row>
        <Col md={12}>
          <h2>Policy and Declaration</h2>

          <h3>Equality</h3>
          <p>
            We are committed to Equality in all aspects of employment so list
            any adjustments above or on a separate attached covering letter that
            you require us to adopt that would enable you to carry out your
            duties as safely as possible. We will not use this information in
            any decision-making process in relation to your application.
          </p>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>I Agree</span>}

            name="equality"
            checked={formData.equality}
            onChange={handleChange}
          />

          <Form.Group className="mb-3 mt-10">
            <Form.Label style={{ float: "left" }}>Details</Form.Label>
            <Form.Control as="textarea" rows={3}   name="details"
              value={formData.details}
              onChange={handleChange}/>
          </Form.Group>

          <h3>Working Time Directive</h3>
          <p>
            You may have the opportunity to regularly work more than 48 hours a
            week. Should you wish to accept this opportunity you will need to
            waive your rights regarding this aspect of the working time
            directive.
          </p>
          <p>
            This agreement is made between Mark and COMPANY, under Regulation 5
            (1) of the Working Time Regulations 1998.
          </p>
          <p>
            I (Mark) understand that COMPANY will maintain records, will
            monitor, and record my working hours and make those records
            available to any authorized inspector in accordance with regulation
            5 (4)(a), (b) and (c) of the regulations.
          </p>
          <p>
            I (Mark) am entering into this agreement voluntarily. I understand
            my responsibilities in relation to maintaining health and safety at
            work and not to undertake working hours in such a way as to put
            myself, other staff, COMPANY, service-users or their family or
            others at risk or injury or detriment.
          </p>
          <p>
            This agreement will continue from the date of signing, but this
            agreement may be terminated at any time on giving 3 months written
            notice to the manager or their nominated deputy. I will ensure that
            I take account of existing shift's and agreed off duties and will
            give reasonable notice when terminating this agreement.
          </p>
          <p>
            Please tick either of the boxes below. This choice has nothing to do
            with the success of your application and can be reversed at any
            time.
          </p>
          <Form.Check
            inline
            type="radio"
            label={<span style={{ marginLeft: '8px' }}>Yes</span>}

            name="directive"
            value="Yes"
            checked={formData.directive === "Yes"}
            onChange={handleChange}
          />
          <Form.Check
            inline
            type="radio"
            name="directive"
            label={<span style={{ marginLeft: '8px' }}>No</span>}

            value="No"
            checked={formData.directive === "No"}
            onChange={handleChange}
          />

          <h3>Data Protection Privacy Notice</h3>
          <p>
            We gather and process your personal information in accordance with
            the company's privacy notice and in compliance with the relevant
            data protection Regulations (GDPR) and laws for assessing your
            suitability for employment. This notice provides you with the
            necessary information regarding your rights and our obligations, and
            explains how, why, and when we process your personal data.
          </p>
          <p>
            We process your personal information to meet our legal, statutory,
            and contractual obligations and to provide you with our products and
            services. We will never collect any unnecessary personal data from
            you and do not process your information in any way, other than as
            specified in this notice. We are aware that in today's world the
            protection of data and information is fundamental to your personal
            security, and we have an obligation to ensure the data we capture
            and store relating to our staff is respected, used correctly and
            stored securely. Please be aware therefore that all data captured
            and retained by our company is managed in accordance with the
            following 8 recognised principles of data protection:
          </p>
          <ul>
            <li>It will be fairly and lawfully processed</li>
            <li>It will be processed for limited purposes</li>
            <li>Remain accurate and up to date</li>
            <li>It will be adequate, relevant, and not excessive</li>
            <li>Not kept for longer than is necessary</li>
            <li>Processed in line with your rights</li>
            <li>Securely captured and retained</li>
            <li>
              Not transferred to other countries without adequate protection
            </li>
          </ul>
          <p>
            Employees have a right to access and view any data we hold relating
            to them and we always uphold this right. Access will be made
            available within 40 days of request. Please apply to the office for
            more information on how to access this information if required.
            COMPANY is registered with the Information Commissionaires Office
            and complies with all relevant legislation and regulation in respect
            of capturing, retaining, and protecting your data. Should any staff
            have any concerns regarding data protection or the practices we
            utilise please bring these concerns (in writing wherever possible)
            to the immediate attention of the HR manager who is located at the
            Head Office.
          </p>
          <p>
            COMPANY will use the information you have given on this form
            (together with any information which we obtain with your consent
            from third parties) for assessing your suitability for employment
            within certain contracts managed by us. It may be necessary to
            disclose your information to our agents and other service providers.
            By returning this form to the Company you consent to process your
            personal data where this is necessary, for example information about
            your past employment, finances, information about your credit
            status, ethnic origin, or criminal offences. You also consent to the
            transfer of your information to your current and future potential
            employers where this is necessary (this may be to companies
            operating abroad if you apply for work outside of the United
            Kingdom). Your information will be held on our computer database and
            in our paper filing systems.
          </p>

          <h3>Declaration By Applicant</h3>
          <p>
            I understand that employment with the COMPANY is subject to
            references and screening in accordance with BS7858. I confirm that
            the information I have provided on my application is true and
            complete to the best of my knowledge. I understand and agree that I
            will be subject to any or all of the following checks: Address
            Check, Financial probity check which the company will retain on
            file, ID verification checks, Academic/Professional qualification
            checks, Employment history, including any periods of unemployment /
            self-employment and any gaps, Criminal background check.
          </p>
          <p>
            I authorize the Company or its agents to approach Government
            agencies, former employers, educational establishments, criminal
            justice agencies and personal referees for information relating to
            and verification of my employment or unemployment history, a
            consumer information search and ID check with a credit reference
            agency, which will keep a record of those searches in line with
            current legislation.
          </p>
          <p>
            I further declare that any documents that I provide as proof of my
            identity, proof of address, and any other documents that I provide
            are genuine and that any falsified documents may be reported to the
            appropriate authority. I understand that it may be a criminal
            offence to attempt to obtain employment by deception and that any
            misrepresentation, omission of a material fact or deception will be
            cause for immediate withdrawal of any offer of employment.
          </p>
          <p>
            I accept that I may be required to undergo a medical examination
            were requested by the company including drug testing. I understand
            and agree that if so, required I will make a statutory declaration
            in accordance with the provision of the Statutory Declarations Act
            1835, in confirmation of previous employment or unemployment.
          </p>
          <p>
            I confirm that if I commence employment with your company and if I
            am currently registered as unemployed, I will inform the relevant
            authorities of my revised employment status. I accept this is my
            responsibility. I agree to attend Training Courses and /or First Aid
            training appropriate to my employment as identified and mutually
            agreed by the company and myself. I accept that it is my
            responsibility to renew and/or retain an SIA Licence (if required
            for my role) as this a legal requirement for employment within the
            designated security sector, failure to do so for any reason will
            result in the termination of my employment. I agree to inform you
            and the SIA of any change of my address.
          </p>
          <p>
            I understand that any false statement or omission to the company or
            its representatives may render me liable to dismissal without
            notice. By signing this declaration, I agree that I have provided
            complete and true information in support of the application and that
            I understand that knowingly making a false statement for this
            purpose is a criminal offence.
          </p>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>I Agree</span>}

            name="applicant"
            value="applicant"
            checked={formData.applicant}
            onChange={handleChange}
          />

          <h3 style={{ marginTop: "20px" }}>Criminal Background Check</h3>
          <p>
            You are applying for a position of trust and in the event of being
            offered employment by the Company we may apply for a Disclosure.
            However, having a criminal record does not necessarily bar you from
            employment. For more information ask a member of staff for a copy of
            the appropriate criminal record body code of practice and/or company
            policy statement regarding ex-offenders. Criminal record information
            is treated in a sensitive way and is restricted to those who need to
            see it to make a recruitment decision. By signing this document,
            upon request you agree to provide a copy of the criminal record
            certificates. The criminal background information is not retained.
            By signing below, you agree to this process.
          </p>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>I Agree</span>}

            name="criminal_check"
            value="criminal_check"
            checked={formData.criminal_check}
            onChange={handleChange}
          />

          <h3 style={{ marginTop: "20px" }}>Financial History</h3>
          <p>
            In accordance with BS 7858:2019 a Credit Reference Check will be
            carried out. Failure to disclose anything will result in a failed
            application.
          </p>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>I Agree</span>}

            name="history"
            value="history"
            checked={formData.history}
            onChange={handleChange}
          />

          <h3 style={{ marginTop: "20px" }}>Screening</h3>
          <p>
            The applicant consents to being screened in accordance with
            BS7858:2019 and will provide information as required. Any offer of
            employment is subject to satisfactory screening and the applicant
            acknowledgement that any false statements or omissions could leads
            to termination of employment/ reject your application and/or
            withdraw any conditional offer of employment. YOU MUST THEREFORE
            SUPPLY ALL THE INFORMATION REQUIRED.
          </p>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>I Agree</span>}

            name="screening"
            value="screening"
            checked={formData.screening}
            onChange={handleChange}
          />

          <Form.Group className="mb-3 mt-3">
            <Form.Label style={{ float: "left" }}>
              Date of Declaration
            </Form.Label>
            <Form.Control type="date"   name="date"
            value="date"
            checked={formData.date}
            onChange={handleChange}/>
          </Form.Group>

          <button className="btn btn-success mb-20" style={{ float: "right" }} onClick={handleSubmit}>
            FINISH
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default PolicyDeclare;
