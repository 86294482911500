import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Dashboard from '../Dashboard/Dashboard';
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { Check, X } from 'lucide-react';
import Swal from 'sweetalert2';

const ActionButton = ({ color, icon: Icon, onClick }) => (
  <IconButton 
    onClick={onClick}
    sx={{
      backgroundColor: color,
      color: 'white',
      '&:hover': {
        backgroundColor: color,
        opacity: 0.8,
      },
      padding: '4px',
      marginRight: '4px',
    }}
  >
    <Icon size={16} />
  </IconButton>
);


const TrashClient = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  useEffect(() => {
    const fetchEmployees = async (id) => {
      try {
        const response = await axios.post(
          'https://srltd.megworld.in/api/client_trash',
          {id:id}
        );
        console.log("TrashData:", response.data.data);
        if (Array.isArray(response.data.data)) {
          setEmployees(response.data.data);
          setFilteredEmployees(response.data.data);
        } else {
          console.error("error");
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const filtered = employees.filter((employee) =>
      Object.values(employee).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredEmployees(filtered);
    setCurrentPage(0);
  }, [searchTerm, employees]);

  // const handleSearchToggle = () => {
  //   setIsSearching(!isSearching);
  //   if (isSearching) {
  //     setSearchTerm('');
  //   }
  // };

  // const filteredEmployees = employees.filter(employee =>
  //   employee.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) || ''
  // );

  // const indexOfLastEntry = currentPage * entriesPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  // const currentEntries = employees.slice(indexOfFirstEntry, indexOfLastEntry);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Clients', bold: false },
      { name: 'Trash Clients', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEmployees);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client");
    XLSX.writeFile(workbook, "TrashClient_data.xlsx");
  };

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };

  const handleRestore = async (clientId, clientName) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Do you want to restore ${clientName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, restore it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post('https://srltd.megworld.in/api/client_trash_restore', { id: clientId });
        console.log("Restore response:", response.data);
        if (response.data.success) {
          setEmployees(employees.filter(employee => employee.id !== clientId));
          setFilteredEmployees(filteredEmployees.filter(employee => employee.id !== clientId));
          
          Swal.fire(
            'Restored!',
            `${clientName} has been restored successfully.`,
            'success'
          );
        } else {
          Swal.fire(
            'Error',
            'Failed to restore client. Please try again.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error('Error restoring client:', error);
      Swal.fire(
        'Error',
        'An error occurred while restoring the client. Please try again.',
        'error'
      );
    }
  };

  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>            
      
      </div>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => {
                    setEntriesPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${isSearching ? "btn-success" : "btn-primary"} ms-2`}
                  style={{ marginTop: "0" }}
                  onClick={handleSearchToggle}
                >
                  {isSearching ? "Back" : "Search"}
                </button>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={!isSearching}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th>S.NO</th>
                    <th>Client Name</th>
                    <th>Address</th>
                    <th>Contact</th>
                    <th>FAX</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.length > 0 ? (
                    currentEntries.map((client, index) => (
                      <tr key={index} style={{fontSize:'13px'}}>
                        <td>{indexOfFirstEntry + index + 1}</td>
                        <td>{client.client_name}</td>
                        <td>{client.client_address}</td>
                        <td>{client.client_contact_number}</td>
                        <td>{client.client_contact_fax}</td>
                        <td>{client.client_contact_email}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No employees found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, employees.length)} of {employees.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  {Array.from({ length: Math.ceil(employees.length / entriesPerPage) }).map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

        
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Address</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Fax</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>

                </TableRow>
              </TableHead>
              <TableBody >
                {filteredEmployees
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((client, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{client.client_name}</TableCell>
                    <TableCell >{client.client_address}</TableCell>
                    <TableCell >{client.client_contact_number}</TableCell>
                    <TableCell >{client.client_contact_fax}</TableCell>
                    <TableCell >{client.client_contact_email}</TableCell>
                    <TableCell>
                    <Tooltip 
    title="Restore" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  > <span>
                <ActionButton 
                  color="#4CAF50" 
                  icon={Check} 
                  onClick={() => handleRestore(client.id, client.client_name)}  
                                 /></span>
                </Tooltip>
{/* 
                <Tooltip 
    title="Remove" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <span>
                <ActionButton 
                  color="#F44336" 
                  icon={X} 
                  // onClick={() => handleReject(employee)}
                /></span>
                </Tooltip> */}
              </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEmployees.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default TrashClient;
