  import React, { useState,useEffect } from "react";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "@fortawesome/fontawesome-free/css/all.min.css";
  import Dashboard from "../Dashboard/Dashboard";
  import "./Roster.css";
  import headerBackground from "../patterns/header-bg.png";
  import { useLocation,Link,useNavigate  } from "react-router-dom";
  import axios from "axios";
  import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
  import * as XLSX from 'xlsx';
  import Swal from 'sweetalert2';
  import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Paper,
    TablePagination,
    IconButton,
    TextField,
    Box,
    Button,
  } from '@mui/material';
  import {
    Edit as EditIcon,
    Clock as ClockIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    ListAlt as ListAltIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
  } from '@mui/icons-material';
  import AccessTimeIcon from '@mui/icons-material/AccessTime';

  const ExternalLinkIcon = (props) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M7 17L17 7" />
      <path d="M7 7h10v10" />
    </svg>
  );

  const StaffRoster = ({ show, onHide, rosterData }) => {
    const [shiftData, setShiftData] = useState([]);
    const [filteredShiftData, setFilteredShiftData] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [clientName, setClientName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [staffName, setStaffName] = useState("");
    const [date, setDate] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedShift, setSelectedShift] = useState(null);

    const [isClient, setIsClient] = useState(false);


    const handleIconClick = (action, shift) => {
      console.log("Shift data:", shift); // Debugging log
      console.log("if_inv value:", shift.if_inv); // Debugging log
      
     
      if (shift && (shift.if_inv === 1 || shift.if_inv === '1')) {
        console.log("Showing invoice alert"); 
        Swal.fire({
          title: 'This Roster is invoiced',
          text: 'No further actions can be performed on this roster.',
          icon: 'info',
          confirmButtonText: 'OK'
        });
        return;
      }
      
      console.log("Proceeding with action"); // Debugging log
      if (typeof action === 'function') {
        action(shift);
      }
    };

    useEffect(() => {
      fetchShiftData();
    }, []);

    const fetchShiftData = async () => {
      try {
        const phoneNumber = localStorage.getItem("phoneNumber");
        const response = await axios.post('https://srltd.megworld.in/api/staff_roaster_new',{ phone_number: phoneNumber }, {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        });
        console.log("Staff Roster:", response);
        
        if (response.data && Array.isArray(response.data.data)) {
          // const formattedData = response.data.data.map(item => ({
          //   // id: item.data.id,
          //   shift_assign_staff: item.data.shift_assign_staff,
          //   shift_site_name: item.data.shift_site_name || '',
          //   shift_type: item.data.shift_bill_rate || '',
          //   shift_start_date: item.data.shift_start_date || '',
          //   shift_end_date: item.data.shift_end_date || '',
          //   shift_start_time: item.data.shift_start_time || '',
          //   shift_end_time: item.data.shift_end_time || '',
          //   shift_break: item.data.shift_break === "0" ? 'No' : 'Yes',
          //   shift_comments: item.data.shift_comments || '',
          //   shift_instructions: item.data.shift_instructions || '',
          //   shift_client_name: item.data.shift_client_name || '',
          // }));
          
          setShiftData(response.data.data);
          setFilteredShiftData(response.data.data);
          setIsClient(response.data.data.some(shift => shift.shift_client_phone === phoneNumber));

        } else {
          console.error('Invalid data structure in API response');
          setShiftData([]);
          setFilteredShiftData([]);
        }
      } catch (error) {
        console.error('Error fetching shift data:', error);
        setShiftData([]);
        setFilteredShiftData([]);
      }
    };


    useEffect(() => {
      const filtered = shiftData.filter((shift) =>
        Object.values(shift).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredShiftData(filtered);
      setCurrentPage(0);
    }, [searchTerm, shiftData]);

    // const handleFilter = (e) => {
    //   e.preventDefault();
    //   const filtered = shiftData.filter(shift => 
    //     (clientName === "" || shift.shift_client_name.toLowerCase().includes(clientName.toLowerCase())) &&
    //     (staffName === "" || shift.shift_assign_staff.toLowerCase().includes(staffName.toLowerCase())) &&
    //     (siteName === "" || shift.shift_site_name.toLowerCase().includes(siteName.toLowerCase())) &&
    //     (date === "" || shift.shift_start_date === date)
    //   );
    //   setFilteredShiftData(filtered);
    // };

    // const handleReset = () => {
    //   setClientName("");
    //   setSiteName("");
    //   setStaffName("");
    //   setDate("");
    //   setFilteredShiftData(shiftData);
    // };

    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Roster', bold: false },
        { name: 'Staff Roster', bold: true },
      ];
    };
    const navigationPath = getNavigationPath();
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(shiftData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Roster");
      XLSX.writeFile(workbook, "StaffRoster.xlsx");
    }

    const handleView = (shift) => {
      setSelectedShift(shift);
      setShowViewModal(true);
    };

    const handleDelete = async (id) => {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
          const response = await axios.post(
            "https://srltd.megworld.in/api/add_shift",
            { delete_id: id },
            {
              headers: {
                Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
              },
            }
          );
          console.log("Response:",response.data)
          if (response.data.success) {
            Swal.fire(
              'Deleted!',
              'The Client has been deleted.',
              'success'
            );
            fetchShiftData();
          } else {
            throw new Error(response.data.message || "Failed to delete Client Roster");
          }
        }
      } catch (error) {
        console.error("Delete Client Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An error occurred while deleting Client Roster.'
        });
      }
    };

    const handleEditSubmit = async (e) => {
      e.preventDefault();
      try {
        const changedFields = {};
        for (const key in selectedShift) {
          if (selectedShift[key] !== shiftData.find(shift => shift.id === selectedShift.id)[key]) {
            changedFields[key] = selectedShift[key];
          }
        }
    
        if (Object.keys(changedFields).length === 0) {
          setShowEditModal(false);
          return;
        }
    
        // Add the id to the changedFields object
        changedFields.id = selectedShift.id;
    
        const response = await axios.post('https://srltd.megworld.in/api/add_shift', changedFields, {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        });
        
        if (response.data.success) {
          setShowEditModal(false);
          
          const updatedShiftData = shiftData.map(shift => 
            shift.id === selectedShift.id ? { ...shift, ...changedFields } : shift
          );
          setShiftData(updatedShiftData);
          setFilteredShiftData(updatedShiftData);
    
          Swal.fire(
            'Updated!',
            'The shift has been updated successfully.',
            'success'
          );
        } else {
          throw new Error(response.data.message || 'Update failed');
        }
      } catch (error) {
        console.error('Error updating shift:', error);
        Swal.fire(
          'Error!',
          'There was a problem updating the shift.',
          'error'
        );
      }
    };
    
    const handleEdit = (shift) => {
      setSelectedShift({...shift});
      setShowEditModal(true);
    };

    const tooltipStyles = {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        color: '#fff',
        fontSize: '14px',
        padding: '8px 12px',
        borderRadius: '4px',
      },
      arrow: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    };

    const [openModal, setOpenModal] = useState(false);
    const [selectedRosterData, setSelectedRosterData] = useState(null);

    const handleOpenModal =async (shift) => {
      await handleIconClick(() => {}, shift);
      setSelectedRosterData(shift);
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const handleViewRosterDetails = () => {
      if (selectedRosterData) {
        const params = new URLSearchParams({
          site: selectedRosterData.shift_site_name || "",
          site_id: selectedRosterData.shift_site_name_id || "",
          shift_title: selectedRosterData.shift_title || "",
          shift_title_id: selectedRosterData.shift_title_id || "",
          shift_mode: selectedRosterData.shift_mode || "",
          shift_mode_id: selectedRosterData.shift_mode_id || "",
          name: selectedRosterData.shift_assign_staff || "",
          name_id: selectedRosterData.shift_assign_staff_id || ""
        });
        
        
        const shareableLink = `${window.location.origin}/rosterdetails?${params.toString()}`;
        
        // Open the link in a new tab
        window.open(shareableLink, '_blank');
      }
      handleCloseModal();
    };

    const handleCopyLink = () => {
      if (selectedRosterData) {
        const params = new URLSearchParams({
          site: selectedRosterData.shift_site_name || "",
          site_id: selectedRosterData.shift_site_name_id || "",
          shift_title: selectedRosterData.shift_title || "",
          shift_title_id: selectedRosterData.shift_title_id || "",
          shift_mode: selectedRosterData.shift_mode || "",
          shift_mode_id: selectedRosterData.shift_mode_id || "",
          name: selectedRosterData.shift_assign_staff || "",
          name_id: selectedRosterData.shift_assign_staff_id || ""
        });

        const shareableLink = `${window.location.origin}/rosterdetails?${params.toString()}`;
        
        navigator.clipboard.writeText(shareableLink)
          .then(() => alert('Link copied to clipboard!'))
          .catch(err => console.error('Failed to copy link: ', err));
      }
    };
const navigate = useNavigate()
    const handleViewTimestamp = async (shift)=>{
      await handleIconClick(() => {}, shift);
      navigate("/timestamp")
    }

    const role = localStorage.getItem("role");
    console.log("User Role Name:",role)

    const renderActionIcons = (shift) => {
      if (role === 'Admin','Office Manager','Accountant') {
        return (
          <>
                 <Tooltip 
      title="View Timestamp" 
      arrow 
      placement="top"
      componentsProps={{
        tooltip: { sx: tooltipStyles.tooltip },
        arrow: { sx: tooltipStyles.arrow },
      }}
    >
                    <IconButton
                    onClick={handleViewTimestamp}
                      className="me-2"
                      size="small"
                      sx={{
                        color: 'white',
                        backgroundColor: 'orange',
                        '&:hover': { backgroundColor: 'darkorange' }
                      }}
                    >
                      <AccessTimeIcon />
                    </IconButton>
                    </Tooltip>
                    {/* </Link> */}

                    <Tooltip 
      title="Delete Staff Roster" 
      arrow 
      placement="top"
      componentsProps={{
        tooltip: { sx: tooltipStyles.tooltip },
        arrow: { sx: tooltipStyles.arrow },
      }}
    >
                    <IconButton
                      onClick={() => handleDelete(shift.id)}
                      size="small"
                      className="me-2"
                      sx={{
                        color: 'white',
                        backgroundColor: 'red',
                        '&:hover': { backgroundColor: 'darkred' }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    </Tooltip>
                    <Tooltip 
        title="View Roster Details" 
        arrow 
        placement="top"
        componentsProps={{
          tooltip: { sx: tooltipStyles.tooltip },
          arrow: { sx: tooltipStyles.arrow },
        }}
      >
        <IconButton
          onClick={() => handleOpenModal(shift)}
          size="small"
          sx={{
            color: 'white',
            backgroundColor: 'purple',
            '&:hover': { backgroundColor: 'darkpurple' }
          }}
        >
          <ExternalLinkIcon />
        </IconButton>
      </Tooltip>
          </>
        );
      } else {
        return (
          <Tooltip 
          title="View Timestamp" 
          arrow 
          placement="top"
          componentsProps={{
            tooltip: { sx: tooltipStyles.tooltip },
            arrow: { sx: tooltipStyles.arrow },
          }}
        >
                        <IconButton
                        onClick={handleViewTimestamp}
                          className="me-2"
                          size="small"
                          sx={{
                            color: 'white',
                            backgroundColor: 'orange',
                            '&:hover': { backgroundColor: 'darkorange' }
                          }}
                        >
                          <AccessTimeIcon />
                        </IconButton>
                        </Tooltip>
        );
      }
    };

    return (
      <>
        {/* <Dashboard /> */}
        <div
          className="toolbar py-2"
          style={{backgroundColor: '#BF0404' }}>
        
                        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
              <React.Fragment key={index}>
                <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                  {part.name}
                </span>
                {index < navigationPath.length - 1 && " / "}  
              </React.Fragment>
            ))}</h3>           
        </div>

            {/* <Container fluid className="mt-4">
        <Form onSubmit={handleFilter}>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>STAFF NAME</Form.Label>
                <Form.Control 
                  type="text" 
                  value={staffName}
                  onChange={(e) => setStaffName(e.target.value)}
                  className="rounded-input"

                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>SITE NAME</Form.Label>
                <Form.Control 
                  type="text" 
                  value={siteName}
                  onChange={(e) => setSiteName(e.target.value)}
                  className="rounded-input"

                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>DATE</Form.Label>
                <Form.Control 
                  type="date" 
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="rounded-input"

                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button type="submit" variant="primary" className="me-2" style={{padding:'8px 30px'}}>FILTER</Button>
              <Button type="button" variant="secondary" className="me-2" style={{padding:'8px 30px'}} onClick={handleReset}>RESET</Button>
              <Button variant="success" style={{padding:'8px 5px',paddingLeft:'8px'}}   onClick={exportToExcel}><i className="fas fa-file-excel" style={{fontSize:'25px'}}></i></Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr className="bg-primary text-white" style={{fontSize:'13px'}}>
                    <th>S.NO</th>
                    <th>Staff Name</th>
                    <th>Client Name</th>
                    <th>Site Name</th>
                    <th>Shift Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Break</th>
                    <th>Comments</th>
                    <th>Instructions</th>
                    <th>Actions</th>

                  </tr>
                </thead>
            <tbody>
    {filteredShiftData.map((shift, index) => (
      <tr key={shift.id || index} style={{fontSize:'13px'}}>
        <td>{index + 1}</td>
        <td>{shift.shift_assign_staff}</td>
        <td>{shift.shift_client_name}</td>
        <td>{shift.shift_site_name}</td>
        <td>{shift.shift_type}</td>
        <td>{shift.shift_start_date}</td>
        <td>{shift.shift_end_date}</td>
        <td>{shift.shift_start_time}</td>
        <td>{shift.shift_end_time}</td>
        <td>{shift.shift_break}</td>
        <td>{shift.shift_comments}</td>
        <td>{shift.shift_instructions}</td>
        <td>
          <Button variant="primary" onClick={() => handleEdit(shift)} style={{padding:'5px 8px'}} className="me-2"><i className="fas fa-edit"></i></Button>
          <Button variant="success" onClick={() => handleView(shift)} style={{padding:'5px 8px'}} className="me-2"><i className="fas fa-eye"></i></Button>
          <Button variant="warning" style={{padding:'5px 8px'}} className="me-2"><i className="fas fa-clock"></i></Button>
          <Button variant="danger" onClick={() => handleDelete(shift.id)} style={{padding:'5px 10px'}} className="me-2"><i className="fas fa-trash-alt"></i></Button>
        </td>
      </tr>
    ))}
  </tbody>
              </Table> */}

  <Box sx={{ width: '100%', mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Paper sx={{ width: '95%', mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={exportToExcel}
        >
          Export to Excel
        </Button>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {['S.NO', 'Staff Name', 'Client Name', 'Site Name', 'Shift Mode','Shift Title', 'Start Date', 'End Date', 'Start Time', 'End Time', 'Break', 'Comments', 'Instructions', 'Actions'].map((header) => (
                <TableCell key={header} sx={{  fontSize: '15px', padding: '4px 8px' }}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredShiftData
              .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
              .map((shift, index) => (
                <TableRow key={index} sx={{ textAlign: 'center' }} hover>
                  <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_assign_staff}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_client_name}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_site_name}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_mode}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_title}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_date}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_date}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_time}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_time}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_break ? 'Yes' : 'No'}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_comments}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_instructions}</TableCell>
                  <TableCell sx={{ padding: '4px 8px' }}>
                      {renderActionIcons(shift)}
                    </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500]}
        component="div"
        count={filteredShiftData.length}
        rowsPerPage={entriesPerPage}
        page={currentPage}
        onPageChange={(event, newPage) => setCurrentPage(newPage)}
        onRowsPerPageChange={(event) => {
          setEntriesPerPage(parseInt(event.target.value, 10));
          setCurrentPage(0);
        }}
      />
    </Paper>
  </Box>
  <Modal show={openModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Roster Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Click the button below to view full roster details:</p>
            <Button variant="primary" onClick={handleViewRosterDetails}>View Roster Details</Button>
            <Button variant="secondary" onClick={handleCopyLink} className="ml-2">Copy Shareable Link</Button>
          </Modal.Body>
        </Modal>


              <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Shift</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Client Name</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_client_name || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_client_name: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Site Name</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_site_name || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_site_name: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Staff Name</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_assign_staff || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_assign_staff: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Shift Type</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_type || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_type: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Start Date</Form.Label>
                <Form.Control 
                  type="date" 
                  value={selectedShift?.shift_start_date || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_start_date: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>End Date</Form.Label>
                <Form.Control 
                  type="date" 
                  value={selectedShift?.shift_end_date || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_end_date: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Start Time</Form.Label>
                <Form.Control 
                  type="time" 
                  value={selectedShift?.shift_start_time || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_start_time: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>End Time</Form.Label>
                <Form.Control 
                  type="time" 
                  value={selectedShift?.shift_end_time || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_end_time: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Shift Comments</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_comments || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_comments: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{float:'left'}}>Shift Instructions</Form.Label>
                <Form.Control 
                  type="text" 
                  value={selectedShift?.shift_instructions || ''}
                  onChange={(e) => setSelectedShift({...selectedShift, shift_instructions: e.target.value})}
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={handleEditSubmit}>
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
            {/* </div> */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>View Shift Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Client Name</th>
            <td>{selectedShift?.shift_client_name}</td>
          </tr>
          <tr>
            <th>Site Name</th>
            <td>{selectedShift?.shift_site_name}</td>
          </tr>
          <tr>
            <th>Staff Name</th>
            <td>{selectedShift?.shift_assign_staff}</td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>{selectedShift?.shift_pay_amount}</td>
          </tr>
          <tr>
            <th>Payable Expenses</th>
            <td>{selectedShift?.shift_pay_expenses}</td>
          </tr>
          <tr>
            <th>Billable Rates</th>
            <td>{selectedShift?.shift_bill_rate}</td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>{selectedShift?.shift_bill_amount}</td>
          </tr>
          <tr>
            <th>Billable Expenses</th>
            <td>{selectedShift?.shift_bill_expenses}</td>
          </tr>
          <tr>
            <th>Penalty / Deduction</th>
            <td>{selectedShift?.shift_penalty}</td>
          </tr>
          <tr>
            <th>Shift Type</th>
            <td>{selectedShift?.shift_type}</td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>{selectedShift?.shift_start_date}</td>
          </tr>
          <tr>
            <th>End Date</th>
            <td>{selectedShift?.shift_end_date}</td>
          </tr>
          <tr>
            <th>Start Time</th>
            <td>{selectedShift?.shift_start_time}</td>
          </tr>
          <tr>
            <th>End Time</th>
            <td>{selectedShift?.shift_end_time}</td>
          </tr>
          <tr>
            <th>Shift Comments</th>
            <td>{selectedShift?.shift_comments}</td>
          </tr>
          <tr>
            <th>Shift Instructions</th>
            <td>{selectedShift?.shift_instructions}</td>
          </tr>
        </tbody>
      </table>
    </Modal.Body>
  </Modal>
      {/* </Col> */}
      {/* </Row>
        </Container> */}
    </>
    );
  };

  export default StaffRoster;








  // const [showAbsentee, setShowAbsentee] = useState(false);
  //   const [showFilterStaff, setShowFilterStaff] = useState(false);
  //   const [weekDates, setWeekDates] = useState([]);

  //   useEffect(() => {
  //     fetchWeekDates();
  //   }, []);

  //   const fetchWeekDates = async () => {
  //     try {
  //       const response = await axios.post('https://srltd.megworld.in/api/roast_shift_week', {}, {
  //         headers: {
  //           'Authorization': '115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
  //         }
  //       });
  //       console.log("Staff Roster:",response)
  //       setWeekDates(response.data);
  //     } catch (error) {
  //       console.error("Error fetching week dates:", error);
  //     }
  //   };
  //   const handleShowFilterStaff = () => setShowFilterStaff(true);
  //   const handleCloseFilterStaff = () => setShowFilterStaff(false);

  //   const handleshowAbsentee = () => setShowAbsentee(true);
  //   const handleCloseAbsentee = () => setShowAbsentee(false);