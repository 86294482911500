import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import "./Add.css";
import { useLocation ,Link} from "react-router-dom";
import { Modal, Card, Form, Nav, Row, Col,  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faList, faTrash, faUsers, faCalendar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Tooltip,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    IconButton,
    TextField,
    Box,
    Button,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
  } from '@mui/material';
  import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    ListAlt as ListAltIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
  } from '@mui/icons-material';
  import Select from 'react-select';


const Holiday = () => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [showHoliday, setShowHoliday] = useState(false);
    const [holidays, setHolidays] = useState([]);
    const [holidayForm, setHolidayForm] = useState({
        employee_id: '1',
        employee: '',
        start_date: '',
        end_date: '',
        rate_hrs: '',
        hrs_leave: '',
        status: '',
        shift_assign_staff:'',
        company_branch: '1' 
    });

    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingHoliday, setEditingHoliday] = useState(null);
    const token = '115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258';

    useEffect(() => {
        fetchHolidays();
    }, []);
    
    const fetchHolidays = async () => {
        try {
            const response = await axios.post('https://srltd.megworld.in/api/show_holidays',
                {},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            console.log("Response data:", response.data);
            
            if (response.data && Array.isArray(response.data.data)) {
                setHolidays(response.data.data);
            } else if (response.data && Array.isArray(response.data)) {
                setHolidays(response.data);
            } else {
                console.error("Unexpected data format:", response.data);
                setHolidays([]);
            }
        } catch (error) {
            console.error("Error fetching holidays:", error);
            setHolidays([]);
        }
    };
    
    const handleFormChange = (e) => {
        setHolidayForm({
            ...holidayForm,
            [e.target.name]: e.target.value
        });
    };

    const handleAddHoliday = async () => {
        try {
           const response= await axios.post('https://srltd.megworld.in/api/add_holidays', holidayForm, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("Response data:",response)
            if(response.data.success){
                Swal.fire({
                    icon: 'success',
                    title: 'Holidays Updated',
                    text: 'Holidays has been updated successfully.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  });
            }
            setShowHoliday(false);
            fetchHolidays(); 
        } catch (error) {
            console.error("Error adding holiday:", error);
        }
    };

    // const handleShowHoliday = () => {
    //     setShowHoliday(true);
    // };

    // const handleCloseHoliday = () => {
    //     setShowHoliday(false);
    // };
    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Employee', bold: false },
        { name: 'Payroll', bold: false },
        { name: 'Holiday', bold: false },
        { name: 'Employee Holidays Details', bold: true }
      ];
    };
  
    const navigationPath = getNavigationPath();
    
  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };

  const handleEditClick = (holiday) => {
    setShowEditModal(true);
    setEditingHoliday(holiday);
    setHolidayForm({
        employee_id: holiday.employee_id,
        employee: holiday.employee,
        start_date: holiday.start_date,
        end_date: holiday.end_date,
        rate_hrs: holiday.rate_hrs,
        hrs_leave: holiday.hrs_leave,
        status: holiday.status,
        company_branch: holiday.company_branch
    });
};

const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingHoliday(null);
    setHolidayForm({
        employee_id: '1',
        employee: '',
        start_date: '',
        end_date: '',
        rate_hrs: '',
        hrs_leave: '',
        status: '',
        company_branch: '1'
    });
};

const handleEditHoliday = async () => {
    try {
        const response = await axios.post('https://srltd.megworld.in/api/add_holidays' ,holidayForm, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.success){
            Swal.fire({
                icon: 'success',
                title: 'Holiday Updated',
                text: 'Holiday has been updated successfully.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
            setHolidays(prevHolidays => 
                prevHolidays.map(holiday => 
                  holiday.id === editingHoliday.id ? { ...holiday, ...holidayForm } : holiday
                )
              );
              
              handleCloseEditModal();
            }
    } catch (error) {
        console.error("Error updating holiday:", error);
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'There was an error updating the holiday.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
        });
    }
};


const handleDeleteHoliday = async (id) => {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            const response = await axios.post('https://srltd.megworld.in/api/add_holidays', {delete_id:id}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.data.success){
                Swal.fire(
                    'Deleted!',
                    'The holiday has been deleted.',
                    'success'
                );
                fetchHolidays();
            }
        }
    } catch (error) {
      console.error("Delete Holiday Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Holiday.'
      });
    }
};

const handleShowHoliday = () => {
    setShowHoliday(true);
    setEditMode(false);
    setHolidayForm({
        employee_id: '1',
        employee: '',
        start_date: '',
        end_date: '',
        rate_hrs: '',
        hrs_leave: '',
        status: '',
        company_branch: '1' 
    });
};

const handleCloseHoliday = () => {
    setShowHoliday(false);
    setEditMode(false);
    setEditId(null);
};


const [staffOptions, setStaffOptions] = useState([]);
const [selectedStaff, setSelectedStaff] = useState(null);

useEffect(() => {
  fetchStaffData();
}, []);

const fetchStaffData = async () => {
  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/shift_staff",
      {},
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
        }
      }
    );
    console.log("Shift Staff Data:", response);
    setStaffOptions(response.data.data);
    staffOptions.map(staff=>({
      value: staff.name,
      label: staff.name,     
    }))
  } catch (error) {
    console.error("Error fetching staff data:", error);
  }
};

const handleStaffChange = (selectedOption) => {
  setSelectedStaff(selectedOption);
  setHolidayForm(prevState => ({
    ...prevState,
    shift_assign_staff: selectedOption ? selectedOption.label : ''
  }));
};


const handleChange = (selectedOption) => {
  setSelectedStaff(selectedOption);
  setHolidayForm(prevState => ({
    ...prevState,
    employee: selectedOption ? selectedOption.label : ''
  }));
};

const formatDate = (dateString) => {
  if (!dateString) return 'Date not available';
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;

};
    return (
        <>
            {/* <Dashboard /> */}
            <div
        className="toolbar py-2 d-flex justify-content-between align-items-center"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
     <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>    


<Box className="d-flex" style={{marginRight:'90px'}}>
  <Button 
    onClick={handleShowHoliday} 
    variant="contained" 
    sx={{ 
      backgroundColor: '#3f51b5', 
      color: 'white', 
      padding: '8px 10px',
      marginLeft:'60px',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#303f9f',
      }
    }}
  >
    ADD HOLIDAYS
  </Button>
</Box>
            </div>

            <Modal show={showHoliday} onHide={handleCloseHoliday}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Holidays</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Employee</Form.Label>
                                <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                  }),
                                 
                                }}
                                isSearchable
                                name="employee"
                                value={selectedStaff}
                             onChange={handleChange}
                             options={staffOptions}
                             placeholder="Select employee"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={holidayForm.start_date}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                value={holidayForm.end_date}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Pay Rate Per Hour</Form.Label>
                            <Form.Control
                                type="number"
                                name="rate_hrs"
                                value={holidayForm.rate_hrs}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Total Hours Per Leave</Form.Label>
                            <Form.Control
                                type="number"
                                name="hrs_leave"
                                value={holidayForm.hrs_leave}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday Status</Form.Label>
                            <Form.Select
                                name="status"
                                value={holidayForm.status}

                                onChange={handleFormChange}
                            >
                                <option>Select Status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Reject">Reject</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="me-2" variant="contained" color="secondary" onClick={handleCloseHoliday}>
                        CLOSE
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleAddHoliday}>
                        ADD HOLIDAYS
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={handleCloseEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Holiday</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Employee</Form.Label>
            <Form.Select
              name="employee"
              value={holidayForm.employee}
              onChange={handleFormChange}
            >
              <option>Select Employee</option>
              <option>Deenl Mario</option>
              <option>Lione M</option>
              <option>Mark Nudd H</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Holiday Start Date</Form.Label>
            <Form.Control
              type="date"
              name="start_date"
              value={holidayForm.start_date}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Holiday End Date</Form.Label>
            <Form.Control
              type="date"
              name="end_date"
              value={holidayForm.end_date}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Pay Rate Per Hour</Form.Label>
            <Form.Control
              type="number"
              name="rate_hrs"
              value={holidayForm.rate_hrs}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Total Hours Per Leave</Form.Label>
            <Form.Control
              type="number"
              name="hrs_leave"
              value={holidayForm.hrs_leave}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Holiday Status</Form.Label>
            <Form.Select
              name="status"
              value={holidayForm.status}
              onChange={handleFormChange}
            >
              <option>Select Status</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Reject">Reject</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="me-2" variant="contained" color="secondary" onClick={handleCloseEditModal}>
          CLOSE
        </Button>
        <Button variant="contained" color="primary" onClick={handleEditHoliday}>
          UPDATE HOLIDAY
        </Button>
      </Modal.Footer>
    </Modal>
            <Card className="mb-4">
                <Card.Body >
                    <Form className="mb-4">
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                  
                                    <Select
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                          }),
                                         
                                        }}
                                        isSearchable
                                        name="shift_assign_staff"
                                        value={selectedStaff}
                                     onChange={handleStaffChange}
                                     options={staffOptions}
                                     placeholder="Select employee"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        value={currentYear}
                                        onChange={(e) => setCurrentYear(e.target.value)}
                                    >
                                        <option>Current Year</option>
                                        {/* Placeholder options for year */}
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                            
                                <Button variant="contained" color="primary" block>SEARCH HOLIDAYS</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        
                        <Col md={10}>
 

    <TableContainer >
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Employee</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Start Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>End Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Total Hours</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Hours Leave</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Status</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{padding:'8px'}}>
              { holidays.map((holiday, index) => ( 
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                                        <TableCell sx={{ padding: '5px' }}>{index+1}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.employee}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{formatDate(holiday.start_date)}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{formatDate(holiday.end_date)}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.rate_hrs}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.hrs_leave}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.status}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>

                    <Tooltip 
    title="Edit Holiday" 
    arrow 
    onClick={() => handleEditClick(holiday)}
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    className="me-2"

    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'blue', 
      '&:hover': { backgroundColor: 'darkgreen' } 
    }}
  >
    <EditIcon />
  </IconButton>
  </Tooltip>
  <Tooltip 
    title="Delete Holiday" 
    arrow 
    onClick={() => handleDeleteHoliday(holiday.id)}
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                    
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
</Col>

                    </Row>

                   <Link to="/monthlycalendar"> <Button variant="contained" color="info" block style={{marginTop:'10px'}}>VIEW YEARLY CALENDAR</Button></Link>
                </Card.Body>
            </Card>
        </>
    );
};




const SidebarIcon = ({ icon, label, active }) => (
    <Nav.Link
        className={`text-center py-3 ${active ? 'bg-secondary' : ''}`}
        title={label}
    >
        <FontAwesomeIcon icon={icon} size="lg" color="white" />
    </Nav.Link>
);

export default Holiday;








{/* <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <h5 className="card-title">HOLIDAYS OVERVIEW</h5>
                                    <Nav className="flex-column">
                                        <Nav.Link>Total Approved</Nav.Link>
                                        <Nav.Link>Approved & Taken</Nav.Link>
                                        <Nav.Link>Approved & Untaken Yet</Nav.Link>
                                        <Nav.Link>Remaining Holidays</Nav.Link>
                                        <Nav.Link>Entitlement</Nav.Link>
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col> */}