import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./Components/SignIn/SignIn";
import { AppContent } from "./AppContent";
import { showLoading } from "./Utils/loading";

function App() {
  const [isLoggedin, setIsLoggedin] = useState(sessionStorage.getItem("isLoggedin"));

  useEffect(() => {
    const loginStatus = sessionStorage.getItem("isLoggedin");

   if(loginStatus === "true") setIsLoggedin(loginStatus === "true");
   else setIsLoggedin(false)
  }, []);

  const handleLogin = (valid) => {
    if(valid) {
    setIsLoggedin(true);
    sessionStorage.setItem("isLoggedin", "true");}
  };

  const handleLogout = () => {
    setIsLoggedin(false);
    sessionStorage.removeItem("isLoggedin");
    localStorage.removeItem("role");
    localStorage.removeItem("phoneNumber");
  };

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedin ? (
            <Route path="*" element={<AppContent handleLogout={handleLogout} />} />
        ) : (
          <>
            <Route path="/" element={<SignIn handleLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
