import React, { useState, useEffect } from "react";
import "./Events.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvent, setFilteredEvent] = useState([]);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);
  const [showSearchEvent, setShowSearchEvent] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [connectToEmployeeOrSubcon, setConnectToEmployeeOrSubcon] =
    useState(false);
  const [connectToSite, setConnectToSite] = useState(false);
  const [eventsPerPage, setEventsPerPage] = useState(10);
  const [totalEvents, setTotalEvents] = useState(events);
  const initialEventState = {
    event_date: '',
    event_time: '',
    event_type: '',
    event_details: '',
    event_connect_emp_subcon_mark: 0,
    event_connect_emp_subcon: '',
    event_connect_site_mark: 0,
    event_connect_site: ''
  };
  const [newEvent, setNewEvent] = useState(initialEventState);
  const [editingEvent, setEditingEvent] = useState(null);
  const [editEvents,setEditEvents] = useState(false);
  const [searchParams, setSearchParams] = useState({
    client: "",
    event_type: "",
    site: "",
    employee_or_subcontractor: "",
  });

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/event_show",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      if (response.data && response.data.success) {
        setEvents(response.data.data);
        setTotalEvents(response.data.data);
        setFilteredEvent(response.data.data);
      } else {
        setError("No event data found");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Error fetching events: " + error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleSearchInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setSearchParams({ ...searchParams, [name]: value });
  // };

  useEffect(() => {
    const filtered = events.filter((event) =>
      Object.values(event).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredEvent(filtered);
    setCurrentPage(0);
  }, [searchTerm, events]);

  // const handleSearch = () => {
  //   if (isSearching) {
  //     setTotalEvents(events);
  //     setSearchTerm("");
  //     setIsSearching(false);
  //     setCurrentPage(1);
  //   } else {
  //     const filtered = events.filter((event) =>
  //       Object.values(event).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //     setTotalEvents(filtered);
  //     setIsSearching(true);
  //     setCurrentPage(1);
  //   }
  // };

 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEventsPerPageChange = (e) => {
    setEventsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // const handleSearch = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://srltd.megworld.in/api/event_show",
  //       searchParams,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setEvents(response.data.data);
  //     handleCloseSearchEvent();
  //   } catch (error) {
  //     console.error("Error searching events:", error);
  //   }
  // };

  const handleShowSearchEvent = () => setShowSearchEvent(true);
  const handleCloseSearchEvent = () => setShowSearchEvent(false);

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prevEvent => ({
      ...prevEvent,
      [name]: value
    }));
  };
  
  const handleModalCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewEvent(prevEvent => ({
      ...prevEvent,
      [name]: checked ? 1 : 0
    }));
  };

 

    const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventDetail = (event) => {
    setSelectedEvent(event);
    setShowEventDetail(true);
  };

  const handleCloseEventDetail = () => {
    setShowEventDetail(false);
    setSelectedEvent(null);
  };

  const handleAddEvent = async () => {
    console.log(newEvent);
    setNewEvent(initialEventState);
    if (
      !newEvent.event_date ||
      !newEvent.event_time ||
      !newEvent.event_type ||
      !newEvent.event_details
    ) {
      alert("Please fill in all required fields");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/event_store",
        newEvent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Event added Added',
          text: 'New Event has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        fetchEvents();
        handleCloseAddEventDetail();
        setNewEvent(response.data.data);
      } else {
        alert("Failed to add event: " + response.data.message);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      alert("An error occurred while adding the event. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const indexOfLastEntry = currentPage * eventsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - eventsPerPage;
  const currentEntries = totalEvents.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

const [site,setSite] = useState([])

const [employee, setEmployee] = useState([]);

useEffect(() => {
  fetchEmployees();
}, []);

const fetchEmployees = async () => {
  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/employee_show",
      {},
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
        },
      }
    );
    console.log("Response:", response.data);
    if (response.data && response.data.success) {
      const employeeData = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
      const options = employeeData.map(employee => ({
        value: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`,
        label: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`
      }));
      
      setEmployee(options);
      console.log("Employee Options:", options);
    } else {
      console.error('Failed to fetch employees');
    }
  } catch (error) {
    console.error('Error fetching employees:', error);
  }
};
 
  useEffect(() => {
    fetchSiteTypes();
  }, []);

  const fetchSiteTypes = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {id:id},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const Siteoptions = successData.map(type => ({
          value: type.site_name,
          label: type.site_name,}
      ));
   
      setSite(Siteoptions);
        console.log("Employee Siteoptions:", Siteoptions);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };
  
const handleSiaTypeChange = (selectedOption, { name }) => {
  setNewEvent(prevEvent => ({
    ...prevEvent,
    [name]: selectedOption ? selectedOption.value : ''
  }));
};

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setNewEvent({
      id: event.id,
      event_date: event.event_date || '',
      event_time: event.event_time || '',
      event_type: event.event_type || '',
      event_details: event.event_details || '',
      event_connect_emp_subcon_mark: event.event_connect_emp_subcon_mark || 0,
      event_connect_emp_subcon: event.event_connect_emp_subcon || '',
      event_connect_site_mark: event.event_connect_site_mark || 0,
      event_connect_site: event.event_connect_site || ''
    });
    setEditEvents(true);
  };

  
const handleEditSubmit = async () => {
  try {
    setIsLoading(true);
    const response = await axios.post(
      "https://srltd.megworld.in/api/event_store",
      newEvent,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data && response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Event Updated',
        text: 'Event has been updated successfully.',
      });
      fetchEvents(); // Refresh the events list
      setEditEvents(false);
    } else {
      throw new Error(response.data.message || "Failed to update event");
    }
  } catch (error) {
    console.error("Update Event Error:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message || 'An error occurred while updating the event.'
    });
  } finally {
    setIsLoading(false);
  }
};
  
const location = useLocation();

const getNavigationPath = () => {
  const pathParts = location.pathname.split('/').filter(Boolean);
  return [
    { name: 'Events', bold: false },
    { name: 'Events', bold: true },
  ];
};

const navigationPath = getNavigationPath();

const exportToExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(events);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Events");
  XLSX.writeFile(workbook, "Events_data.xlsx");
}

const tooltipStyles = {
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: '#fff',
    fontSize: '14px',
    padding: '8px 12px',
    borderRadius: '4px',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};
return (
  <>
    {/* <Dashboard /> */}
    <div
      className="toolbar py-2 d-flex justify-content-between align-items-center"
      id="kt_toolbar"
      style={{backgroundColor: '#BF0404' }}>
      <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
          <React.Fragment key={index}>
            <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
              {part.name}
            </span>
            {index < navigationPath.length - 1 && " / "}  
          </React.Fragment>
        ))}</h3>               
          <div className="d-flex" style={{marginRight:'90px'}}>
          

            <Button
  variant="contained"
  color="primary"
  onClick={handleShowAddEventDetail}
  sx={{ padding: '8px', marginLeft: '250px' }}
>
  Add Event
</Button>
           
          </div>
      </div>

      
      <Modal show={editEvents} onHide={() => setEditEvents(false)}>
  <Modal.Header closeButton>
    <Modal.Title style={{ float: "left" }}>Edit Event</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Event Date</Form.Label>
        <Form.Control
          type="date"
          name="event_date"
          value={newEvent?.event_date || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Event Time</Form.Label>
        <Form.Control
          type="time"
          name="event_time"
          value={newEvent?.event_time || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Event Type</Form.Label>
        <Form.Control
          as="select"
          name="event_type"
          value={newEvent?.event_type || ''}
          onChange={handleInputChange}
        >
          <option value="">Select event type ...</option>
          <option value="Power off">Power off</option>
          <option value="Lateness">Lateness</option>
          <option value="Blow out">Blow out</option>
          <option value="Complaint">Complaint</option>
          <option value="Incident">Incident</option>
          <option value="Accident">Accident</option>
          <option value="Others">Others</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Event Detail</Form.Label>
        <Form.Control
          as="textarea"
          name="event_details"
          value={newEvent?.event_details || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Connect Event to Employee OR a Subcontractor?"
          name="event_connect_emp_subcon_mark"
          checked={newEvent?.event_connect_emp_subcon_mark === 1}
          onChange={handleModalCheckboxChange}
        />
        {newEvent?.event_connect_emp_subcon_mark === 1 && (
          <Select
            name="event_connect_emp_subcon"
            value={employee.find(option => option.value === newEvent?.event_connect_emp_subcon)}
            onChange={(selectedOption) => handleSiaTypeChange(selectedOption, { name: "event_connect_emp_subcon" })}
            options={employee}
            isSearchable
            placeholder="Select Employee/Subcontractors..."
          />
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Connect Event to a site?"
          name="event_connect_site_mark"
          checked={newEvent?.event_connect_site_mark === 1}
          onChange={handleModalCheckboxChange}
        />
        {newEvent?.event_connect_site_mark === 1 && (
          <Select
            name="event_connect_site"
            value={site.find(option => option.value === newEvent?.event_connect_site)}
            onChange={(selectedOption) => handleSiaTypeChange(selectedOption, { name: "event_connect_site" })}
            options={site}
            isSearchable
            placeholder="Select Sites..."
          />
        )}
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>

  <Button className="me-2"  variant="contained"
  color="primary" onClick={handleEditSubmit}>
      Save Changes
    </Button>

    <Button color="secondary" variant="contained" onClick={() => setEditEvents(false)}>
      Close
    </Button>
    
  </Modal.Footer>
</Modal>

      


      <Modal show={showAddEventDetail} onHide={handleCloseAddEventDetail}>
      <Modal.Header closeButton>
        <Modal.Title>Add Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Event Date</Form.Label>
            <Form.Control
              type="date"
              name="event_date"
              // value={newEvent.event_date}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Event Time</Form.Label>
            <Form.Control
              type="time"
              name="event_time"
              // value={newEvent.event_time}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Event Type</Form.Label>
            <Form.Control
              as="select"
              name="event_type"
              // value={newEvent.event_type}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            >
              <option>Select event type ...</option>
              <option>Power off</option>
              <option>Lateness</option>
              <option>Blow out</option>
              <option>Complaint</option>
              <option>Incident</option>
              <option>Accident</option>
              <option>Others</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Event Detail</Form.Label>
            <Form.Control
              as="textarea"
              name="event_details"
              // value={newEvent.event_details}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Connect Event to Employee OR a Subcontractor?"
          name="event_connect_emp_subcon_mark"
          // checked={newEvent.event_connect_emp_subcon_mark === 1}
          onChange={handleModalCheckboxChange}
          style={{ marginBottom: '15px', display: 'block' }}        />
        {newEvent?.event_connect_emp_subcon_mark === 1 && (
          <>
            <Select
              type="text"
              name="event_connect_emp_subcon"
              value={employee.find(option => option.value === newEvent.event_connect_emp_subcon)}
              onChange={handleSiaTypeChange}
              options={employee}
              isSearchable
              placeholder="Select Employee/Subcontractors..."
                        style={{ width: "100%" }}
            />
          </>
        )}
      </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Connect Event to a site?"
              name="event_connect_site_mark"
              // checked={newEvent.event_connect_site_mark === 1}
              onChange={handleModalCheckboxChange}
              style={{ display: "block" , marginBottom: '15px', }}
            />
            {newEvent?.event_connect_site_mark === 1 && (
                <Select
                  type="text"
                  name="event_connect_site"
                  value={site.find(option => option.value === newEvent.event_connect_site)}
                  onChange={handleSiaTypeChange}
                  options={site}
                  isSearchable
                  placeholder="Select Sites..."
                      style={{ width: "100%" }}
                />
            )}
            
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button  className="me-2"   variant="contained"
  color="primary" onClick={handleAddEvent}>
          Add Event
        </Button>
        <Button  variant="contained" color="secondary" onClick={handleCloseAddEventDetail}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={eventsPerPage}
                  onChange={(e) => {
                    handleEventsPerPageChange(e);
                  }}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
                <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={isSearching}
                />
              </div>
            </div>
            {isLoading ? (
              <p>Loading events...</p>
            ) : events && events.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>EVENT ID</th>
                      <th>EVENT DATE</th>
                      <th>EVENT TIME</th>
                      <th>EVENT TYPE</th>
                      <th>EVENT DESCRIPTION</th>
                      <th>CLIENT NAME</th>
                      <th>SITE NAME</th>
                      <th>STAFF</th>
                      <th>CREATED BY</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.length > 0 ? (
                      currentEntries.map((event, index) => (
                        <tr key={index} style={{ fontSize: "13px" }}>
                          <td>{index + 1}</td>
                          <td>{event.event_date}</td>
                          <td>{event.event_time}</td>
                          <td>{event.event_type}</td>
                          <td>{event.event_details}</td>
                          <td>{event.event_connect_emp_subcon}</td>
                          <td>{event.event_connect_site_mark}</td>
                          <td>{event.staff}</td>
                          <td>{event.created_by}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-success me-1"
                              onClick={() => handleEventDetail(event)}>
                              <i
                                className="fas fa-eye"
                                style={{ color: "white" }}></i>
                            </button>
                            <button className="btn btn-sm btn-primary"
                              onClick={() => handleEditEvent(event)}
                            
                          >
                              <i
                                className="fas fa-edit"
                                style={{ color: "white" }}></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No employees found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No events found.</p>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing {indexOfFirstEntry + 1} to{" "}
                {Math.min(indexOfLastEntry, totalEvents.length)} of{" "}
                {totalEvents.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  {Array.from({
                    length: Math.ceil(totalEvents.length / eventsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}>
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Event Date </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Event Time</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Event Type</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Event Description</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Staff</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredEvent
                  .slice(currentPage * eventsPerPage, currentPage * eventsPerPage + eventsPerPage)
                  .map((event, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * eventsPerPage + index + 1}</TableCell>
                    <TableCell >{event.event_date}</TableCell>
                    <TableCell >{event.event_time}</TableCell>
                    <TableCell >{event.event_type}</TableCell>
                    <TableCell >{event.event_details}</TableCell>
                    <TableCell >{event.event_connect_emp_subcon}</TableCell>
                    <TableCell >{event.event_connect_site_mark}</TableCell>
                    <TableCell >{event.staff}</TableCell>
                    <TableCell >
                    <Tooltip 
    title="View Event" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
                              onClick={() => handleEventDetail(event)}
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="Edit Event" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
 onClick={() => handleEditEvent(event)} 
  size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <EditIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEvent.length}
            rowsPerPage={eventsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEventsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
      
      <Modal show={showEventDetail} onHide={handleCloseEventDetail}>
        <Modal.Header closeButton>
          <Modal.Title>Event Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent ? (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Event Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEvent.event_date}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Event Time</Form.Label>
                <Form.Control
                  type="time"
                  value={selectedEvent.event_time}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Event Detail</Form.Label>
                <Form.Control
                  as="textarea"
                  value={selectedEvent.event_details}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Site</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEvent.event_connect_site}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Employee</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEvent.event_connect_emp_subcon}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Action Taken</Form.Label>
                <Form.Control
                  as="textarea"
                  value={selectedEvent.event_action_taken}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Action Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEvent.event_action_date}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Action Time</Form.Label>
                <Form.Control
                  type="time"
                  value={selectedEvent.event_action_time}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Action User</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEvent.event_action_user}
                  readOnly
                  style={{ width: "100%" }}
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Loading event details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
        <Button className="me-2"  variant="contained" color="primary" >
            UPDATE
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCloseEventDetail}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Events;
