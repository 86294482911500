import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Add.css";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { faImage } from '@fortawesome/free-solid-svg-icons'; 
import { Modal, Image } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const EmployeeInfo = () => {
  const location = useLocation();
  const [modalImage, setModalImage] = useState(null);
  const { employee_id, employee_name } = location.state || {};

  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [formData, setFormData] = useState({
    employee_id: employee_id || "",
    employee: employee_name || "",
    type_document: "",
    copy_type: "",
    comments: "",
    document_upload: null,
  });
  const [error, setError] = useState("");

  const apiToken = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    if (employee_id) {
      fetchUploadedDocuments(employee_id);
    }
  }, [employee_id]);

  const fetchUploadedDocuments = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_emp_upload",
        { employee_id: employee_id },
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      console.log("EmployeeInfo:", response);
      setEmployeeInfo(response.data.data);
    } catch (error) {
      console.error("Error fetching uploaded documents:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageClick = (e, document_upload) => {
    e.preventDefault();
    const imageUrl = `https://srltd.megworld.in/${document_upload}`;
    setModalImage(imageUrl);
  };
  
  const closeModal = () => {
    setModalImage(null);
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      document_upload: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!formData.employee_id) {
      setError("Employee ID is required.");
      return;
    }

    const submissionData = new FormData();
    for (const key in formData) {
      if (key === "document_upload" && formData[key]) {
        submissionData.append(key, formData[key], formData[key].name);
      } else if (formData[key]) {
        submissionData.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/emp_upload",
        submissionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      console.log("Upload successful:", response.data);
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Employee Information Added",
          text: "Employee Information has been Added successfully.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        fetchUploadedDocuments();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      if (error.response) {
        setError(
          `Error ${error.response.status}: ${
            error.response.data.message || "Unknown error"
          }`
        );
      } else if (error.request) {
        setError("No response received from the server. Please try again.");
      } else {
        setError("Error setting up the request. Please try again.");
      }
    }
  };
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const breadcrumbPaths = [
      { name: "Master", bold: false },
      { name: "Employee", bold: false },
      { name: "Hr", bold: false },
      { name: "Employee Documents", path: "/hrdocuments", bold: false },
      {
        name: "Employee Documents information",
        path: location.pathname,
        bold: true,
      },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1,
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}>
        <div className="">
          <h3 className="text-white" style={{ fontSize: "15px" }}>
            {navigationPath.map((part, index) => (
              <React.Fragment key={index}>
                <Link
                  to={part.path}
                  onClick={(e) => handleClick(part.path, e)}
                  style={{
                    fontWeight: part.bold ? "bold" : "normal",
                    color: "white",
                    fontSize: part.bold ? "18px" : "15px",
                    textDecoration: "none",
                  }}>
                  {part.name}
                </Link>
                {index < navigationPath.length - 1 && " / "}
              </React.Fragment>
            ))}
          </h3>
        </div>
      </div>
      <div className="container mt-5">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3 mt-20">
            <label htmlFor="documents" className="col-sm-3 col-form-label mt-5">
              Documents Upload
            </label>
            <div className="col-sm-9">
              <input
                type="file"
                className="form-control mt-5"
                id="documents"
                name="document_upload"
                onChange={handleFileChange}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="documentType" className="col-sm-3 col-form-label">
              Select Document Type
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="documentType"
                name="type_document"
                value={formData.type_document}
                onChange={handleChange}
                style={{ width: "100%" }}>
                <option value="">Select document type</option>
                <option value="visa">Visa type</option>
                <option value="passport">Passport copy</option>
                <option value="dl_front">Driving license (front)</option>
                <option value="dl_back">Driving license (back)</option>
                <option value="sia_front">SIA license (front)</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="copyType" className="col-sm-3 col-form-label">
              Copy Type
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="copyType"
                value={formData.copy_type}
                name="copy_type"
                onChange={handleChange}
                style={{ width: "100%" }}>
                <option value="">Select copy type</option>
                <option value="original">Original copy</option>
                <option value="scan">Scan copy</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="comments" className="col-sm-3 col-form-label">
              Comments
            </label>
            <div className="col-sm-9">
              <textarea
                className="form-control"
                id="comments"
                rows="3"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                style={{ width: "100%" }}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-end">
              <button type="submit" className="btn-primary" id="Upload-btn">
                Upload File
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        className="container col-7"
        id="tablebox"
        style={{ width: "100%", marginLeft: "190px" }}>
        <h3>
          <span style={{ color: "skyblue" }}>
            {formData.employee || "Employee"} -
          </span>{" "}
          Documents Uploaded
        </h3>

        {/* <table className="table">
          <thead style={{ fontSize: "15px" }}>
            <tr>
              <th>S.No</th>
              <th>Document Type</th>
              <th>Copy Type</th>
              <th>Document</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {employeeInfo.length > 0 ? (
              employeeInfo.map((doc, index) => (
                <tr key={doc.id || index}>
                  <td>{index + 1}</td>
                  <td>{doc.type_document}</td>
                  <td>{doc.copy_type}</td>
                  <td>
                  
                    <Button
  variant="link"
  onClick={(e) => handleImageClick(e, doc.document_upload)}>

  <FontAwesomeIcon icon={faImage} size="lg" />
</Button>
                  </td>
                  <td>{doc.comments}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No data available in table....
                </td>
              </tr>
            )}
          </tbody>
        </table> */}

        <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Document Type</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Copy Type</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Document</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeInfo
                  .map((doc, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{doc.id || index}</TableCell>
                    <TableCell >{doc.type_document}</TableCell>
                    <TableCell >{doc.copy_type}</TableCell>
                    <TableCell>
                    <Button
  variant="text" 
  onClick={(e) => handleImageClick(e, doc.document_upload)}
  sx={{
    minWidth: 0,
    padding: 0,  
  }}
>
  <FontAwesomeIcon icon={faImage} size="lg" />
</Button>
                    </TableCell>

                    <TableCell sx={{ padding: '5px' }}>{doc.comments}</TableCell>
  

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        {modalImage && (
          <div
            className="modal"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Document View</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setModalImage(null)}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <img
                    src={modalImage}
                    alt="Document"
                    style={{ width: "50%",marginLeft:'110px'}}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeInfo;
