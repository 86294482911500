import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import headerBackground from "../Employee/patterns/header-bg.png";
import "./Subcontractors.css";
import Dashboard from "../../../Dashboard/Dashboard";
import {useLocation} from "react-router-dom";


const Subcontractors = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [branches, setBranches] = useState([
    { id: 1, name: " Head Office", allowApproval: false },
    { id: 2, name: " Head Office", allowApproval: true },
  ]);

  const handleClose = () => setShowModal(false);
  const handleShow = (branch) => {
    setCurrentBranch(branch);
    setShowModal(true);
  };

  const handleSave = (allowApproval) => {
    const updatedBranches = branches.map((branch) =>
      branch.id === currentBranch.id ? { ...branch, allowApproval } : branch
    );
    setBranches(updatedBranches);
    handleClose();
  };


  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Settings', bold: false },
      { name: 'Workforce', bold: false },
      { name: 'Subcontractors', bold: false },
      { name: 'Sub Staff Approval', bold: true },

    ];
  };
  
  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>  
      </div>

      <div className="container mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Branch Name</th>
              <th>
                Allow approval of Sub-Staff from super admin before going onto
                shifts?
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {branches.map((branch) => (
              <tr key={branch.id}>
                <td>{branch.name}</td>
                <td>{branch.allowApproval ? "Yes" : "No"}</td>
                <td>
                  <Button variant="link" onClick={() => handleShow(branch)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sub Staff Approval Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Allow approval of Sub-Staff from super admin before going onto
            shifts?
          </h5>
          <Form>
            <Form.Check
              type="radio"
              label="Yes"
              name="approvalOption"
              id="approvalYes"
              onChange={() => handleSave(true)}
              checked={currentBranch?.allowApproval}
            />
            <Form.Check
              type="radio"
              label="No"
              className="Noo"
              name="approvalOption"
              id="approvalNo"
              onChange={() => handleSave(false)}
              checked={!currentBranch?.allowApproval}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Subcontractors;
