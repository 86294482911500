import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import Dashboard from "../Dashboard/Dashboard";
import "./Sites.css";


const EditSiteForm = ({ site, onClose, onUpdate }) => {
  const [formData, setFormData] = useState(site);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
  };
  
  return (
    <div className="popup-overlay">
    <div className="popup-content">
      <div className="popup-header">
        <h2>EDIT SITE DETAILS</h2>
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label >Client Name</label>
          <input
            type="text"
            name="site_client_name"
            value={formData.site_client_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Site Group</label>
          <input
            type="text"
            name="site_group_name"
            value={formData.site_group_name}
            onChange={handleChange}
          />
        </div>
          <div className="form-group">
          <label>Site Name</label>
          <input
            type="text"
            name="site_name"
            value={formData.site_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Contact Person</label>
          <input
            type="text"
            name="site_contact_person"
            value={formData.site_contact_person}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Contact Number</label>
          <input
            type="number"
            name="site_contact_number"
            value={formData.site_contact_number}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            name="site_address"
            value={formData.site_address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Trained Guards Required</label>
          <input
            type="text"
            name="site_trained_guard"
            value={formData.site_trained_guard}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Post Code</label>
          <input
            type="text"
            name="site_post_code"
            value={formData.site_post_code}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Site Billable Rate (Guarding)</label>
          <input
            type="number"
            name="site_bill_guarding"
            value={formData.site_bill_guarding}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Site Payable Rate (Guarding)</label>
          <input
            type="number"
            name="site_pay_guarding"
            value={formData.site_pay_guarding}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Site Billable Rate (Supervisor)</label>
          <input
            type="number"
            name="site_bill_supervisor"
            value={formData.site_bill_supervisor}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Site Payable Rate (Supervisor)</label>
          <input
            type="number"
            name="site_pay_supervisor"
            value={formData.site_pay_supervisor}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label> Site Note
          </label>
          <input
            type="text"
            name="site_note"
            value={formData.site_note}
            onChange={handleChange}
          />
        </div>
      
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            UPDATE
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
          >
            CLOSE
          </button>
        </div>
      </form>
    </div>
  </div>
  );
};

const SiteDetails = () => {
const [siteData,setSiteData] = useState({})

  useEffect(() => {
    fetchSiteData();
  }, []);

  const fetchSiteData = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        { id:id },
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Site Response:",response.data)
      setSiteData(response.data.data[0] );
    } catch (error) {
      console.error("Error fetching Site data:", error);
    }
  };
  
  const handleUpdateSite = async (updatedData) => {
    try {
      const changedFields = {};
  
      Object.keys(updatedData).forEach(key => {
        if (updatedData[key] !== siteData[key]) {
          changedFields[key] = updatedData[key];
        }
      });
  
      if (Object.keys(changedFields).length === 0) {
        console.log("No changes detected");
        setShowEditForm(false);
        return;
      }
  
      changedFields.id = siteData.id;
  
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_store",
        changedFields,
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
  
      console.log("Update Site response:", response);
      
      fetchSiteData();
      
      setShowEditForm(false);
    } catch (error) {
      console.error("Error updating site data:", error);
    }
  };
  const [showEditForm, setShowEditForm] = useState(false);

  const [showSiteDetails, setShowSiteDetails] = useState(false);

  const handleShowSiteDetails = () => setShowSiteDetails(true);
  const handleCloseSiteDetails = () => setShowSiteDetails(false);

  const [checkCalls, setCheckCalls] = useState([{ id: 1, time: "00:26" }]);

  const [trainedGuards, setTrainedGuards] = useState([]);
  const [trainedGuardsSubcontractor, setTrainedGuardsSubcontractor] = useState(
    []
  );
  const [bannedGuards, setBannedGuards] = useState([]);
  const [bannedGuardsSubcontractor, setBannedGuardsSubcontractor] = useState(
    []
  );

  const renderTable = (data, columns, addButtonText, onAdd) => (
    <div className="card mt-4">
      <div className="card-body">
        <h5 className="card-title d-flex justify-content-between align-items-center">
          {addButtonText}
          <button
            className="btn-primary"
            size="sm"
            onClick={onAdd}
            style={{ padding: "10px" }}
          >
            ADD GUARD
          </button>
        </h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {row[col.toLowerCase().replace(/\s/g, "")]}
                  </td>
                ))}
                <td>
                  <button variant="info" size="sm" className="me-2">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button variant="danger" size="sm">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );

  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    const breadcrumbPaths = [
      { name: 'Sites', bold: false },
      { name: 'View Sites',path:'/viewsite', bold: false },
      { name: 'Site Details', bold: false },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };
  const handleEditClick = () => {
    setShowEditForm(true);
  };

  const handleCloseForm = () => {
    setShowEditForm(false);
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{ fontSize: '15px' }}>
      {navigationPath.map((part, index) => (
        <React.Fragment key={index}>
          <Link
            to={part.path}
            onClick={(e) => handleClick(part.path, e)}
            style={{
              fontWeight: part.bold ? 'bold' : 'normal',
              color: 'white',
              fontSize: part.bold ? '18px' : '15px',
              textDecoration: 'none'
            }}
          >
            {part.name}
          </Link>
          {index < navigationPath.length - 1 && " / "}
        </React.Fragment>
      ))}
    </h3>         
       
      </div>
 {showEditForm && (
  <EditSiteForm
    site={siteData}
    onClose={handleCloseForm}
    onUpdate={handleUpdateSite}
  />
)}

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title d-flex justify-content-between align-items-center">
                  {siteData.site_client_name}
                  <button
                    className="btn-primary"
                    size="sm"
                    style={{ padding: "10px 20px" }}
                    onClick={handleEditClick}                  >
                    EDIT
                  </button>

                  <Modal show={showSiteDetails} onHide={handleCloseSiteDetails}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Site Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group className="mb-3">
                          <Form.Label
                            style={{ float: "left", marginTop: "20px" }}
                          >
                            Client Name
                          </Form.Label>
                          <Form.Control
                            style={{ width: "100%" }}
                            value="Hawks"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Group
                          </Form.Label>
                          <Form.Control
                            style={{ width: "100%" }}
                            value="Manguard"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="halll"
                            name="client_contact_person"
                            style={{ width: "100%" }}
                            placeholder="Site Name"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Contact Person
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="henry"
                            style={{ width: "100%" }}
                            placeholder="Contact Person"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Contact Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="343345484865"
                            name="client_contact_number"
                            style={{ width: "100%" }}
                            placeholder="Contact Number"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Address
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            value="Hrt456"
                            style={{ width: "100%" }}
                            placeholder="Site Address"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Trained Guards Required
                          </Form.Label>
                          <Form.Check type="checkbox" />
                          Yes / No
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label
                            style={{ float: "left", marginTop: "20px" }}
                          >
                            Post Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="jtys"
                            style={{ width: "100%" }}
                            placeholder="Post Code"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Billable Rate (Guarding)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="12"
                            style={{ width: "100%" }}
                            placeholder="Site Billable Rate (Guarding)"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Payable Rate (Guarding)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="10"
                            style={{ width: "100%" }}
                            placeholder="Site Payable Rate (Guarding)"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Billable Rate (Supervisor)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="15"
                            placeholder="Site Billable Rate (Supervisor)"
                            style={{ width: "100%" }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Payable Rate (Supervisor)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value="20"
                            placeholder="Site Payable Rate (Supervisor)"
                            style={{ width: "100%" }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ float: "left" }}>
                            Site Note
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            value="retail"
                            style={{ width: "100%" }}
                            placeholder="Site Note"
                          />
                        </Form.Group>
                      </Form>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseSiteDetails}
                        >
                          CLOSE
                        </Button>
                        <Button variant="primary">UPDATE</Button>
                      </Modal.Footer>
                    </Modal.Body>
                  </Modal>
                </h4>
                <div className="card-body">
                <table className="table">
                <tbody>
                <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Client Name</th>
                      <td>{siteData.site_client_name}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Site Group</th>
                      <td>{siteData.site_group_name}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Site Name</th>
                      <td>{siteData.site_name}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contact Number</th>
                      <td>{siteData.site_contact_number}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contact Person</th>
                      <td>{siteData.site_contact_person}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Address</th>
                      <td>{siteData.site_address}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Trained Guards Required
                      </th>
                      <td>{siteData.site_trained_guard}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Post Code</th>
                      <td>{siteData.site_post_code}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Site Billable Rate (Guarding)</th>
                      <td>{siteData.site_bill_guarding}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Site Payable Rate (Guarding)</th>
                      <td>{siteData.site_pay_guarding}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}> Site Billable Rate (Supervisor)</th>
                      <td>{siteData.site_bill_supervisor}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}> Site Payable Rate (Supervisor)</th>
                      <td>{siteData.site_pay_supervisor}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Site Note</th>
                      <td>{siteData.site_note}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
      
        </div>
{/* 
        {renderTable(
          trainedGuards,
          ["S.No", "Trained Guard", "Training Date", "Event ID"],
          "Trained Guards",
          () => {}
        )}
        {renderTable(
          trainedGuardsSubcontractor,
          [
            "S.No",
            "Subcontractor Name",
            "Trained Guard",
            "Training Date",
            "Event ID",
          ],
          "Trained Guards (Subcontractor)",
          () => {}
        )}
        {renderTable(
          bannedGuards,
          ["S.No", "Banned Guard", "Banned Date", "Event ID"],
          "Banned Guards (Employee)",
          () => {}
        )}
        {renderTable(
          bannedGuardsSubcontractor,
          [
            "S.No",
            "Subcontractor Name",
            "Banned Guard",
            "Banned Date",
            "Event ID",
          ],
          "Banned Guards (Subcontractor)",
          () => {}
        )} */}
      </div>
    </>
  );
};

export default SiteDetails;
