import React, { useState } from "react";
import Dashboard from "../../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import "./Subcontro.css";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const UnApproved = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Master', bold: false },
      { name: 'Subcontractors', bold: false },
      { name: 'Un-Approved Sub Employees', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>                
        </div>

      {showModal && (
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Search Subcontractors</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="branches">Branches:</label>
              <input
                type="text"
                className="form-control"
                id="branches"
                style={{ width: "100%" }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subcontractors">Subcontractors:</label>
              <select className="form-select" id="subcontractors">
                <option value="all">All branches</option>
                <option value="head">Head office</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary">
              Search
            </button>
          </Modal.Footer>
        </Modal>
      )}

      <div className=" mt-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-flex align-items-center" id="flex1">
              <label htmlFor="show" className="me-3">
                Show
              </label>
              <select id="show" className="form-select-custom me-5">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end" id="flex1">
              <label htmlFor="search" className="me-3" id="search">
                Search:
              </label>
              <input type="text" id="search" className="form-control-custom" />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4" id="container-box">
        <table className="table table-striped">
          <thead className="thead">
            <tr>
              <th>ID</th>
              <th>Staff Name</th>
              <th>Company Name</th>
              <th>Contact Number</th>
              <th>SIA Number</th>
              <th>SIA Type</th>
              <th>SIA Expiry</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="9" className="text-center">
                No data available in table....
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UnApproved;
