import React, { useEffect, useState } from "react";
import "./Events.css";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link ,useLocation} from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const CallOut = () => {
  const [callOuts, setCallOuts] = useState([]);
  const [filteredCallout, setFilteredCallout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [callOutsPerPage, setCallOutsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [totalCallOuts, setTotalCallOuts] = useState(callOuts);
  const [newCallOut, setNewCallOut] = useState({
    callout_date: "",
    callout_start_time: "",
    callout_end_time: "",
    callout_details: "",
    callout_initial_charges: "",
    callout_extra_hours_site: 0,
    callout_extra_hours_rate: "",
    callout_connect_emp_subcon_mark: 0,
    callout_connect_emp_subcon: "",
    callout_connect_site_mark: 0,
    callout_connect_site: "",
    company_branch: 1,
  });
  const [showAddCallOutModal, setShowAddCallOutModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams, setSearchParams] = useState({
    callout_date: "",
    callout_start_time: "",
    callout_end_time: "",
    callout_details: "",
    callout_initial_charges: "",
    callout_extra_hours_site:"",
    callout_extra_hours_rate: "",
    callout_connect_emp_subcon_mark: "",
    callout_connect_emp_subcon: "",
    callout_connect_site_mark:"",
    callout_connect_site: "",
  })
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  
  useEffect(() => {
    fetchCallOuts();
  }, [currentPage, callOutsPerPage, searchTerm]);

  const fetchCallOuts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/callout_show",
         { search: searchTerm },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        setCallOuts(response.data.data);
        setTotalCallOuts(response.data.data.length);
        setFilteredCallout(response.data.data);
      } else {
        setError("No callout data found");
      }
    } catch (error) {
      console.error("Error fetching callouts:", error);
      setError("Error fetching callouts: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = callOuts.filter((callout) =>
      Object.values(callout).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredCallout(filtered);
    setCurrentPage(0);
  }, [searchTerm, callOuts]);

  const handleShowAddCallOutModal = () => setShowAddCallOutModal(true);
  const handleCloseAddCallOutModal = () => setShowAddCallOutModal(false);

  const handleModalCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewCallOut(prevCallOut => ({
      ...prevCallOut,
      [name]: checked ? 1 : 0
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCallOut(prevCallOut => ({
      ...prevCallOut,
      [name]: value
    }));
  };
  
  
  const handleAddCallOut = async () => {
    if (
      !newCallOut.callout_date ||
      !newCallOut.callout_start_time ||
      !newCallOut.callout_end_time ||
      !newCallOut.callout_details ||
      !newCallOut.callout_initial_charges
    ) {
      alert("Please fill in all required fields");
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/callout_store",
        newCallOut,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'CallOut added Added',
          text: 'New CallOut has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        fetchCallOuts();
        handleCloseAddCallOutModal();
      } else {
        alert("Failed to add callout: " + response.data.message);
      }
    } catch (error) {
      console.error("Error adding callout:", error);
      alert("An error occurred while adding the callout. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
 
  const handleSearch = () => {
    if (isSearching) {
      setCallOuts(callOuts); // Reset to all callouts
      setSearchTerm("");
      setIsSearching(false);
      setCurrentPage(1);
    } else {
      const filtered = callOuts.filter((callout) =>
        Object.values(callout).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setCallOuts(filtered);
      setIsSearching(true);
      setCurrentPage(1);
    }
  };


  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCallOutsPerPageChange = (e) => {
    setCallOutsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const indexOfLastEntry = currentPage * callOutsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - callOutsPerPage;
  const currentEntries = callOuts.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // const handleCloseAddCallOutModal = () => setShowAddCallOutModal(false);
  // const handleShowAddCallOutModal = () => setShowAddCallOutModal(true);

  // const indexOfLastEntry = currentPage * callOutsPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - callOutsPerPage;
  // const currentEntries = Array.isArray(totalCallOuts) 
  // ? totalCallOuts.slice(indexOfFirstEntry, indexOfLastEntry)
  // : [];
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const [employee, setEmployee] = useState([]);

useEffect(() => {
  fetchEmployees();
}, []);

const fetchEmployees = async () => {
  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/employee_show",
      {},
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
        },
      }
    );
    console.log("Response:", response.data);
    if (response.data && response.data.success) {
      const employeeData = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
      const options = employeeData.map(employee => ({
        value: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`,
        label: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`
      }));
      
      setEmployee(options);
      console.log("Employee Options:", options);
    } else {
      console.error('Failed to fetch employees');
    }
  } catch (error) {
    console.error('Error fetching employees:', error);
  }
};
const [site,setSite] = useState([])

useEffect(() => {
  fetchSiteTypes();
}, []);

const fetchSiteTypes = async (id) => {
  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/site_show",
      {id:id},
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
        },
      }
    );
    console.log("Response:", response.data);
    if (response.data && response.data.success) {
      const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
      const Siteoptions = successData.map(type => ({
        value: type.id.toString(),
        label: type.site_name,}
    ));
 
    setSite(Siteoptions);
      console.log("Employee Siteoptions:", Siteoptions);    

    } else {
      console.error('Failed to fetch Site types');
    }
  } catch (error) {
    console.error('Error fetching Site types:', error);
  }
};
  const handleSiaTypeChange = (selectedOption, { name }) => {
    setNewCallOut((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Events', bold: false },
      { name: 'CallOut', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(callOuts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Callout");
    XLSX.writeFile(workbook, "Callout_data.xlsx");
  }
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2 d-flex justify-content-between align-items-center"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>      
      <div className="d-flex" style={{marginRight:'90px'}}>
      <Button
  variant="contained"
  color="primary"
  onClick={handleShowAddCallOutModal}
  sx={{ padding: '8px', marginLeft: '250px' }}
>
  Add CallOut
</Button>
       
      </div>
      </div>
      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={callOutsPerPage}
                  onChange={handleCallOutsPerPageChange}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
                <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
\                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={isSearching}
                  placeholder="Search..."
                />
              </div>
            </div>
            {isLoading ? (
              <p>Loading callouts...</p>
            ) : callOuts && callOuts.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr style={{fontSize:'13px'}}>
                      <th>ID</th>
                      <th>CallOut Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Details</th>
                      <th>Initial Charges</th>
                      <th>Extra Hours</th>
                      <th>Employee/Subcontractor</th>
                      <th>Site</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.map((callout, index) => (
                      <tr key={callout.id} style={{ fontSize: '15px' }}>
                        <td>{indexOfFirstEntry + index + 1}</td>
                        <td>{callout.callout_date}</td>
                        <td>{callout.callout_start_time}</td>
                        <td>{callout.callout_end_time}</td>
                        <td>{callout.callout_details}</td>
                        <td>{callout.callout_initial_charges}</td>
                        <td>{callout.callout_extra_hours_rate}</td>
                        <td>{callout.callout_connect_emp_subcon}</td>
                        <td>{callout.callout_connect_site}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No data available in table...</p>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
  <p style={{marginLeft:'30px'}}>
    Showing {indexOfFirstEntry + 1} to{" "}
    {Math.min(indexOfLastEntry, callOuts.length)} of{" "}
    {callOuts.length} entries
  </p>
  <nav>
    <ul className="pagination mb-0">
      {Array.from({
        length: Math.ceil(callOuts.length / callOutsPerPage),
      }).map((_, index) => (
        <li
          key={index}
          style={{marginRight:'20px'}}
          className={`page-item ${
            currentPage === index + 1 ? "active" : ""
          }`}
        >
          <button
            className="page-link"
            style={{marginTop:'-20px' }}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        </li>
      ))}
    </ul>
  </nav>
</div>

        </div>
      </div> */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Callout Date </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Start Time</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>End Time</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Details</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Initial Charges</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Extra Hours</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Employee/Subcontractors</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site</TableCell>
                  {/* <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredCallout
                  .slice(currentPage * callOutsPerPage, currentPage * callOutsPerPage + callOutsPerPage)
                  .map((callout, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * callOutsPerPage + index + 1}</TableCell>
                    <TableCell >{callout.callout_date}</TableCell>
                    <TableCell >{callout.callout_start_time}</TableCell>
                    <TableCell >{callout.callout_end_time}</TableCell>
                    <TableCell >{callout.callout_details}</TableCell>
                    <TableCell >{callout.callout_initial_charges}</TableCell>
                    <TableCell >{callout.callout_extra_hours_rate}</TableCell>
                    <TableCell >{callout.callout_connect_emp_subcon}</TableCell>
                    <TableCell >{callout.callout_connect_site}</TableCell>
                    <TableCell >{callout.staff}</TableCell>
                    {/* <TableCell >
  <IconButton 
                              onClick={() => handleEventDetail(callout)}
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>


  <IconButton 
 onClick={() => handleEditEvent(callout)} 
  size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <EditIcon />
  </IconButton>
</TableCell> */}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredCallout.length}
            rowsPerPage={callOutsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setCallOutsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
      <Modal show={showAddCallOutModal} onHide={handleCloseAddCallOutModal}>
  <Modal.Header closeButton>
    <Modal.Title>Add Call Out</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Call Out Date</Form.Label>
        <Form.Control
          type="date"
          name="callout_date"
          value={newCallOut && newCallOut.callout_date}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Start Time</Form.Label>
        <Form.Control
          type="time"
          name="callout_start_time"
          value={ newCallOut && newCallOut.callout_start_time}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>End Time</Form.Label>
        <Form.Control
          type="time"
          name="callout_end_time"
          value={newCallOut && newCallOut.callout_end_time}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
          <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Call Out Details</Form.Label>
        <Form.Control
          as="textarea"
          name="callout_details"
          placeholder="Call Out Details"
          value={newCallOut && newCallOut.callout_details}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Initial Call Out Charges</Form.Label>
        <Form.Control
          type="text"
          name="callout_initial_charges"
          value={newCallOut && newCallOut.callout_initial_charges}
          onChange={handleInputChange}
          placeholder="Site"
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Extra Hours Rate (Rate/Hour)</Form.Label>
        <Form.Control
          type="text"
          name="callout_extra_hours_rate"
          placeholder="Rate"
          value={newCallOut && newCallOut.callout_extra_hours_rate}
          onChange={handleInputChange}
          style={{ width: "100%" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Connect Call Out to Employee OR a Subcontractor"
          style={{marginBottom: '15px'}}
          name="callout_connect_emp_subcon_mark"
          checked={ newCallOut && newCallOut.callout_connect_emp_subcon_mark === 1}
          onChange={handleModalCheckboxChange}
        />
        {newCallOut && newCallOut.callout_connect_emp_subcon_mark === 1 && (
          <Select
            type="text"
            name="callout_connect_emp_subcon"
            value={employee.find(option => option.value === newCallOut.callout_connect_emp_subcon)}
            onChange={handleSiaTypeChange}
            options={employee}
            isSearchable
            placeholder="Select Employee/Subcontractors..."
          style={{ width: "100%"}}
          />
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Connect Call Out to a site"
          style={{marginBottom: '15px'}}
          name="callout_connect_site_mark"
          checked={newCallOut && newCallOut.callout_connect_site_mark === 1}
          onChange={handleModalCheckboxChange}
        />
        {newCallOut && newCallOut.callout_connect_site_mark === 1 && (
          <Select
            type="text"
            name="callout_connect_site"
            value={site.find(option => option.value === newCallOut.callout_connect_site)}
            onChange={handleSiaTypeChange}
            options={site}
            isSearchable
            placeholder="Select Sites..."
            style={{ width: "100%", }}
          />
        )}
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button  className="me-2" variant="contained"
  color="primary" onClick={handleAddCallOut}>
      Add Call Out
    </Button>
    <Button color="secondary" variant="contained" onClick={handleCloseAddCallOutModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </>
  );
};

export default CallOut;
