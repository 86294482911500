import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {  Link } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard";
import "./Invoice.css";
import SGSLogoImg from "../Invoice/patterns/logos/SGS-Logo-1-1024x387.webp";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modal,Form, } from "react-bootstrap";
import { pdfjs } from 'react-pdf';
import jsPDF from "jspdf";
import NewSGSLogo from "../Invoice/patterns/logos/NewSGSLogo.jpeg"
import * as XLSX from 'xlsx';



const ViewShiftInvoice   = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showExport, setShowExport] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [exportType, setExportType] = useState('');
  const [exportFormat, setExportFormat] = useState('');

  const handleExport = () => {
    if (exportFormat === 'PDF') {
      const pdfBlob = generatePDF(invoiceData);
      if (pdfBlob) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      }
    } else if (exportFormat === 'EXCEL') {
      exportToExcel();
    }
    handleClose();
  };


  const generatePDF = (invoice) => {
    if (!invoice) {
      console.error('Invalid invoice data');
      return null;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
  
    // Add Logo
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471', margin, 45);
  
    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('INVOICE', pageWidth - margin, 25, { align: 'right' });
  
    // Add invoice details
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Invoice#: ${invoice.invoice_no || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Invoice Date: ${new Date(invoice.created_at).toLocaleDateString()}`, pageWidth - margin, 45, { align: 'right' });
    doc.text(`Service descriptions: Security Services`, pageWidth - margin, 50, { align: 'right' });
    doc.text(`Service Period: ${invoice.invoice_start_date} to ${invoice.invoice_end_date}`, pageWidth - margin, 55, { align: 'right' });
  
    // Add main table
    doc.autoTable({
      startY: 80,
      head: [['S/N', 'STAFF NAME', 'SHIFT DATE', 'SHIFT START TIME', 'SHIFT END TIME', 'SHIFT HOURS', 'CHARGE RATE', 'TOTAL SHIFT AMOUNT']],
      body: invoice.shift_data.map((item, index) => [
        index + 1,
        item.shift_assign_staff,
        item.shift_start_date,
        item.shift_start_time,
        item.shift_end_time,
        parseFloat(item.total_hrs || 0).toFixed(2),
        `£${parseFloat(item.hr_amount || 0).toFixed(2)}`,
        `£${parseFloat(item.total_amount || 0).toFixed(2)}`
      ]),
      theme: 'grid',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        3: { cellWidth: 'auto' },
        4: { cellWidth: 'auto' },
        5: { cellWidth: 'auto' },
        6: { cellWidth: 'auto' },
        7: { cellWidth: 'auto' }
      },
      margin: { top: 10 },
    });
  
    // Add Total row
    const totalHours = parseFloat(invoice.total_hrs || 0);
    const totalAmount = invoice.shift_data.reduce((sum, item) => sum + parseFloat(item.total_amount || 0), 0);
  
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [['Total', '']],
      body: [
        ['Total Hours', totalHours.toFixed(2)],
        ['Total Amount', `£${totalAmount.toFixed(2)}`]
      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' }
      },
    });
  
    // Add Summary
    const finalY = doc.lastAutoTable.finalY + 20;
    doc.setFontSize(14);
    doc.text('Summary:', margin, finalY);
    
    const summaryData = [
      ['NET AMOUNT', `£${parseFloat(invoice.net_total || 0).toFixed(2)}`],
      ['VAT @ 20%', `£${parseFloat(invoice.vat || 0).toFixed(2)}`],
      ['Amount With VAT', `£${(parseFloat(invoice.net_total || 0) + parseFloat(invoice.vat || 0)).toFixed(2)}`],
      ['Billable Expenses', `£${parseFloat(invoice.expense || 0).toFixed(2)}`],
      ['Gross Amount', `£${parseFloat(invoice.gross_total || 0).toFixed(2)}`]
    ];
  
    doc.autoTable({
      startY: finalY + 10,
      head: [['Description', 'Amount']],
      body: summaryData,
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' }
      },
    });
  
    return doc.output('blob');
  };
  
  const previewPDF = (invoice) => {
    const pdfBlob = generatePDF(invoice);
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  };

  const exportToExcel = () => {
    let data;
    if (exportType === 'Shift Wise') {
      data = invoiceData.shift_data.map(item => ({
        'Staff Name': item.shift_assign_staff,
        'Shift Date': item.shift_start_date,
        'Shift Start Time': item.shift_start_time,
        'Shift End Time': item.shift_end_time,
        'Shift Hours': item.total_hrs,
        'Charge Rate': item.hr_amount,
        'Total Shift Amount': item.total_amount
      }));
    } else if (exportType === 'Site Wise') {
      // Assuming you have site-wise data, adjust this accordingly
      data = [
        {
          'Site Name': invoiceData.client_name,
          'Total Hours': invoiceData.total_hrs,
          'Total Amount': invoiceData.gross_amount
        }
      ];
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(workbook, `Invoice_${exportType}.xlsx`);
  };


  const handleShow = () => setShowExport(true);
  const handleClose = () => setShowExport(false);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const invoiceId = location.state?.id;
        if (!invoiceId) {
          setError("No invoice ID provided");
          setLoading(false);
          return;
        }
  
        const response = await axios.post(
          "https://srltd.megworld.in/api/view_invoice_shift",
          { id: invoiceId },
          {
            headers: {
              Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
            }
          }
        );
        console.log("Invoice Data:",response.data.data.shift_data)
        setInvoiceData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        setError("Failed to fetch invoice data. Please try again later.");
        setLoading(false);
      }
    };
  
    fetchInvoiceData();
  }, [location.state]);

  const getNavigationPath = () => {
    const breadcrumbPaths = [
      { name: 'Accounts', bold: false },
      { name: 'Invoice', bold: false },
      { name: 'Manage Invoice', path: '/manageinvoice', bold: false },
      { name: 'View Shift Invoice', bold: true },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoiceData) {
    return <div>No invoice data available.</div>;
  }

    const totalExpenses = parseFloat(invoiceData.expense || 0);
  const totalAmount = invoiceData.shift_data.reduce((sum, item) => sum + parseFloat(item.total_amount || 0), 0);
  const totalNetAmount = parseFloat(invoiceData.net_total || 0);
  const vat = parseFloat(invoiceData.vat || 0);
  const totalAmountWithVAT = totalNetAmount + vat;
  const totalGrossAmount = parseFloat(invoiceData.gross_total || 0);



  // const exportToExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(invoiceData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
  //   XLSX.writeFile(workbook, "ViewInvoice_data.xlsx");
  // }
  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-3" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{ fontSize: '15px' }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <Link
                to={part.path}
                onClick={(e) => handleClick(part.path, e)}
                style={{
                  fontWeight: part.bold ? 'bold' : 'normal',
                  color: 'white',
                  fontSize: part.bold ? '18px' : '15px',
                  textDecoration: 'none'
                }}
              >
                {part.name}
              </Link>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>

      <div className="invoice-container">
        <div className="invoice-header">
          <div className="company-info">
            <h2>{invoiceData.client_name}</h2>
            <p>{invoiceData.client_address}</p>
            <p>Mobile No: {invoiceData.client_contact_number}</p>
            <p>E-mail: {invoiceData.client_contact_email}</p>
          </div>

          <div className="image-preview-container">
            <div className="image-preview">
              <img src={SGSLogoImg} alt="" />
            </div>
          </div>

          <div className="invoice-info">
            <p>Invoice No: {invoiceData.invoice_no}</p>
            <p>Invoice Date: {new Date(invoiceData.created_at).toLocaleDateString()}</p>
            <p>Service descriptions: Security Services</p>
            <p>Service Period: {invoiceData.invoice_start_date} to {invoiceData.invoice_end_date}</p>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>SHIFT MODE</th>
              <th>SHIFT START DATE</th>
              <th>SHIFT END DATE</th>
              <th>SHIFT START TIME</th>
              <th>SHIFT END TIME</th>
              <th>BREAK</th>
              <th>TOTAL SHIFT AMOUNT</th>
            </tr>
          </thead>
          <tbody>
        {invoiceData.shift_data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.shift_mode}</td>
            <td>{item.shift_start_date}</td>
            <td>{item.shift_end_date}</td>
            <td>{item.shift_start_time}</td>
            <td>{item.shift_end_time}</td>
            <td>{item.shift_break}</td>
            <td>£{parseFloat(item.total_amount || 0).toFixed(2)}</td>
          </tr>
        ))}
        <tr className="total-row">
          <td colSpan="5">Total</td>
          <td></td>
          <td></td>
          <td>£{totalAmount.toFixed(2)}</td>
        </tr>
      </tbody>
        </table>

        <div className="invoice-summary">
          <table className="summary-table">
            <tbody>
              <tr>
                <td>NET AMOUNT</td>
                <td>£{totalNetAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td>VAT @ 20%</td>
                <td>£{vat.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Amount With VAT</td>
                <td>£{totalAmountWithVAT.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Billable Expenses</td>
                <td>£{totalExpenses.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Gross Amount</td>
                <td>£{totalGrossAmount.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
       
        </div>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
            variant="contained"
            color="primary"
            onClick={handleShow}
          >
            Export Invoice
          </Button>
        </div>

        <Modal show={showExport} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Export Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select onChange={(e) => setExportType(e.target.value)}>
                  <option>Select Type</option>
                  <option>Shift Wise</option>
                  <option>Site Wise</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Format</Form.Label>
                <Form.Select onChange={(e) => setExportFormat(e.target.value)}>
                  <option>Select Format</option>
                  <option>PDF</option>
                  <option>EXCEL</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleExport}>
              EXPORT
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              CLOSE
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ViewShiftInvoice;