import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignIn.css";
import axios from "axios";
import { useAuth } from "./ProtectedRoute"; 
import Swal from 'sweetalert2';
import SGS_Logo from "../SignIn/media/logos/SGS-Logo-1-1024x387.webp";
// import { useUser } from "../SignIn/UserContext";

const SignIn = ({handleLogin}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();

  // const { setUser } = useUser();
  
  const showLoading = () => {
    Swal.fire({
      title: '',
      html: '<b>Be patient.</b><br/>This might take a few moments to sign in.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  };

  const closeLoading = () => {
    Swal.close();
  };

  const validate = () => {
    const errors = {};
    
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
    }
  
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(password)) {
      errors.password = "Password must contain at least one uppercase letter, one lowercase letter, and one number";
    }
  
    return errors;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const payload = { email, password };
      const _token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
      
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/login",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${_token}`,
            },
          }
        );
        const data = response.data.data;
        
        if (response.data.success) {
          const authToken = data.token;
          localStorage.setItem("token", authToken);
          localStorage.setItem("role", data.role_name);
          localStorage.setItem("phoneNumber", data.phone_number);

          // // Store user data in context
          // setUser({
          //   role: data.role_name,
          //   phoneNumber: data.phone_number, 
          // });

          // handleLogin()
         
        Swal.fire({
          icon: 'success',
          title: 'Login successful',
          text: 'You will be redirected to the dashboard.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            showLoading(); // Show loading after clicking OK

            setTimeout(() => {
              closeLoading(); // Clear loading after 1 second
              handleLogin(true); // Update logged-in state
              navigate("/dashboardpage"); // Redirect to dashboard
            }, 1000); // 1 second delay
          }
        });

        } else {
          console.error("Login error:", response.data.message);
          setError({ submit: response.data.message });
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: response.data.message || 'Invalid credentials. Please try again.',
          });
        }
      } catch (err) {
        console.error("Login error:", err.response?.data || err.message);
        setError({ submit: "An error occurred during login" });
        Swal.fire({
          icon: 'error',
          title: 'Login Error',
          text: 'An error occurred during login. Please try again later.',
        });
      }
    }
  };



  return (
    <div className="body">
    <div className="auth-container">
    <div className="auth-form-container">
      <div className="auth-form">
        <img src={SGS_Logo} alt="SGS Logo" className="auth-logo" />
        <h2>Sign In</h2>
        {/* <div className="social-auth">
        <a href="https://www.instagram.com/azhagar.krish/"> <button className="social-btn"><i className="fab fa-instagram"></i></button></a>
        <button className="social-btn"><i className="fab fa-google"></i></button>
          <button className="social-btn"><i className="fab fa-facebook"></i></button>
        </div>
        <p className="auth-divider">or use your account</p> */}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            className="auth-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          {error.email && <div className="error" style={{fontSize:'13px'}}>{error.email}</div>}
          <input
            type="password"
            placeholder="Password"
            className="auth-input"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error.password && <div className="error" style={{fontSize:'13px'}}>{error.password}</div>}
          <a href="#" className="forgot-password" style={{fontSize:'13px'}}>Forgot your password?</a>
          {error.submit && <div className="error">{error.submit}</div>}
          <button type="submit" className="auth-button">SIGN IN</button>
        </form>
      </div>
    </div>
    <div className="auth-welcome">
      <h2 style={{color:'white'}}>Welcome Back !</h2>
      {/* <p>Enter your SignUp details and start your journey with us</p>
      <button className="auth-switch-button" onClick={() => navigate("/signup")}>SIGN UP</button> */}
    </div>
  </div>
  </div>
  );
};
export default SignIn;
