import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "./Dashboard";
import { useAuth } from "../SignIn/ProtectedRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Dashboard.css";
import { Modal, Form } from "react-bootstrap";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { Pie, Doughnut, Bar } from "react-chartjs-2";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend as RechartsLegend,
  ResponsiveContainer,
} from "recharts";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartJSTooltip,
  Legend as ChartJSLegend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
ChartJS.register(
  ArcElement,
  ChartJSTooltip,
  ChartJSLegend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8",
  "#82ca9d",
];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const getFormattedDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
};

const DashboardPage = ({handleLogout}) => {
  const [chartData, setChartData] = useState([]);
  const [siaLicensesData, setSiaLicensesData] = useState([]);
  const [documentTypeData, setDocumentTypeData] = useState({});
  const [eventData, setEventData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchEmployeeTypeData = async () => {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/employee_type_dashboard",
          {
            headers: {
              Authorization:
                "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            },
          }
        );

        const data = response.data.data.map((item) => ({
          label: item.employee_type,
          value: item.count,
        }));

        setChartData({
          labels: data.map((item) => item.label),
          datasets: [
            {
              data: data.map((item) => item.value),
              backgroundColor: COLORS,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEmployeeTypeData();
  }, []);

  useEffect(() => {
    const fetchSiaData = async () => {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sia_license_dashboard",
          {
            headers: {
              Authorization:
                "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            },
          }
        );

        setSiaLicensesData(
          response.data.data.map((item) => ({
            employee_sia_type: item.employee_sia_type,
            count: item.count,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSiaData();
  }, []);

  useEffect(() => {
    const fetchEventData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/event_type_dashboard",
          {},
          {
            headers: {
              Authorization:
                "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            },
          }
        );

        setEventData(
          response.data.data.map((item) => ({
            name: item.event_type,
            value: parseInt(item.count, 10) || 0,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEventData();
  }, []);

  useEffect(() => {
    const fetchDocumentTypeData = async () => {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/document_type_dashboard",
          {
            headers: {
              Authorization:
                "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            },
          }
        );

        const labels = response.data.data.map((item) => item.type_document);
        const values = response.data.data.map((item) =>
          parseInt(item.count, 10)
        );

        setDocumentTypeData({
          labels,
          datasets: [
            {
              label: "Document Count",
              data: values,
              backgroundColor: COLORS,
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 4,
              barThickness: 40,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDocumentTypeData();
  }, []);

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  // const renderResponseData = (response) => {
  //   if (!response) return null;
  //   return (
  //     <div className="mt-3">
  //       <h4>API Response:</h4>
  //       <pre>{JSON.stringify(response, null, 2)}</pre>
  //     </div>
  //   );
  // };

  const date = getFormattedDate();

  const [activeStaff, setActiveStaff] = useState(0);
  const [activeClients, setActiveClients] = useState(0);
  const [activeSites, setActiveSites] = useState(0);
  const [currentDate, setCurrentDate] = useState(date);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const staffResponse = await axios.post(
          "https://srltd.megworld.in/api/active_staff"
        );
        console.log("Staff:", staffResponse.data.data);
        setActiveStaff(staffResponse.data.data);

        const clientResponse = await axios.post(
          "https://srltd.megworld.in/api/active_client"
        );
        console.log("Client:", clientResponse);
        setActiveClients(clientResponse.data.data);
        const siteResponse = await axios.post(
          "https://srltd.megworld.in/api/active_site"
        );
        console.log("Site:", siteResponse);
        setActiveSites(siteResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [events, setEvents] = useState([]);
  const [callout, setCallout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCalloutModal, setShowCalloutModal] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedCallout, setSelectedCallout] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchEvents();
  }, []);
  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/event_show",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      if (response.data && response.data.success) {
        setEvents(response.data.data);
      } else {
        setError("No event data found");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Error fetching events: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCallout();
  }, []);
  const fetchCallout = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/callout_show",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      if (response.data && response.data.success) {
        setCallout(response.data.data);
      } else {
        setError("No event data found");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Error fetching events: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowCalloutModal = (callouts) => {
    setSelectedCallout(callouts);
    setShowCalloutModal(true);
  };

  const handleCloseCalloutModal = () => {
    setShowCalloutModal(false);
    setSelectedCallout(null);
  };
  const handleShowModal = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };
  const [tableBar, setTableBar] = useState(false);

  const handleShowBar = () => {
    setTableBar(true);
  };
  const handleCloseBar = () => {
    setTableBar(false);
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const employmentTypeData = {
  //   labels: ["Non-operational", "Operational"],
  //   datasets: [
  //     {
  //       data: [40, 60],
  //       backgroundColor: ["rgb(0, 192, 239)", "rgb(255, 159, 64)"],
  //       borderWidth: 0,
  //     },
  //   ],
  // };
  // const employmentTypeData2 = {
  //   labels: ["Non-operational", "Operational"],
  //   datasets: [
  //     {
  //       data: [20, 80],
  //       backgroundColor: ["rgb(0, 192, 239)", "rgb(255, 159, 64)"],
  //       borderWidth: 0,
  //     },
  //   ],
  // };
  // const siaLicensesData = {
  //   labels: ["Door Supervision", "Close Protection"],
  //   datasets: [
  //     {
  //       data: [75, 25],
  //       backgroundColor: ["rgb(0, 192, 239)", "rgb(255, 159, 64)"],
  //       borderWidth: 0,
  //     },
  //   ],
  // };
  // const siaLicensesData2 = {
  //   labels: ["Complaint"],
  //   datasets: [
  //     {
  //       data: [90],
  //       backgroundColor: ["rgb(0, 192, 239)", "rgb(255, 159, 64)"],
  //       borderWidth: 0,
  //     },
  //   ],
  // };

  // const pieChartOptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "right",
  //       labels: {
  //         usePointStyle: true,
  //         pointStyle: "rect",
  //         padding: 20,
  //         font: {
  //           size: 12,
  //         },
  //       },
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           let label = context.label || "";
  //           if (label) {
  //             label += ": ";
  //           }
  //           if (context.parsed !== null) {
  //             label += context.parsed + "%";
  //           }
  //           return label;
  //         },
  //       },
  //     },
  //   },
  //   cutout: "60%",
  // };
  // const chartData = {
  //   labels: [
  //     "15-01-2024",
  //     "16-01-2024",
  //     "17-01-2024",
  //     "18-01-2024",
  //     "19-01-2024",
  //     "20-01-2024",
  //     "21-01-2024",
  //   ],
  //   datasets: [
  //     {
  //       label: "Hours",
  //       data: [0, 0, 0, 0, 0, 0, 0],
  //       borderColor: "rgb(75, 192, 192)",
  //       tension: 0.1,
  //     },
  //     {
  //       label: "Shifts",
  //       data: [0, 0, 0, 0, 0, 0, 0],
  //       borderColor: "rgb(54, 162, 235)",
  //       tension: 0.1,
  //     },
  //   ],
  // };

  // const chartOptions = {
  //   responsive: true,
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //       max: 12,
  //       ticks: {
  //         stepSize: 2,
  //       },
  //     },
  //   },
  // };

  const role = localStorage.getItem("role");
  console.log("User Role Name:",role)


  const pieChartData = {
    labels: ["Type 1", "Type 2"],
    datasets: [
      {
        data: [60, 40],
        backgroundColor: ["rgb(0, 192, 239)", "rgb(255, 159, 64)"],
        hoverOffset: 4,
      },
    ],
  };

  const [shiftData, setShiftData] = useState([]);
  const [filterShift, setFilterShift] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchShiftData();
  }, []);

  const fetchShiftData = async () => {
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_shift_dashboard",
        { phone_number: phoneNumber },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Current Shift Roster:", response);

      const newShiftData = JSON.parse(localStorage.getItem("newShiftData"));

      if (newShiftData) {
        const updatedShiftData = [newShiftData, ...response.data.data];
        setShiftData(updatedShiftData);
        setFilterShift(updatedShiftData);
        localStorage.removeItem("newShiftData");
      } else {
        setShiftData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching shift data:", error);
    }
  };
  useEffect(() => {
    const filtered = shiftData.filter((shift) =>
      Object.values(shift).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilterShift(filtered);
    setCurrentPage(0);
  }, [searchTerm, shiftData]);

  const [siaLicenseExpiry, setSiaLicenseExpiry] = useState([]);
  const [currentSiaPage, setCurrentSiaPage] = useState(0);
  const [siaPerPage, setSiaPerPage] = useState(10);
  const [searchSiaTerm, setSearchSiaTerm] = useState("");
  const [filterSia, setFilterSia] = useState([]);
  const [siaLicenseSub, setSiaLicenseSub] = useState([]);
  const [siaVisaExpiry, setSiaVisaExpiry] = useState([]);

  const [Loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const filtered = siaLicenseExpiry.filter((sia) =>
      Object.values(sia).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilterSia(filtered);
    setCurrentPage(0);
  }, [searchTerm, siaLicenseExpiry]);

  useEffect(() => {
    const fetchData = async () => {
      const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sia_license_expiry",
          {},
          { headers }
        );
        setSiaLicenseExpiry(response.data.data || []);
        setFilterSia(response.data.data || []);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setErrors("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sia_expiry_sub",
          {},
          { headers }
        );
        setSiaLicenseSub(response.data.data || []);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setErrors("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/visa_expiry_sub",
          {},
          { headers }
        );
        setSiaVisaExpiry(response.data.data || []);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setErrors("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(shiftData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "shiftData");
    XLSX.writeFile(workbook, "shiftData.xlsx");
  };

  const handleClick = () => {
    navigate("/view");
  };
  const handleClientClick = () => {
    navigate("/viewclient");
  };
  const handleSiteClick = () => {
    navigate("/viewsite");
  };

  const tooltipStyles = {
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.87)",
      color: "#fff",
      fontSize: "14px",
      padding: "8px 12px",
      borderRadius: "4px",
    },
    arrow: {
      color: "rgba(0, 0, 0, 0.87)",
    },
  };

  const renderCurrentShiftTable = () => (
    <>
  
    <Box sx={{ width: "100%", mt: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <h3 style={{ marginTop: "10px" }}>CURRENT SHIFTS DETAILS FOR {currentDate}</h3>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Client Name</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Site Name</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Staff Name</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Shift Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Start Date</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>End Date</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Start Time</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>End Time</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Break</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Comments</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Instructions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterShift
                .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                .map((shift, index) => (
                  <TableRow key={index} sx={{ textAlign: "center" }} hover>
                    <TableCell>{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{shift.shift_client_name}</TableCell>
                    <TableCell>{shift.shift_site_name}</TableCell>
                    <TableCell>{shift.shift_assign_staff}</TableCell>
                    <TableCell>{shift.shift_type}</TableCell>
                    <TableCell>{shift.shift_start_date}</TableCell>
                    <TableCell>{shift.shift_end_date}</TableCell>
                    <TableCell>{shift.shift_start_time}</TableCell>
                    <TableCell>{shift.shift_end_time}</TableCell>
                    <TableCell>{shift.shift_break ? "Yes" : "No"}</TableCell>
                    <TableCell>{shift.shift_comments}</TableCell>
                    <TableCell>{shift.shift_instructions}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={filterShift.length}
          rowsPerPage={entriesPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => setCurrentPage(newPage)}
          onRowsPerPageChange={(event) => {
            setEntriesPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </Paper>
    </Box>
    </>
  );

  const renderAllData = ()=>(
    <>
    {/* <Dashboard handleLogout={handleLogout} /> */}
    

    <div className="container-fluid mt-3">
      <div className="row justify-content-center">
        <div
          className="col-md-3 mb-3"
          onClick={handleClick}
          style={{ cursor: "pointer" }}>
          <div className="card bg-primary text-white">
            <div className="card-body">
              <div className="d-flex">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <i
                    className="fas fa-users me-2"
                    style={{ color: "white", fontSize: "30px" }}></i>
                  <h5
                    className="card-title mb-0 me-2"
                    style={{ fontSize: "20px" }}>
                    {activeStaff}
                  </h5>
                  <p className="card-text" style={{ fontSize: "16px" }}>
                    ACTIVE STAFF
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-md-3 mb-3"
          onClick={handleClientClick}
          style={{ cursor: "pointer" }}>
          <div className="card bg-info text-white">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <i
                    className="fas fa-briefcase me-2"
                    style={{ color: "white", fontSize: "30px" }}></i>
                  <h5
                    className="card-title mb-0 me-2"
                    style={{ fontSize: "20px" }}>
                    {activeClients}
                  </h5>
                  <p className="card-text" style={{ fontSize: "16px" }}>
                    ACTIVE CLIENTS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-md-3 mb-3"
          onClick={handleSiteClick}
          style={{ cursor: "pointer" }}>
          <div className="card bg-success text-white">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <i
                    className="fas fa-map-marker-alt fa-1x me-2"
                    style={{ color: "white", fontSize: "30px" }}></i>
                  <h5
                    className="card-title mb-0 me-2"
                    style={{ fontSize: "20px" }}>
                    {activeSites}
                  </h5>
                  <p className="card-text" style={{ fontSize: "16px" }}>
                    ACTIVE SITES
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card mb-4 mt-15">
        <div className="card-header"> */}
      {/* <div className="d-flex justify-content-between align-items-center"> */}
      {/* <div>
              <span style={{ color: "black", fontSize: "15px" }}>
                All Shifts Status
              </span>
              <select className="form-select d-inline-block w-auto mx-2">
                <option>Select Shift Status</option>
                <option>All Shift Status</option>
                <option>Confirmed</option>
                <option>Un Confirmed</option>
                <option>Un Assigned</option>
              </select>
              <span>entries</span>
            </div> */}
      {/* <div>
              <span className="me-2"></span>
              <input
                className="form-control d-inline-block w-auto"
                value= {currentDate}               />
            </div> */}
      {/* </div>
        </div> */}

      {/* <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              Show
              <select className="form-select d-inline-block w-auto mx-2">
                {[10, 50, 100, 500].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
            <div className="d-flex align-items-center">
              <Button variant="primary" className="m-0">
                Search
              </Button>
              <input
                type="text"
                className="form-control ms-2"
                // style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr style={{fontSize:'15px'}}>
                <th>S.NO</th>
                <th>Client Name</th>
                <th>Site Name</th>
                <th>Staff Name</th>
                <th>Shift Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Break</th>
                <th>Comments</th>
                <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
              {shiftData.map((shift, index) => (
                <tr key={index} style={{fontSize:'13px'}}>
                  <td>{index + 1}</td>
                  <td>{shift.shift_client_name}</td>
                  <td>{shift.shift_site_name}</td>
                  <td>{shift.shift_assign_staff}</td>
                  <td>{shift.shift_type}</td>
                  <td>{shift.shift_start_date}</td>
                  <td>{shift.shift_end_date}</td>
                  <td>{shift.shift_start_time}</td>
                  <td>{shift.shift_end_time}</td>
                  <td>{shift.shift_break ? 'Yes' : 'No'}</td>
                  <td>{shift.shift_comments}</td>
                  <td>{shift.shift_instructions}</td>
                  
                </tr>
              ))}
            </tbody>
            </table>
          </div>
          <div>Showing 0 to 0 of 0 entries</div>
        </div> */}
      <Box
        sx={{
          width: "100%",
          mt: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <h3 style={{ marginTop: "10px" }}>
            CURRENT SHIFTS DETAILS FOR {currentDate}
          </h3>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}>
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Site Name</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Staff Name</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Shift Type</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Start Date</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>End Date</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Start Time</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>End Time</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Break</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Comments</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    Instructions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterShift
                  .slice(
                    currentPage * entriesPerPage,
                    currentPage * entriesPerPage + entriesPerPage
                  )
                  .map((shift, index) => (
                    <TableRow key={index} sx={{ textAlign: "center" }} hover>
                      <TableCell>
                        {currentPage * entriesPerPage + index + 1}
                      </TableCell>
                      <TableCell>{shift.shift_client_name}</TableCell>
                      <TableCell>{shift.shift_site_name}</TableCell>
                      <TableCell>{shift.shift_assign_staff}</TableCell>
                      <TableCell>{shift.shift_type}</TableCell>
                      <TableCell>{shift.shift_start_date}</TableCell>
                      <TableCell>{shift.shift_end_date}</TableCell>
                      <TableCell>{shift.shift_start_time}</TableCell>
                      <TableCell>{shift.shift_end_time}</TableCell>
                      <TableCell>
                        {shift.shift_break ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{shift.shift_comments}</TableCell>
                      <TableCell>{shift.shift_instructions}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={filterShift.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </div>
    {/* <div className="row mt-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="mb-0">WEEKLY SHIFTS STATUS</h3>
              <div>
                <span className="me-2" style={{ color: "black" }}>
                  WEEK
                </span>
                <input
                  type="date"
                  className="form-control d-inline-block w-auto"
                  value={currentDate}
                />
              </div>
            </div>
            <div className="card-body">
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
</div> */}
    <div className="row mt-4" style={{ marginLeft: "20px" }}>
      <div className="col-md-6">
        {/* <div className="card">
    <div className="card-header d-flex justify-content-between align-items-center">
      <h3 className="mb-0">CALLOUT</h3>
    </div>
    <div className="card-body">
      <div className="table-responsive" style={{ maxHeight: "180px", overflowY: "auto" }}>
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "15px" }}>
              <th>S.NO</th>
              <th>CallOut Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Details</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {callout.map((callouts, index) => (
              <tr key={index} style={{ fontSize: "15px" }}>
                <td>{index + 1}</td>
                <td>{callouts.callout_date}</td>
                <td>{callouts.callout_start_time}</td>
                <td>{callouts.callout_end_time}</td>
                <td>{callouts.callout_details}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleShowCalloutModal(callouts)}
                  >
                    <i className="fas fa-eye" style={{ color: "white" }}></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div> */}
        <TableContainer
          sx={{ maxWidth: "95%", maxHeight: 270, overflowY: "auto" }}>
          <h3 className="mb-0">CALLOUT</h3>
          <Table aria-labelledby="tableTitle" size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Callout Date</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Start Time</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>End Time</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Details</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callout.map((callouts, index) => (
                <TableRow key={index} sx={{ textAlign: "center" }} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{callouts.callout_date}</TableCell>
                  <TableCell>{callouts.callout_start_time}</TableCell>
                  <TableCell>{callouts.callout_end_time}</TableCell>
                  <TableCell>{callouts.callout_details}</TableCell>

                  <TableCell>
                    <IconButton
                      onClick={() => handleShowCalloutModal(callouts)}
                      className="me-2"
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "green",

                        "&:hover": { backgroundColor: "darkblue" },
                      }}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="col-md-6">
        {/* <div className="card">
    <div className="card-header d-flex justify-content-between align-items-center">
      <h3 className="mb-0">EVENTS</h3>
    </div>
    <div className="card-body">
      <div className="table-responsive" style={{ maxHeight: "180px", overflowY: "auto" }}>
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "15px" }}>
              <th>S.NO</th>
              <th>Date</th>
              <th>Time</th>
              <th>Event Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index} style={{ fontSize: "15px" }}>
                <td>{index + 1}</td>
                <td>{event.event_date}</td>
                <td>{event.event_time}</td>
                <td>{event.event_type}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleShowModal(event)}
                  >
                    <i className="fas fa-eye" style={{ color: "white" }}></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> */}
        <TableContainer
          sx={{ maxWidth: "95%", maxHeight: 270, overflowY: "auto" }}>
          <h3 className="mb-0">EVENTS</h3>
          <Table aria-labelledby="tableTitle" size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Event Date</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Event Time</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Event Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event, index) => (
                <TableRow key={index} sx={{ textAlign: "center" }} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{event.event_date}</TableCell>
                  <TableCell>{event.event_time}</TableCell>
                  <TableCell>{event.event_type}</TableCell>

                  <TableCell>
                    <IconButton
                      onClick={() => handleShowModal(event)}
                      className="me-2"
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "green",

                        "&:hover": { backgroundColor: "darkblue" },
                      }}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </div>
</div> */}
      </div>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Event Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Event Date</Form.Label>
            <Form.Control type="date" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Event Time</Form.Label>
            <Form.Control type="time" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Event Type</Form.Label>
            <Form.Control as="select" style={{ width: "100%" }}>
              <option>Select event type ...</option>
              <option>Power off</option>
              <option>Lateness</option>
              <option>Blow out</option>
              <option>Complaint</option>
              <option>Incident</option>
              <option>Accident</option>
              <option>Others</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Event Detail</Form.Label>
            <Form.Control as="textarea" style={{ width: "100%" }} />
          </Form.Group>{" "}
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Site</Form.Label>
            <Form.Control type="text" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Employee</Form.Label>
            <Form.Control type="text" style={{ width: "100%" }} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Add Event</Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showCalloutModal} onHide={handleCloseCalloutModal}>
      <Modal.Header closeButton>
        <Modal.Title>Callout Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Callout Date</Form.Label>
            <Form.Control type="date" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Start Time</Form.Label>
            <Form.Control type="time" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>End Time</Form.Label>
            <Form.Control type="time" style={{ width: "100%" }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Callout Detail</Form.Label>
            <Form.Control as="textarea" style={{ width: "100%" }} />
          </Form.Group>{" "}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Add Callout</Button>
        <Button variant="secondary" onClick={handleCloseCalloutModal}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>

    <div className="container-fluid mt-4">
      <div className=" mt-4">
        <div className="row justify-content-center">
          <div className="container col-md-5 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Employee Type</h3>
              </div>
              <div className="card-body">
                <div
                  style={{ height: "350px" }}
                  className="d-flex justify-content-center">
                  {chartData && chartData.labels ? (
                    <Pie data={chartData} options={pieChartOptions} />
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>{" "}
            </div>
          </div>

          <div className="container col-md-5 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="mb-0">S.I.A Licenses</h3>
              </div>
              <div className="card-body">
                <div
                  style={{ height: "350px" }}
                  className="d-flex justify-content-center">
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                      data={siaLicensesData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis
                        dataKey="employee_sia_type"
                        tick={{ fill: "#666", fontSize: 12 }}
                        axisLine={{ stroke: "#ccc" }}
                      />
                      <YAxis
                        type="number"
                        domain={["dataMin - 100", "dataMax + 100"]}
                        tick={{ fill: "#666", fontSize: 12 }}
                        axisLine={{ stroke: "#ccc" }}
                      />
                      <RechartsTooltip />
                      <RechartsLegend />
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#4e79a7"
                        strokeWidth={2}
                        dot={{ fill: "#4e79a7", r: 6 }}
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <div className="row justify-content-center">
          <div className="container col-md-5 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Document Type</h3>
              </div>
              <div className="card-body">
                <div className="chart-container">
                  <div
                    style={{ height: "360px" }}
                    className="d-flex justify-content-center">
                    {documentTypeData && documentTypeData.labels ? (
                      <Bar
                        data={documentTypeData}
                        options={pieChartOptions}
                      />
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-md-5 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Events Type</h3>
              </div>
              <div className="card-body">
                <div
                  style={{ height: "350px" }}
                  className="d-flex justify-content-center">
                  {eventData && eventData.length > 0 ? (
                    <Doughnut
                      data={{
                        labels: eventData.map((item) => item.name),
                        datasets: [
                          {
                            data: eventData.map((item) => item.value),
                            backgroundColor: COLORS,
                            hoverBackgroundColor: COLORS.map(
                              (color) => `${color}cc`
                            ), // Slightly transparent on hover
                          },
                        ],
                      }}
                      options={pieChartOptions}
                    />
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </div> */}

    {/* <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              Show
              <select className="form-select d-inline-block w-auto mx-2">
                {[10, 50, 100, 500].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
            <div className="d-flex align-items-center">
              Search:
              <input
                type="text"
                className="form-control ms-2"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr style={{ fontSize: "15px" }}>
                <th>S.No</th>
                <th>Staff PIN</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Employee Type</th>
                <th>SIA Number</th>
                <th>SIA Type</th>
                <th>SIA Expiry</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {siaLicenseExpiry.length > 0 ? (
                siaLicenseExpiry.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{`${item.employee_forename} ${item.employee_middname} ${item.employee_surname}`.trim()}</td>
                    <td>{item.employee_staff_pin}</td>
                    <td>{item.employee_contactno}</td>
                    <td>{item.employee_type}</td>
                    <td>{item.employee_sia_no}</td>
                    <td>{item.employee_sia_type}</td>
                    <td>{item.employee_expdate}</td>
                    <td>{new Date(item.employee_expdate) < new Date() ? 'Expired' : 'Active'}</td>
                  </tr>
                ))
              ) : (
                <tr className='text-center'>
                  <td colSpan={7}>No data available in Table</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Showing 1 to 1 of entries</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}
    <Box
      sx={{
        width: "100%",
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Paper sx={{ width: "90%", mb: 2 }}>
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          SIA License Expiry(Employees)
        </h3>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}>
            Export to Excel
          </Button>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Staff Pin</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Name</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Employee Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>SIA Number</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>SIA Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Expiry</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ padding: "8px" }}>
              {filterSia
                .slice(
                  currentSiaPage * siaPerPage,
                  currentSiaPage * siaPerPage + siaPerPage
                )
                .map((item, index) => (
                  <TableRow key={index} sx={{ textAlign: "center" }} hover>
                    <TableCell sx={{ padding: "5px" }}>
                      {currentSiaPage * siaPerPage + index + 1}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_forename}
                      {item.employee_middname && ` ${item.employee_middname}`}
                      {item.employee_surname && ` ${item.employee_surname}`}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_staff_pin}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_contactno}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_type}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_sia_no}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_sia_type}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.employee_expdate}
                    </TableCell>
                    {/* <TableCell sx={{ padding: '5px' }}>
<IconButton 
  component={Link} 
  to="/viewemployeeinfo" 
  className="me-2"
  size="small" 
  sx={{ 
    color: 'white', 
    backgroundColor: 'green', 

    '&:hover': { backgroundColor: 'darkblue' } 
  }}
>
  <VisibilityIcon />
</IconButton>

<IconButton 
  component={Link} 
  to="/employeeform" 
  className="me-2"

  size="small" 
  sx={{ 
    color: 'white', 
    backgroundColor: 'blue', 
    '&:hover': { backgroundColor: 'darkgreen' } 
  }}
>
  <EditIcon />
</IconButton>

<IconButton 
  component={Link} 
  to="/employeechecklist" 
  className="me-2"
  size="small" 
  sx={{ 
    color: 'white', 
    backgroundColor: 'orange', 
    '&:hover': { backgroundColor: 'darkorange' } 
  }}
>
  <ListAltIcon />
</IconButton>

<IconButton 
  onClick={() => handleDelete(employee.id)} 
  size="small" 
  className="me-2"
  sx={{ 
    color: 'white', 
    backgroundColor: 'red', 
    '&:hover': { backgroundColor: 'darkred' } 
  }}
>
  <DeleteIcon />
</IconButton>
</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={filterSia.length}
          rowsPerPage={siaPerPage}
          page={currentSiaPage}
          onPageChange={(event, newPage) => setCurrentSiaPage(newPage)}
          onRowsPerPageChange={(event) => {
            setSiaPerPage(parseInt(event.target.value, 10));
            setCurrentSiaPage(0);
          }}
        />
      </Paper>
    </Box>
    {/* <div className="container mt-4">
      <div className="card">
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          SIA License Expiry(Sub Employees)
        </h3>

        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              Show
              <select className="form-select d-inline-block w-auto mx-2">
                {[10, 50, 100, 500].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
            <div className="d-flex align-items-center">
              Search:
              <input
                type="text"
                className="form-control ms-2"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr style={{ fontSize: "15px" }}>
                  <th>S.No</th>
                  <th>SubContractors</th>
                  <th>Sub Employee</th>
                  <th>Contact</th>
                  <th>SIA Number</th>
                  <th>SIA Type</th>
                  <th>SIA Expiry</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td colSpan={8}>No datas available in Table</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Showing 1 to 1 of entries</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div> */}
    <Box
      sx={{
        width: "100%",
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Paper sx={{ width: "90%", mb: 2 }}>
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          SIA License Expiry(Sub Employees)
        </h3>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}>
            Export to Excel
          </Button>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Subcontractor</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Sub Employee</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Contact</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>SIA Number</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>SIA Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Expiry</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Status</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
    {/* <div className="container-fluid mt-4">
      <div className="card">
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          Visa Expiry(Employees)
        </h3>

        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              Show
              <select className="form-select d-inline-block w-auto mx-2">
                {[10, 50, 100, 500].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
            <div className="d-flex align-items-center">
              Search:
              <input
                type="text"
                className="form-control ms-2"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr style={{ fontSize: "15px" }}>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Visa Type</th>
                  <th>Visa Expiry</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark H</td>
                  <td>565756388313</td>
                  <td>Student</td>
                  <td>11-01-2024</td>
                  <td>Expired</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Showing 1 to 1 of entries</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div> */}
    {/* <div className="container mt-4">
      <div className="card">
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          Visa Expiry(Sub Employees)
        </h3>

        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              Show
              <select className="form-select d-inline-block w-auto mx-2">
                {[10, 50, 100, 500].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
            <div className="d-flex align-items-center">
              Search:
              <input
                type="text"
                className="form-control ms-2"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr style={{ fontSize: "15px" }}>
                  <th>S.No</th>
                  <th>Sub Contractors</th>
                  <th>Sub Employee</th>
                  <th>Contact</th>
                  <th>Visa Type</th>
                  <th>Visa Expiry</th>
                  <th>Expiry Days</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td colSpan={7}>No datas available in Table</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Showing 1 to 1 of entries</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div> */}
    <Box
      sx={{
        width: "100%",
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Paper sx={{ width: "90%", mb: 2 }}>
        <h3
          style={{ marginLeft: "10px", fontSize: "25px", marginTop: "15px" }}>
          Proof Of ID Expiry
        </h3>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}>
            Export to Excel
          </Button>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Subcontractor</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Sub Employee</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Contact</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Visa Type</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Visa Expiry</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Expiry</TableCell>
                <TableCell sx={{ fontSize: "15px" }}>Status</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  </>
  )
  return (
    <>
      {/* <Dashboard handleLogout={handleLogout} /> */}
      <div
        className="toolbar py-1"
        id="kt_toolbar"
        class="dashboard-page"
        style={{ backgroundColor: "#BF0404",zIndex:'999'}}>
        <div
          id="kt_toolbar_container"
          className="d-flex flex-stack flex-wrap mt-2">
          <h3
            className="text-white "
            style={{ fontSize: "15px", marginLeft: "40px" }}>
            Dashboard
          </h3>
        </div>
      </div>

      {role === 'Client' || role === 'Security Officer' ? (
        renderCurrentShiftTable()
      ) : (
        // Render all other dashboard content here
        <div>
          {/* Include all other dashboard components and content */}
          {renderAllData()}
        </div>
      )}
    </>
  );
};

export default DashboardPage;
