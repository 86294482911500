import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import Dashboard from "../Dashboard/Dashboard";
import "./Roster.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Select from 'react-select';
import {
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  User as UserIcon,
  Edit as EditIcon,
  Clock as ClockIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person'; 

const ClientRoster = ({ show, onHide }) => {


  const location = useLocation();
  // ... other code

  useEffect(() => {
    fetchShiftData();

    // Check if there's updated data from EditClientRoster
    if (location.state && location.state.updatedShift) {
      const updatedShift = location.state.updatedShift;
      setShiftData(prevData => 
        prevData.map(shift => 
          shift.id === updatedShift.id ? updatedShift : shift
        )
      );
      setFilteredShiftData(prevData => 
        prevData.map(shift => 
          shift.id === updatedShift.id ? updatedShift : shift
        )
      );
      // Clear the state to avoid unnecessary updates
      window.history.replaceState({}, document.title);
    }
  }, [location]);
  
  const [shiftData, setShiftData] = useState([]);
  const [filteredShiftData, setFilteredShiftData] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientName, setClientName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [date, setDate] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [subStaffData,setSubStaffData] = useState([]);
  const [shiftStaffData,setShiftStaffData] = useState([]);

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };


  const [shiftModeOptions, setShiftModeOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [subContractorOptions, setSubContractorOptions] = useState([]);

  const [addStaffData, setAddStaffData] = useState({
    shift_mode: '',
    shift_mode_id: '',
    shift_assign_staff: [],
    shift_assign_staff_id: '',
    shift_assign_subemp: [],
    shift_assign_subemp_id: '',
  });

  useEffect(() => {
    fetchShiftModes();
    fetchSubContractorData();
  }, [selectedShiftId]);


  const fetchShiftModes = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_mode_auto',
        { id: selectedShiftId },
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      const options = response.data.map(item => ({
        value: item.id,
        label: item.shift_mode
      }));
      setShiftModeOptions(options);
    } catch (error) {
      console.error('Error fetching shift modes:', error);
    }
  };

  useEffect(() => {
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/shift_staff",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      const options = response.data.data.map(staff => ({
        value: staff.value,
        label: staff.label,     
             }));
      setStaffOptions(options);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const handleStaffSelectChange = (selectedOptions) => {
    const staffData = selectedOptions.map(option => ({
      id: option.value,
      name: option.label
    }));
    
    setAddStaffData(prev => ({
      ...prev,
      shift_assign_staff: staffData,
      shift_assign_staff_id: staffData.map(staff => staff.id).join(',')
    }));
  };

  const fetchSubContractorData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      const options = response.data.data.map(subcontractor => ({
        value: subcontractor.id,
        label: subcontractor.subcon_company_name,         }));
      setSubContractorOptions(options);
    } catch (error) {
      console.error("Error fetching subcontractor data:", error);
    }
  };


  const handleSelectChange = (selectedOptions, name) => {
    if (name === 'shift_assign_staff') {
      handleStaffSelectChange(selectedOptions);
    } else if (name === 'shift_mode') {
      setAddStaffData(prev => ({
        ...prev,
        shift_mode: selectedOptions.label,
        shift_mode_id: selectedOptions.value
      }));
    } else if (name === 'shift_assign_subemp') {
      const subempData = selectedOptions.map(option => ({
        id: option.value,
        name: option.label
      }));
      setAddStaffData(prev => ({
        ...prev,
        shift_assign_subemp: subempData,
        shift_assign_subemp_id: subempData.map(subemp => subemp.id).join(',')
      }));
    }
  };



 
  const getNavigationPath = () => {
    return [
      { name: 'Roster', bold: false },
      { name: 'Client Roster', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  useEffect(() => {
    const filtered = shiftData.filter((shift) =>
      Object.values(shift).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredShiftData(filtered);
    setCurrentPage(0);
  }, [searchTerm, shiftData]);

  const [isClient, setIsClient] = useState(false);

  const role = localStorage.getItem("role");
  console.log("User Role Name:",role)

  useEffect(() => {
    fetchShiftData();
  }, []);

 
  
  
  const fetchShiftData = async () => {
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const response = await axios.post('https://srltd.megworld.in/api/client_roaster', { phone_number: phoneNumber }, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log("Client Roster Response :", response);
      
      // const role = response.data.role;
      // setIsClient(role === 'client');

      if (response.data && response.data.data) {
        const newShiftData = JSON.parse(localStorage.getItem('newShiftData'));
        
        if (newShiftData) {
          const updatedShiftData = [newShiftData, ...response.data.data];
          setShiftData(updatedShiftData);
          setFilteredShiftData(updatedShiftData);
          
          setIsClient(response.data.data.some(shift => shift.shift_client_phone === phoneNumber));
        

          localStorage.removeItem('newShiftData');
        } else {
          setShiftData(response.data.data);
          setFilteredShiftData(response.data.data);
        }
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      console.error('Error fetching shift data:', error);
    }
  };

  const handleIconClick = (action, shift) => {
    console.log("Shift data:", shift); // Debugging log
    console.log("if_inv value:", shift.if_inv); // Debugging log
    
   
    if (shift && (shift.if_inv === 1 || shift.if_inv === '1')) {
      console.log("Showing invoice alert"); 
      Swal.fire({
        title: 'This Roster is invoiced',
        text: 'No further actions can be performed on this roster.',
        icon: 'info',
        confirmButtonText: 'OK'
      });
      return;
    }
    
    console.log("Proceeding with action"); // Debugging log
    if (typeof action === 'function') {
      action(shift);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filtered = shiftData.filter(shift => 
      shift &&
      (clientName === "" || (shift.shift_client_name && shift.shift_client_name.toLowerCase().includes(clientName.toLowerCase()))) &&
      (siteName === "" || (shift.shift_site_name && shift.shift_site_name.toLowerCase().includes(siteName.toLowerCase()))) &&
      (date === "" || shift.shift_start_date === date)
    );
    setFilteredShiftData(filtered);
  };
  const handleReset = () => {
    setClientName("");
    setSiteName("");
    setDate("");
    setFilteredShiftData(shiftData);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(shiftData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Roster");
    XLSX.writeFile(workbook, "ClientRoster.xlsx");
  };

  const handleView = async (shift) => {
    await handleIconClick(() => {}, shift);
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const response = await axios.post('https://srltd.megworld.in/api/view_client_roaster_new', 
        { id: shift.id, phone_number: phoneNumber },
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      if (response.data && response.data.success && response.data.data) {
        setSelectedShift(response.data.data);
       setSubStaffData(response.data.data.staff || []);
        setShiftStaffData(response.data.data.shifts || []);
        setSelectedShiftId(shift.id)
        setShowViewModal(true);
        // console.log("Shift ID:",shift.id)
        // setAddStaffData(prevState => ({
        //   ...prevState,
        //   id: selectedShiftId
        // }));
        // fetchShiftModes(shift.id);
      } else {
        throw new Error(response.data?.message || 'Failed to fetch shift details');
      }
    } catch (error) {
      console.error('Error fetching shift details:', error);
      Swal.fire('Error', 'Failed to fetch shift details', 'error');
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_shift",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:", response.data);
        if (response.data.success) {
          Swal.fire('Deleted!', 'The Client has been deleted.', 'success');
          fetchShiftData();
        } else {
          throw new Error(response.data.message || "Failed to delete Client Roster");
        }
      }
    } catch (error) {
      console.error("Delete Client Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Client Roster.'
      });
    }
  };

  const handleEdit = async (shift) => {
    await handleIconClick(() => {}, shift); 
    navigate('/editclientroster', { state: { id: shift } });
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteStaff = async (id) => {
    // if (isDeleting) return; // Prevent multiple delete requests

    setIsDeleting(true);

    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/staff_delete',
        { delete_id: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          },
        }
      );

      if (response.data.success) {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        // onStaffDeleted(staffId); // Callback to update parent component's state
      } else {
        throw new Error(response.data.message || 'Failed to delete staff');
      }
    } catch (error) {
      console.error('Error deleting staff:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the staff member.',
      });

    } finally {
      setShowViewModal(false);
      setIsDeleting(false);
    }
  };

  const [formData, setFormData] = useState({
    id: '',
    shift_title: '',
    shift_client_name: '',
    shift_site_name: '',
    shift_assign_staff: '',
    shift_assign_subemp: '',
    shift_pay_amount: '',
    shift_pay_expenses: '',
    shift_bill_rate: '',
    shift_bill_amount: '',
    shift_bill_expenses: '',
    shift_type: '',
    shift_penalty: '',
    shift_start_date: '',
    shift_end_date: '',
    shift_start_time: '',
    shift_end_time: '',
    shift_break: false,
    shift_check_call: false,
    shift_unpaid: false,
    shift_training: false,
    shift_confirm: false,
    shift_unconfirm: false,
    shift_comments: '',
    shift_instructions: '',
    company_branch: '1',
  });

  const [selectedStaffData,setSelectedStaffData] = useState([]);
 
  const [showStaffModal,setShowStaffModal] = useState(false);

  const handleShowStaffModal = async (shift) => {
    await handleIconClick(() => {}, shift);
    setSelectedShiftId(shift.id);
    setAddStaffData(prevState => ({
      ...prevState,
      id: shift.id
    }));
    setShowStaffModal(true);
    fetchShiftModes(shift.id);
  };


  const handleCloseStaffModal = ()=>{
    setShowStaffModal(false);
  }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async (id) => {
  //   try {
  //     const response = await axios.post(
  //       'https://srltd.megworld.in/api/view_client_roaster_new',
  //     {id:selectedShiftId},
  //               {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
  //         },
  //       }
  //     );
      
  //        console.log("View Staff Response:",response)
  //        if (response.data && response.data.success) {
  //         setSelectedStaffData(response.data.data);
  //         setSubStaffData(response.data.data.staff || []);
  //         setShiftStaffData(response.data.data.shifts || []);
  //         console.log("Shift Table Response:",(response.data.data.shifts))
  //     } else {
  //       console.error('Received data is not an array:', response.data.data);
  //       setSubStaffData([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching staff data:', error);
  //     setSubStaffData([]);
  //   }
  // };

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: type === 'checkbox' ? checked : value
  //   }));
  // };

  // useEffect(() => {
  //   if (selectedShift && selectedShift.id) {
  //     addStaffData(prev => ({
  //       ...prev,
  //       id: selectedShift.id
  //     }));
  //   }
  // }, [selectedShift]);

 



const handleAddStaff = async () => {
  
  try {
    const dataToSend = {
      ...addStaffData,
      shift_assign_staff: addStaffData.shift_assign_staff.map(staff => staff.name).join(', '),
      // shift_assign_staff_id is already a comma-separated string
      shift_assign_subemp: addStaffData.shift_assign_subemp.map(subemp => subemp.name).join(', '),
      // shift_assign_subemp_id is already a comma-separated string
    };

    const response = await axios.post(
      'https://srltd.megworld.in/api/staff_delete',
  dataToSend,
        {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258',
          "Content-Type": "application/json",
        }
      }
    );

    if (response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Staff Added',
        text: 'Staff has been added successfully.',
      });
      // setAddStaffData({
      //   id: selectedShiftId,
      //   shift_assign_staff: '',
      //   shift_assign_subemp: '',
      //   shift_mode: ''
      // });
      // fetchData([selectedShiftId]); 
      handleCloseStaffModal();
    }
  } catch (error) {
    console.error('Error adding staff:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while adding staff.',
    });
  }
};



//   const [staffOptions, setStaffOptions] = useState([]);
//   const [selectedStaff, setSelectedStaff] = useState([]);

//   useEffect(() => {
//     fetchStaffData();
//   }, []);

//   const fetchStaffData = async () => {
//     try {
//       const response = await axios.post(
//         "https://srltd.megworld.in/api/shift_staff",
//         {},
//         {
//           headers: {
//             Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
//           }
//         }
//       );
//       console.log("Staff Data:", response);
//       setStaffOptions(response.data.data);
//       staffOptions.map(staff=>({
//         value: staff.id,
//         label: staff.name,     
//       }))
//     } catch (error) {
//       console.error("Error fetching staff data:", error);
//     }
//   };

//   const handleStaffChange = (selectedOptions) => {
//     setSelectedStaff(selectedOptions);
//     setAddStaffData(prevState => ({
//       ...prevState,
//       shift_assign_staff: selectedOptions.map(option => option.label).join(', ')
//     }));
//   };

//   const [subOptions, setSubOptions] = useState([]);
//   const [selectedSubStaff, setSelectedSubStaff] = useState([]);
  
//   useEffect(() => {
//     fetchSubStaffData();
//   }, []);
  
//   const fetchSubStaffData = async () => {
//     try {
//       const response = await axios.post(
//         "https://srltd.megworld.in/api/sub_employee_show",
//         {},
//         {
//           headers: {
//             Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
//           }
//         }
//       );
//       console.log("Subcontractor Data:", response);
//       const formattedOptions = response.data.data.map(staff => ({
//         value: staff.id,
//         label: staff.subcon_company_name,
//       }));
//       setSubOptions(formattedOptions);
//     } catch (error) {
//       console.error("Error fetching staff data:", error);
//     }
//   };
  
//   const handleSelectChange = (selectedOption, name) => {
//     setFormData(prev => ({
//       ...prev,
//       [name]: selectedOption ? selectedOption.label : '',
//       [`${name}_id`]: selectedOption ? selectedOption.value : ''
//     }));
//   };

//   c

//   const [shiftModes, setShiftModes] = useState([]);
// const [selectedMode,setSelectedMode] = useState([])

// const fetchShiftModes = async (shiftId) => {
//   try {
//     const response = await axios.post(
//       'https://srltd.megworld.in/api/shift_mode_auto',
//       { id: shiftId },
//       {
//         headers: {
//           'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
//         }
//       }
//     );
//     console.log("Shift Mode Auto:", response);
//     const modes = response.data.map(item => ({
//       value: item.id,
//       label: item.shift_mode
//     }));
//     setShiftModes(modes);
//   } catch (error) {
//     console.error('Error fetching shift modes:', error);
//   }
// };

// const handleShiftModeChange = (selectedShiftOptions) => {
//   const options = Array.isArray(selectedShiftOptions) ? selectedShiftOptions : [selectedShiftOptions];
//   setSelectedMode(options);
//   setAddStaffData(prevState => ({
//     ...prevState,
//     shift_mode: options.map(option => option.label).join(', ')
//   }));
// };


//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setAddStaffData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

const renderActionIcons = (shift) => {
  if (role === 'Admin','Office Manager','Accountant') {
    return (
      <>
        <Tooltip title="Edit Client Roster" arrow placement="top">
          <IconButton
            onClick={() => handleEdit(shift)}
            className="me-2"
            size="small"
            sx={{
              color: 'white',
              backgroundColor: 'blue',
              '&:hover': { backgroundColor: 'darkblue' }
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Client Roster" arrow placement="top">
          <IconButton
            onClick={() => handleView(shift)}
            className="me-2"
            size="small"
            sx={{
              color: 'white',
              backgroundColor: 'green',
              '&:hover': { backgroundColor: 'darkgreen' }
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Link to="/timestamp">
          <Tooltip title="View Timestamp" arrow placement="top">
            <IconButton
              className="me-2"
              size="small"
              sx={{
                color: 'white',
                backgroundColor: 'orange',
                '&:hover': { backgroundColor: 'darkorange' }
              }}
            >
              <AccessTimeIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <Tooltip title="Delete Client Roster" arrow placement="top">
          <IconButton
            onClick={() => handleDelete(shift.id)}
            size="small"
            className="me-2"
            sx={{
              color: 'white',
              backgroundColor: 'red',
              '&:hover': { backgroundColor: 'darkred' }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Allocate Staff" arrow placement="top">
          <IconButton
            size="small"
            onClick={() => handleShowStaffModal(shift)}
            className="me-2"
            sx={{
              color: 'white',
              backgroundColor: 'purple',
              '&:hover': { backgroundColor: 'darkpurple' }
            }}
          >
            <PersonIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return (
      <Tooltip title="View Client Roster" arrow placement="top">
        <IconButton
          onClick={() => handleView(shift)}
          className="me-2"
          size="small"
          sx={{
            color: 'white',
            backgroundColor: 'green',
            '&:hover': { backgroundColor: 'darkgreen' }
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    );
  }
};


  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      

          <Box sx={{ width: '100%', mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Paper sx={{ width: '95%', mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={exportToExcel}
      >
        Export to Excel
      </Button>
    </Box>
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {['S.NO','Shift Title', 'Client Name', 'Site Name', 'Staff Name', 'Shift Type', 'Start Date', 'End Date', 'Start Time', 'End Time', 'Break', 'Comments', 'Instructions', 'Actions'].map((header) => (
              <TableCell key={header} sx={{  fontSize: '15px', padding: '4px 8px' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredShiftData
            .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
            .map((shift, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_title}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_client_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_site_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_assign_staff}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_type}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_break ? 'Yes' : 'No'}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_comments}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_instructions}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                      {renderActionIcons(shift)}
                    </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, 500]}
      component="div"
      count={filteredShiftData.length}
      rowsPerPage={entriesPerPage}
      page={currentPage}
      onPageChange={(event, newPage) => setCurrentPage(newPage)}
      onRowsPerPageChange={(event) => {
        setEntriesPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
      }}
    />
  </Paper>
</Box>

<Modal show={showStaffModal} onHide={handleCloseStaffModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        {/* <Form.Control 
            type="hidden" 
            name="id" 
            value={selectedShiftId} 
          /> */}

<Form.Group  controlId="staff">
              <Form.Label style={{float:'left' }}>Shift Mode</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}         
options={shiftModeOptions}
                value={shiftModeOptions.find(option => option.value === addStaffData.shift_mode_id)}
                onChange={(selectedOption) => handleSelectChange(selectedOption, 'shift_mode')}
                isSearchable
                placeholder="Select Shift Mode..."
              />
            </Form.Group>
        <Form.Group  controlId="staff">
              <Form.Label style={{float:'left' }}>Staff</Form.Label>
              <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
              }}         
                options={staffOptions}
                value={addStaffData.shift_assign_staff.map(staff => ({ value: staff.id, label: staff.name }))}
                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'shift_assign_staff')}
                isMulti
                isSearchable
              placeholder="Select Staff Name..."
            />
            </Form.Group>
        <Form.Group controlId="SubContractor">
              <Form.Label style={{ float:'left'}}>SubContractor</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}      

options={subContractorOptions}
value={subContractorOptions.filter(option => 
  Array.isArray(addStaffData.shift_assign_subemp)
    ? addStaffData.shift_assign_subemp.some(subemp => subemp.name === option.label)
    : addStaffData.shift_assign_subemp.split(', ').includes(option.label)
)}
onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'shift_assign_subemp')}
isMulti
                placeholder="Select Staff Name..."
              />
            </Form.Group>

      
         
          
       
     
       
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="me-2"  variant="contained"  onClick={handleAddStaff}
  color="primary">
          Add 
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCloseStaffModal}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>

          <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>View Shift Details</Modal.Title>
    {/* <IconButton aria-label="add"  sx={{ marginRight: 1 }}>
        <AddIcon />
      </IconButton> */}
  </Modal.Header>
  <Modal.Body>
    {selectedShift && selectedShift.main && selectedShift.main[0] && (
      <>
        <div className="row">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Shift Title</th>
                  <td>{selectedShift.main[0].shift_title}</td>
                </tr>
              <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Staff Name</th>
                  <td>{selectedShift.main[0].shift_assign_staff}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Client Name</th>
                  <td>{selectedShift.main[0].shift_client_name}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Site Name</th>
                  <td>{selectedShift.main[0].shift_site_name}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Shift Type</th>
                  <td>{selectedShift.main[0].shift_type}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              {/* <tr>
                  <th>Pay Rate</th>
                  <td>{selectedShift.main[0].shift_pay_rate}</td>
                </tr> */}
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Start Date</th>
                  <td>{selectedShift.main[0].shift_start_date}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>End Date</th>
                  <td>{selectedShift.main[0].shift_end_date}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Comments</th>
                  <td>{selectedShift.main[0].shift_comments}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Instructions</th>
                  <td>{selectedShift.main[0].shift_instructions}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Bill Rate</th>
                  <td>£{selectedShift.main[0].shift_bill_rate}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Pay Amount</th>
                  <td>£{selectedShift.main[0].shift_pay_amount}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Pay Expenses</th>
                  <td>£{selectedShift.main[0].shift_pay_expenses}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Break</th>
                  <td>{selectedShift.main[0].shift_break}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Start Time</th>
                  <td>{selectedShift.main[0].shift_start_time}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>End Time</th>
                  <td>{selectedShift.main[0].shift_end_time}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Bill Amount</th>
                  <td>£{selectedShift.main[0].shift_bill_amount}</td>
                </tr>
                <tr>
                  <th style={{fontWeight:'bold',fontSize:'16px'}}>Bill Expenses</th>
                  <td>£{selectedShift.main[0].shift_bill_expenses}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="position-relative">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4 className="m-0">Shift Details</h4>
       
      </div>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Shift Mode</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Total Time</TableCell>
            <TableCell>Shift Break</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shiftStaffData.map((shift, index) => (
            <TableRow key={index}>
              <TableCell>{shift.shift_mode || 'Unnamed Staff'}</TableCell>
              <TableCell>{shift.shift_start_date || 'Unnamed Staff'}</TableCell>
              <TableCell>{shift.shift_end_date || 'Unnamed Shift Mode'}</TableCell>
              <TableCell>{shift.shift_start_time}</TableCell>
              <TableCell>{shift.shift_end_time}</TableCell>
              <TableCell>{shift.total_time}</TableCell>
              <TableCell>{shift.shift_break}</TableCell>

              {/* <TableCell>
                <IconButton
                  size="small"
                  className="me-2"
                  sx={{
                    color: 'white',
                    backgroundColor: 'red',
                    '&:hover': { backgroundColor: 'darkred' }
                  }}
                  onClick={() => handleDeleteStaff(staffMember.id)}
                  disabled={isDeleting}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  
    </div>


        <div className="position-relative">
      <div className="d-flex justify-content-between align-items-center mb-2 mt-10">
        <h4 className="m-0">Staff Details</h4>
       
      </div>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Staff Name</TableCell>
            <TableCell>SubContractor Name</TableCell>
            <TableCell>Shift Mode</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subStaffData.map((staffMember, index) => (
            <TableRow key={index}>
              <TableCell>{staffMember.shift_assign_staff || 'Unnamed Staff'}</TableCell>
              <TableCell>{staffMember.shift_assign_staff || 'Unnamed Staff'}</TableCell>
              <TableCell>{staffMember.shift_mode || 'Unnamed Shift Mode'}</TableCell>
              <TableCell>{staffMember.status}</TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  className="me-2"
                  sx={{
                    color: 'white',
                    backgroundColor: 'red',
                    '&:hover': { backgroundColor: 'darkred' }
                  }}
                  onClick={() => handleDeleteStaff(staffMember.id)}
                  disabled={isDeleting}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  
    </div>
      </>
    )}
  </Modal.Body>
</Modal>
        {/* </Col>
      </Row>
      </Container> */}
    </>
  );
};

export default ClientRoster;