import React, { useState, useEffect } from "react";
import "./Clients.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import headerBackground from "../patterns/header-bg.png";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const ViewClient = () => {
  const [clients, setClients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
const [totalClients,setTotalClients] = useState(clients)
  const [searchParams, setSearchParams] = useState({
    client_name: "",
    client_address: "",
    client_contact_number: "",
    client_contact_fax: "",
    client_contact_email:""
  });


  useEffect(() => {
      fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show", { phone_number: localStorage.getItem("phoneNumber") },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Fetched clients:", response.data.data);
      setClients(response.data.data);
      setTotalClients(response.data.data) 
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  
  useEffect(() => {
    const filtered = clients.filter((client) =>
      Object.values(client).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setTotalClients(filtered);
    setCurrentPage(0);
  }, [searchTerm, clients]);

//   const indexOfLastEntry = currentPage * entriesPerPage;
// const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
// const currentEntries = clients
//   .filter((client) =>
//     Object.values(client).some((value) =>
//       value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
//     )
//   )
//   .slice(indexOfFirstEntry, indexOfLastEntry);

  


//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const handleEntriesPerPageChange = (e) => {
//     setEntriesPerPage(Number(e.target.value));
//     setCurrentPage(1);
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1);
//   };


  
  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      fetchClients(); // Reset to fetch all clients
    } else {
      // Filter clients based on searchTerm
      const filteredClients = clients.filter((client) =>
        client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setClients(filteredClients);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/client_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:",response.data)
        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Client has been deleted.',
            'success'
          );
          fetchClients();
        } else {
          throw new Error(response.data.message || "Failed to delete Client");
        }
      }
    } catch (error) {
      console.error("Delete Client Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Client.'
      });
    }
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Clients', bold: false },
      { name: 'View Clients', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(clients);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
    XLSX.writeFile(workbook, "Clients_data.xlsx");
  }

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>            
       
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>SEARCH CLIENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select>
                <option>All Clients</option>
                {/* You can populate options dynamically based on fetched data */}
                {clients.map((client) => (
                  <option key={client.client_name}>{client.client_name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            CLOSE
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            SEARCH
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select className="form-select d-inline-block w-auto mx-2" value={entriesPerPage}   onChange={(e) => {
                    handleEntriesPerPageChange(e);
                  }}>
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
              <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>                  
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  // style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Client Name</th>
                    <th>Address</th>
                    <th>Contact</th>
                    <th>FAX</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.length > 0 ? (
                    currentEntries.map((client, index) => (
                      <tr key={index} style={{ fontSize: "15px" }}>
                        <td>{index + 1}</td>
                        <td>{client.client_name}</td>
                        <td>{client.client_address}</td>
                        <td>{client.client_contact_number}</td>
                        <td>{client.client_contact_fax}</td>
                        <td>{client.client_contact_email}</td>
                        <td>
                          <Link to="/viewdetails">
                            <button className=" btn-sm btn-success me-1">
                              <i
                                className="fas fa-file"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                            </Link>
                          <button className=" btn-sm btn-danger me-1"
                                                      onClick={() => handleDelete(client.id)}

                          >
                            <i
                              className="fas fa-trash"
                              style={{ color: "white" }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No clients found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
  <p>
    Showing {Math.min(indexOfFirstEntry + 1, clients.length)} to{" "}
    {Math.min(indexOfLastEntry, clients.length)} of {clients.length} entries
  </p>
  <nav>
    <ul className="pagination mb-0">
      {Array.from({ length: Math.ceil(clients.length / entriesPerPage) }).map(
        (_, index) => (
          <li
            key={index}
            className={`page-item ${
              currentPage === index + 1 ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        )
      )}
    </ul>
  </nav>
</div>

          </div>
        </div>
      </div> */}
      
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Address</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Fax</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {totalClients
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((client, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{client.client_name}</TableCell>
                    <TableCell >{client.client_address}</TableCell>
                    <TableCell >{client.client_contact_number}</TableCell>
                    <TableCell >{client.client_contact_fax}</TableCell>
                    <TableCell >{client.client_contact_email}</TableCell>

                    <TableCell >
                    <Tooltip 
    title="View Client" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    component={Link} 
    to="/viewdetails" 
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="Delete Client" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    onClick={() => handleDelete(client.id)}    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={totalClients.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ViewClient;
