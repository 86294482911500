import React,{useState,useEffect} from "react";
import Dashboard from "../../Dashboard/Dashboard";
import "./Add.css";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import {
  // Button,
  Form,
  FormControl,
  InputGroup,
  // Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const Guard = () => {

  const [guard,setGuard] = useState("");
  const [tableData, setTableData] = useState([]);
  const [guardList, setGuardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchGuard();
  }, []);

  const fetchGuard = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/guard_group_show", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        setError("Failed to fetch Guard Group");
      }
    } catch (err) {
      setError("Error fetching  Guard Group");
    } finally {
      setLoading(false);
    }
  };

  const handleAddGuard = async () => {
    if (guard.trim() !== "") {
      setLoading(true);

      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/guard_group_store",
         {
          guard_group: guard,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
console.log(response.data.data)
        if (response.data && response.data.success) {
          setGuardList([...guardList, response.data.data]);
          fetchGuard();
          setGuard("");
          Swal.fire({
            icon: 'success',
            title: ' Guard Group Added',
            text: 'New Guard Group has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add  Guard Group");
        }
      } catch (err) {
        setError("Error adding  Guard Groupe");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/guard_group_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Guard has been deleted.',
            'success'
          );
          fetchGuard();
        } else {
          throw new Error(response.data.message || "Failed to delete Guard");
        }
      }
    } catch (error) {
      console.error("Delete Guard Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Guard.'
      });
    }
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: editingItem.id,
        guard_group: editingItem.guard_group,
        company_branch: editingItem.company_branch,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        "https://srltd.megworld.in/api/guard_group_store",
        payload,
        config
      );

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Guard Updated',
          text: 'Guard has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        setShowModal(false);
        fetchGuard();
      } else {
        throw new Error(response.data.message || "Failed to update Guard");
      }
    } catch (error) {
      console.error("Update SIA Type Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while updating Guard.'
      });
    } finally {
      setIsLoading(false);
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'Guard Group', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <div className="">
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>          </div>
        
      </div>

      <div className=" mt-8">
        <div className="row">
          <div className="col-md-4 mt-1" id="container1">
            <div className="mb-3">
              <label
                htmlFor="guardGroupName"
                className="form-label d-block mb-1"
                style={{ fontSize: "20px", float: "left", marginLeft: 10 }}
              >
                Guard Group Name
              </label>
              <input
                type="text"
                className="form-control w-100 mb-3"
                id="guardGroupName"
                value={guard}
                onChange={(e) => setGuard(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <button
                className=" w-100 "
                id="save-btn"
                style={{ marginLeft: 10 }}
                onClick={handleAddGuard}
              >
                SAVE
              </button>
            </div>
          </div>
          {/* <div className="col-7 mt-10" id="tablebox">
            <table className="table">
              <thead style={{ fontSize: "15px" }}>
                <tr>
                  <th>Group ID</th>
                  <th>Group Name</th>
                  <th>Branch</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{data.guard_group}</td>
                <td>{data.branch}</td>
                <td>
                <button
                              className="btn btn-sm btn-primary me-1"
                              onClick={() => handleEdit(data)}

                             >
                              <i
                                className="fas fa-edit"
                                style={{ color: "white" }}></i>
                            </button>
                            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(data.id)}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}></i>
                            </button>
                </td>
              </tr>
            ))}
          </tbody>
            </table>
          </div> */}
 <div className="col-7 mt-10" id="tablebox">
          <TableContainer component={Paper} sx={{ maxWidth: '100%', mt: 2 }}>
      <Table aria-label="guard groups table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}>Group ID</TableCell>
            <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}>Group Name</TableCell>
            <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}>Branch</TableCell>
            <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((data, index) => (
            <TableRow key={index} hover style={{fontSize:'13px'}}>
              <TableCell sx={{ padding: '8px' }}>{index + 1}</TableCell>
              <TableCell sx={{ padding: '8px' }}>{data.guard_group}</TableCell>
              <TableCell sx={{ padding: '8px' }}>{data.branch}</TableCell>
              <TableCell sx={{ padding: '8px' }}>
              <Tooltip 
    title="Edit Guard" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
                <IconButton
                  onClick={() => handleEdit(data)}
                  size="small"
                  sx={{
                    color: 'white',
                    backgroundColor: 'primary.main',
                    marginRight: 1,
                    '&:hover': { backgroundColor: 'primary.dark' }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                </Tooltip>
                <Tooltip 
    title="Delete Guard" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
                <IconButton
                  onClick={() => handleDelete(data.id)}
                  size="small"
                  sx={{
                    color: 'white',
                    backgroundColor: 'error.main',
                    '&:hover': { backgroundColor: 'error.dark' }
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                </Tooltip>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Guard Gruop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Guard Group Name</Form.Label>
              <Form.Control
                type="text"
                value={editingItem?.guard_group || ''}
                onChange={(e) => setEditingItem({...editingItem, guard_group: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Branch</Form.Label>
              <Form.Control
                type="text"
                value={editingItem?.company_branch || ''}
                onChange={(e) => setEditingItem({...editingItem, company_branch: e.target.value})}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
      </div>
    </>
  );
};

export default Guard;
