import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation ,useNavigate} from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import "./Invoice.css";
import Dashboard from "../../Dashboard/Dashboard";
import axios from "axios";
import Swal from 'sweetalert2';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import NewSGSLogo from "../Invoice/patterns/logos/NewSGSLogo.jpeg"
import * as XLSX from 'xlsx';
import Select from 'react-select';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Visibility, Edit, Delete, Email, PictureAsPdf } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const ManageInvoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoice,setFilteredInvoice] = useState([]);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);
  const [showSearchInvoice, setShowSearchInvoice] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [newInvoice, setNewInvoice] = useState({
    invoice_client_name: "",
    invoice_site_group: "",
    invoice_start_date: "",
    invoice_end_date: "",
    invoice_no: "",
    company_branch: 1,
  });
  const [searchParams, setSearchParams] = useState({
    invoice_client_name: "",
    invoice_site_group: "",
    invoice_start_date: "",
    invoice_end_date: "",
    invoice_no: "",
    company_branch: 1,
  })

  const [editingInvoice, setEditingInvoice] = useState(null);
const [showEditModal, setShowEditModal] = useState(false);
const [pdfData, setPdfData] = useState(null);

const handleShowEditModal = (invoice) => {
  setEditingInvoice({...invoice});
  setShowEditModal(true);
};

const handleCloseEditModal = () => {
  setEditingInvoice(null);  
  setShowEditModal(false);
};

const handleUpdateInvoice = async () => {
  try {
    setIsLoading(true);
    const response = await axios.post(
      "https://srltd.megworld.in/api/add_invoice",
      
      editingInvoice,      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data && response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Invoice Updated',
        text: 'Invoice has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      setInvoices(invoices.map((invoice) =>
        invoice.id === editingInvoice.id ? editingInvoice : invoice
      ));
      handleCloseEditModal();
    } else {
      throw new Error(response.data.message || "Failed to update invoice");
    }
  } catch (error) {
    console.error("Error updating invoice:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message || 'An error occurred while updating the invoice.'
    });
  } finally {
    setIsLoading(false);
  }
};

const handleDeleteInvoice = async (id) => {
  try {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_invoice",
        { delete_id: id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data && response.data.success) {
        Swal.fire(
          'Deleted!',
          'The invoice has been deleted.',
          'success'
        );
        setInvoices(invoices.filter((invoice) => invoice.id !== id));
      } else {
        throw new Error(response.data.message || "Failed to delete invoice");
      }
    }
  } catch (error) {
    console.error("Error deleting invoice:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message || 'An error occurred while deleting the invoice.'
    });
  } finally {
    setIsLoading(false);
  }
};

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525";

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_invoice",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.success) {
        setInvoices(response.data.data);
        setFilteredInvoice(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      setError("Error fetching invoice: " + error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const filtered = invoices.filter((invoice) =>
      Object.values(invoice).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredInvoice(filtered);
    setCurrentPage(0);
  }, [searchTerm, invoices]);
  
  const handleAddInvoice = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_invoice",
        newInvoice,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      if(response.data && response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Invoice Details Added',
          text: 'New Invoice has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        setNewInvoice(response.data.data)
        fetchInvoices();
        handleCloseAddEventDetail();
      }
    } catch (error) {
      console.error("Error adding invoice:", error);
      alert("An error occurred while adding invoice. Please try again.")
    }
  };

 
  
  const filteredInvoices = invoices.filter((invoice) => {
    if (!invoice)return false; 
      return Object.keys(invoice).some((key) =>{
        const value = invoice[key];
        return value &&  value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      });
  });


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      fetchInvoices(); // Reset to original list if search term is empty
    } else {
      const filtered = invoices.filter((invoice) =>
        Object.values(invoice).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setInvoices(filtered);
    }
    setCurrentPage(1); // Reset to first page after search
  };
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstEntry, indexOfLastEntry);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const handleShowEmail = () => setShowEmail(true);
  const handleCloseEmail = () => setShowEmail(false);

  const handleShowSearchInvoice = () => setShowSearchInvoice(true);
  const handleCloseSearchInvoice = () => setShowSearchInvoice(false);

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewInvoice({ ...newInvoice, [name]: value });
  };
  
  const generatePDF = (invoice) => {
    if (!invoice) {
      console.error('Invalid invoice data');
      return null;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
  
    // Add Logo
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471',margin,45)

    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('INVOICE', pageWidth - margin, 25, { align: 'right' });
  
    // Add invoice details
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Invoice#: ${invoice.invoice_no || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Invoice Date: ${new Date().toLocaleDateString()}`, pageWidth - margin, 45, { align: 'right' });
    doc.text(`Terms: Due on Receipt`, pageWidth - margin, 50, { align: 'right' });
    doc.text(`Due Date: ${invoice.invoice_end_date || 'N/A'}`, pageWidth - margin, 55, { align: 'right' });
  
    // Add Bill To and Ship To
    doc.setFillColor(230, 230, 250);
    doc.rect(margin, 85, 80, 25, 'F');
    doc.rect(pageWidth / 2, 85, 80, 25, 'F');
    doc.setFontSize(11);
    doc.text('Bill To', margin + 2, 95,);
    doc.text('Ship To', pageWidth / 2 + 2, 95);
    doc.setFontSize(15);
    doc.text(`${invoice.invoice_client_name || 'N/A'}`, margin + 2, 100);
    doc.text(`${invoice.invoice_client_name || 'N/A'}`, pageWidth / 2 + 2, 100);
  
    // Add main table
    doc.autoTable({
      startY: 120,
      head: [['Site Group','Start Date','End Date ']],
      body: [
        [` ${invoice.invoice_site_group || 'N/A'}`,`${invoice.invoice_start_date || 'N/A'}`,`${invoice.invoice_end_date || 'N/A'}`

        ],
        
      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
  
    // Add Payment Details
    const finalY = doc.lastAutoTable.finalY || 150;
    doc.setFontSize(14);
    doc.text('Payment Details:', margin, finalY + 20);
  
    doc.autoTable({
      startY: finalY + 30,
      head: [['Description', 'Amount']],
      body: [
        ['Net Amount', `£${invoice.net_amount || '0.00'}`],
        ['VAT @ 20%', `£${invoice.vat_amount || '0.00'}`],
        ['Billable Expenses', `£${invoice.billable_expenses || '0.00'}`],
        ['Gross Amount', `£${invoice.gross_amount || '0.00'}`]
      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
  
    return doc.output('blob');
  };
  
  const previewPDF = (invoice) => {
    const pdfBlob = generatePDF(invoice);
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  };
  
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Invoice', bold: false },
      { name: 'Manage Invoice', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(invoices);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(workbook, "ManageInvoice_data.xlsx");
  }

     
  const [siteGroups, setSiteGroups] = useState([]);

  useEffect(() => {
    fetchShiftTitles();
  }, []);

  const fetchShiftTitles = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_roaster",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Shift Title Response:", response.data);

      if (response.data && Array.isArray(response.data.data)) {
        const shiftTitleOptions = response.data.data.map(item => ({
          value: item.shift_title,
          label: item.shift_title
        }));
        setSiteGroups(shiftTitleOptions);
      } else {
        console.error('Unexpected API response format');
      }
    } catch (error) {
      console.error('Error fetching Shift Titles:', error);
    }
  };

  const handleShiftTitleChange = (selectedOption, { name }) => {
    setNewInvoice(prevInvoice => ({
      ...prevInvoice,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };

 

  const handleSiaTypeChange = (selectedOption, { name }) => {
    setNewInvoice(prevEvent => ({
      ...prevEvent,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };

  const [clientName,setClientName] = useState([])

  const handleClientChange = (selectedOption, { name }) => {
    setNewInvoice((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.client_name,
          label: type.client_name,
        }
      ));
 
    setClientName(options);
        console.log("Client Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };
  const navigate = useNavigate();


  const handleView = (invoice)=>{
    navigate('/viewinvoice', { state: { id: invoice } })
  }


  const handleShiftView = (invoice)=>{
    navigate('/viewshiftinvoice', { state: { id: invoice } })
  }

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2 d-flex justify-content-between align-items-center"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>      
         
          <div  className="d-flex" style={{marginRight:'90px'}}>
         
            <Button
  className="me-2"  variant="contained"
  color="primary"
  onClick={handleShowAddEventDetail}
  sx={{ padding: '8px', marginLeft: '100px' }}
>
Create Invoice</Button>
          </div>
      
          <Modal show={showEditModal} onHide={handleCloseEditModal}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Invoice</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleUpdateInvoice}>
      <Form.Group className="mb-3">
        <Form.Label style={{float:'left'}}>Client Name</Form.Label>
        <Form.Control
          name="invoice_client_name"
          value={editingInvoice?.invoice_client_name || ''}
          onChange={(e) => setEditingInvoice({...editingInvoice, invoice_client_name: e.target.value})}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{float:'left'}}>Site Group</Form.Label>
        <Form.Control
          name="invoice_site_group"
          value={editingInvoice?.invoice_site_group || ''}
          onChange={(e) => setEditingInvoice({...editingInvoice, invoice_site_group: e.target.value})}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{float:'left'}}>Start Date</Form.Label>
        <Form.Control
          type="date"
          name="invoice_start_date"
          value={editingInvoice?.invoice_start_date || ''}
          onChange={(e) => setEditingInvoice({...editingInvoice, invoice_start_date: e.target.value})}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{float:'left'}}>End Date</Form.Label>
        <Form.Control
          type="date"
          name="invoice_end_date"
          value={editingInvoice?.invoice_end_date || ''}
          onChange={(e) => setEditingInvoice({...editingInvoice, invoice_end_date: e.target.value})}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{float:'left'}}>Invoice Number</Form.Label>
        <Form.Control
          type="number"
          name="invoice_no"
          value={editingInvoice?.invoice_no || ''}
          onChange={(e) => setEditingInvoice({...editingInvoice, invoice_no: e.target.value})}
          required
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button className="me-2"  variant="contained"
  color="primary" onClick={handleUpdateInvoice} disabled={isLoading}>
      {isLoading ? 'Updating...' : 'Update'}
    </Button>
    <Button variant="contained"
  color="secondary" onClick={handleCloseEditModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

          <Modal show={showSearchInvoice} onHide={handleCloseSearchInvoice}>
            <Modal.Header closeButton>
              <Modal.Title>Search Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
                  <Form.Select style={{ width: "100%" }}>
                    <option>All Client...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
                  <Form.Select style={{ width: "100%" }}>
                    <option>All Site Group...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
                  <Form.Control
                    type="Date"
                    style={{ width: "100%" }}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>End Date</Form.Label>
                  <Form.Control type="date" style={{ width: "100%" }} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="me-2"  variant="contained"
  color="primary">SEARCH</Button>
              <Button variant="contained"
  color="secondary" onClick={handleCloseSearchInvoice}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAddEventDetail} onHide={handleCloseAddEventDetail}>
            <Modal.Header closeButton>
              <Modal.Title>Create New Invoice </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Client Name</Form.Label>
                  <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                name="invoice_client_name"
                value={clientName.find(option => option.value === newInvoice?.invoice_client_name)}
                onChange={handleClientChange}
                options={clientName}
                isSearchable
                />
                </Form.Group>
                <Form.Group className="mb-3">
      <Form.Label style={{ float: "left" }}>
      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Shift Title</Form.Label>
      <Select
          styles={{
            control: (provided) => ({
              ...provided,
              width: '100%',
            }),
          }}

        name="invoice_site_group"
        value={siteGroups.find(option => option.value === newInvoice?.invoice_site_group)}
        onChange={handleShiftTitleChange}
        options={siteGroups}        isSearchable
        placeholder="Select Shift Title..."
    
      />
    </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
                  <Form.Control
                    type="Date"
                    name="invoice_start_date"
                    // value={newInvoice.invoice_start_date}
                    onChange={handleInputChange}
                    required
                    style={{ width: "100%" }}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    style={{ width: "100%" }}
                    name="invoice_end_date"
                    // value={newInvoice.invoice_end_date}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginRight: 70 }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                    Invoice Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Invoice Number"
                    style={{ width: "100%" }}
                    name="invoice_no"
                    // value={newInvoice.invoice_no}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="me-2"  variant="contained"
  color="primary"   onClick={handleAddInvoice}>CREATE</Button>
              <Button variant="contained"
  color="secondary" onClick={handleCloseAddEventDetail}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        
     
              <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Invoice Number </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Shift Title</TableCell>
                  {/* <TableCell sx={{ fontSize: '15px'}}>Invoice Date</TableCell> */}
                  <TableCell sx={{ fontSize: '15px'}}>Start Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>End Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredInvoice
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((invoice, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell >{invoice.invoice_client_name}</TableCell>
                    <TableCell >{invoice.invoice_no}</TableCell>
                    <TableCell >{invoice.invoice_site_group}</TableCell>
                    {/* <TableCell >{invoice.invoice_date}</TableCell> */}
                    <TableCell >{invoice.invoice_start_date}</TableCell>
                    <TableCell >{invoice.invoice_end_date}</TableCell>
                    <TableCell >
  {/* <Link to="/viewinvoice"> */}
                     <Tooltip 
    title="Staff Wise Invoice" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      color="success"
      size="small"
      sx={{ marginRight: 2,  color: 'white', 
        backgroundColor: 'green',  '&:hover': { backgroundColor: 'darkgreen' }  }}
onClick={()=>handleView(invoice)}
    >
      <Visibility sx={{ fontSize: 16 }} />
    </IconButton>
    </Tooltip>
    <Tooltip 
    title="Shift Wise Invoice" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      color="success"
      size="small"
      sx={{ marginRight: 2,  color: 'white', 
        backgroundColor: 'Magenta',  '&:hover': { backgroundColor: 'Magenta' }  }}
onClick={()=>handleShiftView(invoice)}
    >
      <Visibility sx={{ fontSize: 16 }} />
    </IconButton>
    </Tooltip>
  {/* </Link> */}
  <Tooltip 
    title="Edit Invoice" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{ marginRight: 2,  color: 'white', 
      backgroundColor: 'blue',  '&:hover': { backgroundColor: 'darkblue' }  }}
  onClick={() => handleShowEditModal(invoice)}
  >
    <Edit sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>
  <Tooltip 
    title="Delete Invoice" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="error"
    size="small"
    sx={{ marginRight: 2,  color: 'white', 
      backgroundColor: 'red',  '&:hover': { backgroundColor: 'darkred' }  }}
  onClick={() => handleDeleteInvoice(invoice.id)}
  >
    <Delete sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="Email" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{ marginRight: 2,marginTop:'1',  color: 'white', 
      backgroundColor: 'orange',  '&:hover': { backgroundColor: 'darkorange' }  }}
  onClick={handleShowEmail}
  >
    <Email sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>
  <Tooltip 
    title="View PDF" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{color: 'white', 
      backgroundColor: 'darkviolet',  '&:hover': { backgroundColor: 'darkviolet' }  }}

    onClick={() => previewPDF(invoice)}
  >
    <PictureAsPdf sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredInvoice.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>

              <Modal show={showEmail} onHide={handleCloseEmail}>
                <Modal.Header closeButton>
                  <Modal.Title>Send Invoice Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h3>
                    Email Recipient :
                    <span style={{ color: "red" }}>sewrfd@gmail.com</span>
                  </h3>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="me-2"  variant="contained"
  color="primary">SEND</Button>
                  <Button variant="contained"
  color="secondary" onClick={handleCloseEmail}>
                    CLOSE
                  </Button>
                </Modal.Footer>
              </Modal>



    </>
  );
};

export default ManageInvoice;
