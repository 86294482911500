import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./ViewEmployee.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation,useNavigate } from "react-router-dom";
import {
  faPhone,
  faCalendar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import defaultPreviewLogo from "../Employee/patterns/previewimg.jpg";
import Dashboard from "../../Dashboard/Dashboard";
import Basic from "./Basic";
import Addresses from "./Address";
import Training from "./Training";
import Employment from "./Employment";
import PersonalRef from "./PersonalRef";
import Documents from "./Documents";
import SIAQues from "./SIAQues";
import InterviewCheck from "./InterviewCheck";
import "./Add.css";
import PreviousHistory from "./PreviousHistory";
import PreviousEmployment from "./PreviousEmployment";
import PreviousEducation from "./PreviousEducation";
import SelfEmployment from "./SelfEmployment";
import ShowPreviousEmployment from "./ShowPreviousEmployment";
import ShowPreviousEducation from "./ShowPreviousEducation";
import ShowSelfEmployment from "./ShowSelfEmployment";


const ViewEmployeeInfo = () => {
  const [activeSection, setActiveSection] = useState("Basic");
  const [activePreviousHistoryTab, setActivePreviousHistoryTab] = useState("Previous Employment");
  const tabs = [
    "Basic",
    "Addresses",
    "Training",
    "Employment",
    "Previous History",
    "Personal References",
    "Documents",
    "SIA Questionare",
    "Interview Checklist",
  ];

  const previousHistoryTabs = [
    "Previous Employment",
    "Previous Education",
    "Self Employment",
  ];

  const renderActiveSection = () => {
    switch (activeSection) {
      case "Basic":
        return <Basic />;
      case "Addresses":
        return <Addresses />;
      case "Training":
        return <Training />;
      case "Employment":
        return <Employment />;
        case "Previous History":
        return renderPreviousHistorySection();
      case "Personal References":
        return <PersonalRef />;
      case "Documents":
        return <Documents />;
      case "SIA Questionare":
        return <SIAQues />;
      case "Interview Checklist":
        return <InterviewCheck />;
      default:
        return null;
    }
  };


  const renderPreviousHistorySection = () => {
    return (
      <div>
        <div className="sub-tabs">
          {previousHistoryTabs.map((tab) => (
            <button
              key={tab}
              className={`sub-tab ${activePreviousHistoryTab === tab ? "active" : ""}`}
              onClick={() => setActivePreviousHistoryTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {renderActivePreviousHistoryTab()}
      </div>
    );
  };

  const renderActivePreviousHistoryTab = () => {
    switch (activePreviousHistoryTab) {
      case "Previous Employment":
        return <ShowPreviousEmployment/>;
      case "Previous Education":
        return <ShowPreviousEducation/>;
      case "Self Employment":
        return <ShowSelfEmployment/>;
      default:
        return null;
    }
  };
  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    const breadcrumbPaths = [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'View', path:'/view', bold: false },
      { name: 'Employee Information', path: location.pathname, bold: true }
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <div className="">
        <h3 className="text-white" style={{ fontSize: '15px' }}>
      {navigationPath.map((part, index) => (
        <React.Fragment key={index}>
          <Link
            to={part.path}
            onClick={(e) => handleClick(part.path, e)}
            style={{
              fontWeight: part.bold ? 'bold' : 'normal',
              color: 'white',
              fontSize: part.bold ? '18px' : '15px',
              textDecoration: 'none'
            }}
          >
            {part.name}
          </Link>
          {index < navigationPath.length - 1 && " / "}
        </React.Fragment>
      ))}
    </h3>      </div>
       
      </div>

      <div className="employee-info-container">
        <div className="main-content">
          {/* <div className="profile-section">
            <div className="profile-image-placeholder">
              <img
                roundedCircle
                style={{
                  width: "70px",
                  height: "70px",
                  objectFit: "cover",
                  marginTop: "10px",
                }}
                src={defaultPreviewLogo}
                alt=""
              />
            </div>
            <h2>Iione M</h2>
            <div className="profile-info">
              <p>
                <FontAwesomeIcon icon={faPhone} /> 56576757657
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
              </p>
              <p>
                <FontAwesomeIcon icon={faCalendar} /> 16 Jan 2024
              </p>
            </div>
            <h3>Joining</h3>
          </div> */}
          <div className="container info-section">
            <div className="tabs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab ${activeSection === tab ? "active" : ""}`}
                  onClick={() => setActiveSection(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            {renderActiveSection()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmployeeInfo;
