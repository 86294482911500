import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Add.css";
import Dashboard from "../../Dashboard/Dashboard";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { Form, Button, Modal, Row, Col, Alert,Table } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';

const Add = () => {
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    employee_staff_pin: "",
    employee_forename: "",
    // employee_middname: "",
    employee_surname: "",
    employee_gender: "",
    employee_dob: "",
    employee_contactno: "",
    employee_type: "",
    employee_sia_no: "",
    employee_sia_type: "",
    employee_expdate: "",
    employee_jobname:"",
    employee_department:""

  });

  const populateFormData = (data) => {
    setFormData(prevData => {
      const newData = { ...prevData };
  
      Object.entries(data).forEach(([key, value]) => {
        const formattedValue = Object.values(value)[0] || "";
        switch (key) {
          case 'row_1':
            newData.employee_forename = formattedValue;
            break;
          case 'row_2':
            newData.employee_surname = formattedValue;
            break;
          case 'row_3':
            newData.employee_sia_no = formattedValue;
            break;
          case 'row_5':
            newData.employee_sia_type = formattedValue;
            break;
          case 'row_6':
            newData.employee_expdate = formatDate(formattedValue);
            break;
        }
      });
  
      return newData;
    });
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const verifySIA = async () => {
    setIsLoading(true);
    setApiError(null);
  
    const siaNumber = formData.employee_sia_no;
  
    if (!/^\d{16}$/.test(siaNumber)) {
      setApiError("Invalid SIA number. Please enter a valid 16-digit number.");
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/check_sia_type",
        { LicenseNo: siaNumber },
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('SIA Verification Response:', response.data);
  
      if (!response.data || response.data.length === 0 || Object.keys(response.data[0]).length === 0) {
        setApiResponse({ message: "SIA Number Not Found" });
      } else {
        setApiResponse(response.data);
        populateFormData(response.data[0]);
      }
      setShowModal(true);
    } catch (error) {
      console.error("Error verifying SIA:", error);
      setApiError(error.message || "An error occurred while verifying SIA");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const validateForm = () => {
    let newErrors = {};

    if (!formData.employee_staff_pin) newErrors.employee_staff_pin = "Staff PIN is required";
    if (!formData.employee_forename) newErrors.employee_forename = "Forename is required";
    // if (!formData.employee_middname) newErrors.employee_middname = "Middname is required";
    if (!formData.employee_surname) newErrors.employee_surname = "Surname is required";
    if (!formData.employee_gender) newErrors.employee_gender = "Gender is required";
    if (!formData.employee_dob) newErrors.employee_dob = "Date of Birth is required";
    if (!formData.employee_contactno) newErrors.employee_contactno = "Contact Number is required";
    if (!formData.employee_type) newErrors.employee_type = "Employee Type is required";

    if (formData.employee_type === "operational" && !formData.sia_not_required) {
      if (!formData.employee_sia_no) newErrors.employee_sia_no = "SIA Number is required";
      if (!formData.employee_sia_type) newErrors.employee_sia_type = "SIA Type is required";
      if (!formData.employee_expdate) newErrors.employee_expdate = "SIA Expiry Date is required";
    }

    if (formData.employee_type === "nonOperational" && !formData.sia_not_required) {
      if (!formData.employee_jobname) newErrors.employee_jobname = "Job Title is required";
      if (!formData.employee_department) newErrors.employee_department = "Department is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const _token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/employee_store",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${_token}`,
            },
          }
        );
        console.log("Full response:", response);
        if (response.data && response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Employee Updated',
            text: 'Employee has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          navigate("/view", { state: { newEmployee: response.data.success } });
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Full error object:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
          setErrors({
            api: `Server error: ${
              error.response.data.message || "Unknown error"
            }`,
          });
        } else if (error.request) {
          console.error("Error request:", error.request);
          setErrors({
            api: "No response received from server. Please try again.",
          });
        } else {
          console.error("Error message:", error.message);
          setErrors({ api: "An error occurred. Please try again." });
        }
      }
    }
  };


  const [siaTypes, setSiaTypes] = useState([]);

  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sia_types_show",
        {id:id},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        // Check if success is an array
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.id.toString(),
          label: type.sia_license_name,
        }));
        setSiaTypes(options);
        console.log("SIA Options:", options);    
      
      } else {
        console.error('Failed to fetch SIA types');
      }
    } catch (error) {
      console.error('Error fetching SIA types:', error);
    }
  };
  const handleSiaTypeChange = (selectedOption) => {
    handleInputChange({
      target: {
        name: 'employee_sia_type',
        value: selectedOption ? selectedOption.value : ''
      }
    });
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'Add New Employee', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();

  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>
   
      </div>
      <div className="container mt-5">
      <Form onSubmit={handleSubmit} className="form-box">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>Staff PIN</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            name="employee_staff_pin"
            placeholder="Staff PIN"
            value={formData.employee_staff_pin}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_staff_pin}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_staff_pin}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={2} style={{fontSize:'15px'}}>
    <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span> Forename
  </Form.Label>
          <Col sm={7}>
          <Form.Control
            type="text"
            name="employee_forename"
            placeholder="Forename"
            value={formData.employee_forename}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_forename}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_forename}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      {/* <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>Middname</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            name="employee_middname"
            placeholder="Middname"
            value={formData.employee_middname}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_middname}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_middname}</Form.Control.Feedback>
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Surname</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            name="employee_surname"
            placeholder="Surname"
            value={formData.employee_surname}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_surname}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_surname}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>Gender</Form.Label>
        <Col sm={7}>
          <Form.Select
            name="employee_gender"
            value={formData.employee_gender}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_gender}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.employee_gender}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>Date of Birth</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="date"
            name="employee_dob"
            value={formData.employee_dob}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_dob}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_dob}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>Contact Number</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            name="employee_contactno"
            placeholder="Contact Number"
            value={formData.employee_contactno}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_contactno}
          />
          <Form.Control.Feedback type="invalid">{errors.employee_contactno}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2} style={{fontSize:'15px'}}>
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Employee Type</Form.Label>
        <Col sm={7}>
          <Form.Select
            name="employee_type"
            value={formData.employee_type}
            onChange={handleInputChange}
            isInvalid={!!errors.employee_type}
          >
            <option value="">Select type...</option>
            <option value="nonOperational">Non Operational</option>
            <option value="operational">Operational</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.employee_type}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      {formData.employee_type === "operational" && (
        <>
          <Form.Group as={Row} className="mb-3">
  <Form.Label column sm={2} style={{fontSize:'15px'}}>
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>SIA Number</Form.Label>
  <Col sm={7}>
    <Form.Control
      type="text"
      name="employee_sia_no"
      placeholder="SIA Number"
      value={formData.employee_sia_no}
      onChange={handleInputChange}
      isInvalid={!!errors.employee_sia_no || !!apiError} // Combine error states
    />
    <Form.Control.Feedback type="invalid">
      {errors.employee_sia_no || apiError}
    </Form.Control.Feedback>
  </Col>
</Form.Group>

<Form.Group as={Row} className="mb-3">
  <Col sm={{ span: 10, offset: 2 }}>
    <Form.Check
      type="checkbox"
      id="verifySIA"
      label={<span style={{ marginLeft: '8px' }}>Verify SIA Number</span>}

      onChange={(e) => e.target.checked && verifySIA()}
      disabled={!formData.employee_sia_no || isLoading}
    />
    {isLoading && ' Verifying...'}
  </Col>
</Form.Group>

<Form.Group as={Row} className="mb-3">
      <Form.Label column sm={2} style={{fontSize:'15px'}}>
      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>SIA Type</Form.Label>
      <Col sm={7}>
        <Form.Control
          name="employee_sia_type"
          value={formData.employee_sia_type}
          onChange={handleInputChange}
          placeholder="SIA Type"
          className={errors.employee_sia_type ? 'is-invalid' : ''}
        />
        {errors.employee_sia_type && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors.employee_sia_type}
          </div>
        )}
      </Col>
    </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} style={{fontSize:'15px'}}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Expiry Date</Form.Label>
            <Col sm={7}>
              <Form.Control
                type="date"
                name="employee_expdate"
                value={formData.employee_expdate}
                onChange={handleInputChange}
                isInvalid={!!errors.employee_expdate}
              />
              <Form.Control.Feedback type="invalid">{errors.employee_expdate}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      )}

{formData.employee_type === "nonOperational" && (
        <>
          <Form.Group as={Row} className="mb-3">
  <Form.Label column sm={2} style={{fontSize:'15px'}}>
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Job Title</Form.Label>
  <Col sm={7}>
    <Form.Control
      type="text"
      name="employee_jobname"
      placeholder="Job Title"
      value={formData.employee_jobname}
      onChange={handleInputChange}
      isInvalid={!!errors.employee_jobname || !!apiError} 
    />
    <Form.Control.Feedback type="invalid">
      {errors.employee_jobname || apiError}
    </Form.Control.Feedback>
  </Col>
</Form.Group>


<Form.Group as={Row} className="mb-3">
      <Form.Label column sm={2} style={{fontSize:'15px'}}>
      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Department</Form.Label>
      <Col sm={7}>
        <Form.Control
        type="text"
          name="employee_department"
          value={formData.employee_department}
          onChange={handleInputChange}
          placeholder="Department"
          className={errors.employee_department ? 'is-invalid' : ''}
        />
        {errors.employee_department && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors.employee_department}
          </div>
        )}
      </Col>
    </Form.Group>

        
        </>
      )}
<Modal 
  show={showModal} 
  onHide={() => {
    setShowModal(false);
    if (apiResponse && apiResponse.length > 0) {
      populateFormData(apiResponse[0]);
    }
  }}
>
  <Modal.Header closeButton>
    <Modal.Title>SIA Verification Result</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  {apiError ? (
    <Alert variant="danger">
      Error: {apiError}
    </Alert>
  ) : apiResponse && apiResponse.length > 0 ? (
    <table className="table table-bordered">
      <tbody>
        {apiResponse[0] && Object.entries(apiResponse[0]).map(([key, value], index) => {
          const label = {
            row_1: 'First name',
            row_2: 'Surname',
            row_3: 'Licence number',
            row_4: 'Role',
            row_5: 'Licence sector',
            row_6: 'Expiry date',
            row_7: 'Status',
          }[`row_${index + 1}`];

          const formattedValue = Object.values(value)[0] || "-";

          return (
            <tr key={key} style={{fontSize:'15px'}}>
              <td style={{width: '40%',fontWeight:'bold'}}>{label}</td>
              <td >{formattedValue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p>SIA Number Not Found</p>
  )}
</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => {
      setShowModal(false);
      if (apiResponse && apiResponse.length > 0) {
        populateFormData(apiResponse[0]);
      }
    }}>
      Close
    </Button>
  </Modal.Footer>
</Modal>


      {errors.api && <div className="alert alert-danger">{errors.api}</div>}

      <Form.Group as={Row}>
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit" className="btn-primary mb-20" onClick={handleSubmit}>
            SAVE
          </Button>
        </Col>
      </Form.Group>
    </Form>
      </div>
    </>
  );
};

export default Add;
