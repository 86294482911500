import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { Modal, Form, } from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const DormantSite = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Sites', bold: false },
      { name: 'Dormant Sites', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>      
       
      </div>

      {/* <div className="container mt-4">
        <div className=" mt-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="d-flex align-items-center" id="flex1">
                <label htmlFor="show" className="me-3">
                  Show
                </label>
                <select
                  id="show"
                  className="form-select-custom me-5"
                  style={{ border: "1px solid lightgray" }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-end" id="flex1">
                <label htmlFor="search" className="me-3" id="search">
                  Search:
                </label>
                <input
                  type="text"
                  id="search"
                  className="form-control-custom"
                  style={{ border: "1px solid lightgray" }}
                />
              </div>
            </div>
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>CLIENT NAME</th>
              <th>Site Group</th>
              <th>SIN</th>
              <th>Site Name</th>
              <th>Contact Persion</th>
              <th>Contact Number</th>
              <th>Post Code</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="d-flex justify-content-between align-items-center">
          <p>Showing 0 to 0 of 0 entries</p>
          <nav>
            <ul className="pagination">
              <li className="page-item disabled">
                <a className="page-link" href="#">
                  Previous
                </a>
              </li>
              <li className="page-item disabled">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <button
          className="btn-danger"
          style={{
            padding: "10px 20px",
            color: "white",
            backgroundColor: "red",
            marginLeft: "980px",
          }}
        >
          Delete
        </button>
      </div> */}

      
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
              // InputProps={{
              //   startAdornment: <SearchIcon fontSize="small" />,
              // }}
            />
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button> */}
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Group</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Person</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Address</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Postcode</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {/* {filteredEmployees
                  .map((site, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{site.site_client_name}</TableCell>
                    <TableCell >{site.site_group_name}</TableCell>
                    <TableCell >{site.site_name}</TableCell>
                    <TableCell >{site.site_contact_person}</TableCell>
                    <TableCell >{site.site_contact_number}</TableCell>
                    <TableCell >{site.site_address}</TableCell>
                    <TableCell >{site.site_post_code}</TableCell>
  

                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEmployees.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          /> */}
        </Paper>
      </Box>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Search Dormant Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Select>
                <option>Head Office</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
              <Form.Select>
                <option>Head Office</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Dormant Since</Form.Label>
              <Form.Control
                type="text"
                placeholder="Select date"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            CLOSE
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            SEARCH
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DormantSite;
