import React, { useState, useEffect } from "react";
import axios from "axios";

const Employment = () => {
  const [employmentHistory, setEmploymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchEmploymentHistory();
  }, []);

  const fetchEmploymentHistory = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_payroll_information",
        { employee_id: "1" },

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response :",response)
      setEmploymentHistory(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Error fetching employment history");
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <h3>Employment</h3>
      {employmentHistory && employmentHistory.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Hourly Pay Rate</th>
              <th>Payment Time</th>
              <th>Fixed Pay</th>
              <th>Travel Expense</th>
              <th>Bank Account Name</th>
              <th>Bank Account Number</th>
              <th>Bank Sort Code </th>
              <th>Bank Name</th>
              <th>Other Information</th>


            </tr>
          </thead>
          <tbody>
            {employmentHistory.map((job, index) => (
              <tr key={index}>
                <td>{job.hourly_pay_rate}</td>
                <td>{job.payment_period}</td>
                <td>{job.fixed_pay}</td>
                <td>{job.travel_expense}</td>
                <td>{job.bank_acc_name}</td>
                <td>{job.bank_acc_no}</td>
                <td>{job.bank_sort_code}</td>
                <td>{job.bank_name}</td>
                <td>{job.other_information}</td>

              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No employment history available.</p>
      )}
    </div>
  );
};

export default Employment;