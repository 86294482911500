import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../Dashboard/assets/media/logos/logo-light.svg";
import logoDefault from "../Dashboard/assets/media/logos/logo-default.svg";
import headerBackground from "../patterns/header-bg.png";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button, Form } from "react-bootstrap";
import "./Dashboard.css";
import { useAuth } from "../SignIn/ProtectedRoute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGauge,
  faCalendarDays,
  faPhone,
  faUserShield,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { Offcanvas, NavItem, NavLink, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SGS_LogoImg from "../Dashboard/assets/media/logos/SGS_LogoImg.png";
import profile_preview_img from "../Dashboard/assets/media/logos/profile preview img.jpg";
import "typeface-roboto";
import NavigationPath from "./Navigation";
import Swal from "sweetalert2";
import { RollerShadesSharp } from "@mui/icons-material";


const Dashboard = ({handleLogout}) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenus, setActiveSubMenus] = useState(null);
  const [showEmployeeDropdown, setShowEmployeeDropdown] = useState(false);
  const [showSubcontractorDropdown, setShowSubcontractorDropdown] =
    useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 858);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 858);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    {
      title: "DASHBOARD",
      icon: "fa-solid fa-gauge",
      path: "/dashboardpage",
      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
    },
    {
      title: "EMPLOYEE",
      icon: "fa-solid fa-users",
      role: ["Admin", "Office Manager"],
      subMenu: [
        {
          title: "Payroll",
          role: ["Admin", "Office Manager"],
          subMenu: [
            { title: "Add", path: "/add", role: ["Admin", "Office Manager"] },
            { title: "View", path: "/view", role: ["Admin", "Office Manager"] },
            { title: "Trash", path: "/trashemployee", role: ["Admin", "Office Manager"] },
            { title: "Dormant Staff", path: "/dormantstaff", role: ["Admin", "Office Manager"] },
            { title: "Guard Group", path: "/guard", role: ["Admin", "Office Manager"] },
            {
              title: "Holiday",
              role: ["Admin", "Office Manager"],
              subMenu: [
                { title: "Staff Holidays", path: "/holiday", role: ["Admin", "Office Manager"] },
                { title: "Holidays Monthly Calendar", path: "/monthlycalendar", role: ["Admin", "Office Manager"] },
                { title: "Current Holidays Request", path: "/holidayrequest", role: ["Admin", "Office Manager"] },
              ],
            },
            {
              title: "Hr",
              role: ["Admin", "Office Manager"],
              subMenu: [
                { title: "Online Applications", path: "/onlineapp", role: ["Admin", "Office Manager"] },
                { title: "Documents", path: "/hrdocuments", role: ["Admin", "Office Manager"] },
              ],
            },
            { title: "Import Employee", path: "/exportemployee", role: ["Admin", "Office Manager"] },
          ],
        },
        {
          title: "Subcontractors",
          role: ["Admin", "Office Manager"],
          subMenu: [
            { title: "Add", path: "/subadd", role: ["Admin", "Office Manager"] },
            { title: "View", path: "/subview", role: ["Admin", "Office Manager"] },
            { title: "Trash", path: "/trash", role: ["Admin", "Office Manager"] },
            { title: "Dormant Sub Staff", path: "/dormantsub", role: ["Admin", "Office Manager"] },
          ],
        },
      ],
    },
    {
      title: "CLIENTS",
      icon: "fa-solid fa-building",
      role: ["Admin", "Client","Office Manager"],
      subMenu: [
        { title: "Add Clients ", path: "/addclient", role: ["Admin"] },
        { title: "View Clients", path: "/viewclient", role: ["Admin", "Client", "Office Manager"]},
        { title: "Trash Clients", path: "/trashclient", role: ["Admin","Client", "Office Manager"] },
      ],
    },
    {
      title: "SITES",
      icon: "fa-solid fa-map-location-dot",
      role: ["Admin", "Client","Office Manager"],
      subMenu: [
        { title: "Add Site", path: "/addsite", role: ["Admin"] },
        { title: "View Site", path: "/viewsite", role: ["Admin", "Client", "Office Manager"] },
        { title: "Trash Site", path: "/trashsite", role: ["Admin","Client", "Office Manager"] },
      ],
    },
    {
      title: "ROSTER",
      icon: "fa-solid fa-calendar-days",
      role: ["Admin", "Accountant", "Client", "Security Officer", "Office Manager"],
      subMenu: [
        { title: "Add Shifts", path: "/addshift", role: ["Admin", "Office Manager"] },
        { title: "Client Roster", path: "/clientroster", role: ["Admin", "Client", "Office Manager","Accountant",] },
        { title: "Staff Roster", path: "/staffroster", role: ["Admin", "Accountant", "Security Officer", "Office Manager","Accountant",] },
        { title: "Sub staff Roster", path: "/substaffroster", role: ["Admin", "Accountant", "Office Manager","Accountant",] },
      ],
    },
    {
      title: "EVENTS",
      icon: "fa-solid fa-calendar-check",
      role: ["Admin", "Office Manager"],
      subMenu: [
        { title: "Events", path: "/events",      role: ["Admin", "Office Manager"],
        },
        { title: "Call out", path: "/callout",      role: ["Admin", "Office Manager"],
        },
      ],
    },
    {
      title: "ACCOUNTS",
      icon: "fa-solid fa-coins",
      role: ["Admin", "Accountant", "Office Manager"],
      subMenu: [
        {
          title: "Invoice",
          role: ["Admin", "Accountant", "Office Manager"],
          subMenu: [
            { title: "Manage Invoices", path: "/manageinvoice",      role: ["Admin", "Accountant", "Office Manager"],
            },
            { title: "Manage Payments", path: "/managepayments" ,      role: ["Admin", "Accountant", "Office Manager"],
            },
            { title: "Credit Notes", path: "/creditnotes" ,      role: ["Admin", "Accountant", "Office Manager"],
            },
          ],
        },
        {
          title: "Payroll",
          role: ["Admin", "Accountant", "Office Manager"],
          subMenu: [
            { title: "Manage payroll", path: "/managepayroll",      role: ["Admin", "Accountant", "Office Manager"],
            },
            { title: "Manage Payments", path: "/payrollpayments",      role: ["Admin", "Accountant", "Office Manager"],
            },
            { title: "Advance Payments", path: "/advancepayment",      role: ["Admin", "Accountant", "Office Manager"],
            },
          ],
        },
      ],
    },
    {
      title: "REPORTS",
      icon: "fa-solid fa-chart-bar",
      role: ["Admin", "Accountant", "Staff","Office Manager"],
      subMenu: [
        {
          title: "Work Force Reports",
          role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            { title: "Staff List", path: "/stafflist",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Application From", path: "/applicationform" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // { title: "Disclaimer Report" ,path:"/disclaimerreport"},
            { title: "SubContracts List", path: "/subcontractslist" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Client Roster Employee List",
              role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/clientrosteremployeelist",
            },
            // { title: "New Employee Form",path:"/newemployeeform"},
            // { title: "Site Distance  to Employees", path:"/sitedistance" },
          ],
        },
        {
          title: "Client and Site Reports",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            // { title: "Client Details",path:"/clientdetails"},
            { title: "Client List", path: "/clientlist" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // { title: "Site Details" ,path:"/SiteDetailsReport"},
            { title: "Site List", path: "/sitelist",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
          ],
        },
        {
          title: "Roster Reports",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            { title: "Employee/Client Roster", path: "/employeeclientroster",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Lateness Report", path: "/latenessreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "SubContracts Report", path: "/subcontractorreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Master Client Roster Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/masterclientrosterreport",
            },
            { title: "Site Roster", path: "/siteroster" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Shift Status Report", path: "/shiftstatusreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // { title: "Weekly Time Sheet",path:"/weeklytimesheet" },
            { title: "Weekly Roster View", path: "/weeklyrosterview",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // { title: "Master Roster Report",path:"/masterrosterreport"},
            // { title: "Contracted Hours Report",path:"/contractorhoursreport"},
          ],
        },
        {
          title: "Timestamp Reports",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            // {title:"Chase Up History Report",path:"/chaseuphistory"},
            { title: "Timestamp Report", path: "/timestampreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
          ],
        },
        {
          title: "Finance Reports(Invoice)",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            { title: "Client Invoice", path: "/clientinvoice",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Client Invoice Payments",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/clientinvoicepayments",
            },
            { title: "Site Wise Invoice", path: "/sitewiseinvoice",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Subcontractor Invoice", path: "/subcontractorinvoice",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Missing Shifts", path: "/missingshift",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Site Shifts Report", path: "/siteshiftreport" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Profit & Loss Statment", path: "/profit",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Timestamp Out Invoice Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/timestampinvoicereport",
            },
            { title: "Total Number of Hours", path: "/totalnumber" ,      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Client Hours Analysis", path: "/clienthoursanalysis",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
          ],
        },
        {
          title: "Finance Reports(Payroll)",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            { title: "Employee Wise Payroll", path: "/employeewisepayroll",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // {title:"All Employees Payroll",path:"/allemployeepayroll"},
            {
              title: "Paid / Unpaid Shift & Holidays",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/painunpaidshift",
            },
            {
              title: "Employee Site Wise Hours",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/employeesitewisehours",
            },
            { title: "Time Sheet", path: "/timesheet",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Master Payroll & Invoice Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/masterpayrollinvoicereport",
            },
            // {title:"Payment Claculation",path:"/paymentcalculation"},
            {
              title: "Subcontractor Hours Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/subcontractorhoursreport",
            },
          ],
        },
        {
          title: "HR Reports",      role: ["Admin", "Accountant", "Staff","Office Manager"],

          subMenu: [
            { title: "Employees Sia Expiry", path: "/employeesiaexpiry",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            // {title:"Sia License Check",path:"/sialicensecheck"},
            {
              title: "Suncontractor Sia Expiry",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/subcontractorsiaexpiry",
            },
            { title: "Employee Visa Expiry", path: "/employeevisaexpiry",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            { title: "Hr Document Report", path: "/htdocumentreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
            },
            {
              title: "Sub Employee Document Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/subemployeedocumentreport",
            },
            {
              title: "Screening and Vetting Report",      role: ["Admin", "Accountant", "Staff","Office Manager"],

              path: "/screeningandvettingreport",
            },
          ],
        },
        { title: "Event Reports", path: "/eventsreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
        },
        { title: "Log Reports", path: "/logreport",      role: ["Admin", "Accountant", "Staff","Office Manager"],
        },
      ],
    },
    {
      title: "SETTINGS",
      icon: "fa-solid fa-gear",
      role: ["Admin", "Office Manager"],
      subMenu: [
        { title: "Company", path: "/companydetails", role: ["Admin", "Office Manager"] },
        { title: "Client", path: "/clientsettings", role: ["Admin", "Office Manager"] },
        {
          title: "Employees",
          role: ["Admin", "Office Manager"],
          subMenu: [
            { title: "General Settings", path: "/generalsetting",      role: ["Admin", "Office Manager"],
            },
            { title: "Staff Web Portal" ,      role: ["Admin", "Office Manager"],
            },
            { title: "Staff Mobile App",      role: ["Admin", "Office Manager"],
            },
          ],
        },
        {
          title: "Subcontractor",
          role: ["Admin", "Office Manager"],
          subMenu: [{ title: "Sub Staff Approval", path: "/subcontractors",      role: ["Admin", "Office Manager"],
          }],
        },
      ],
    },
    {
      title: "TIMESTAMP",
      icon: "fa-solid fa-clock",
      role: ["Admin", "Accountant", "Staff", "Security Officer", "Office Manager"],
      subMenu: [
        { title: "Click to Attendance", path: "/timestamp",      role: ["Admin", "Accountant", "Staff", "Security Officer", "Office Manager"],
        },
        { title: "Attendance Report", path: "/attendancereport",      role: ["Admin", "Accountant", "Staff", "Security Officer", "Office Manager"],
        },
      ],
    },
    {
      title: "SCREENING",
      icon: "fa-solid fa-user-shield",
      onClick: () => handleShowScreening(),
      role: ["Admin", "Office Manager"],
    },
    {
      title: "PROFILE",
      icon: "fa-solid fa-user",
      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
      subMenu: [
        { title: "User", path: "/userdetails",      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
        },
        { title: "SignOut", onClick: () => handleSignOut(),      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
        },
      ],
    },
    { 
      title: "FAQ", 
      icon: "fa-solid fa-question-circle",  
      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
    },
    {
      title: "NOTIFICATIONS",
      icon: "fa-solid fa-bell",
      onClick: () => handleMenuClick({ title: "NOTIFICATIONS" }),
      role: ["Admin", "Accountant", "Office Manager", "Security Officer", "Client"],
    },
  ];

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const stringrole = localStorage.getItem("role");
    setLoggedinrole(stringrole);

    const checkOverflow = () => {
      if (menuRef.current) {
        const isOverflowing =
          menuRef.current.scrollWidth > menuRef.current.clientWidth;
        setShowScrollButtons(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  
  const handleScroll = (direction) => {
    if (menuRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      menuRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const [loggedinrole, setLoggedinrole] = useState("");

  // useEffect(() => {
  //   const stringrole = localStorage.getItem("role");
  //   // if (stringrole){
  //   //   const role = JSON.parse(stringrole)
  //   SetLoggedinrole(stringrole);
  //   // }
  // }, []);

  const hasaccessItem = (role) => {
    console.log({ loggedinrole });
    return role.some((item) => item === loggedinrole);
  };

  const mobileFooterItems = [
    { title: "Dashboard", icon: faGauge, path: "/dashboardpage" },
    { title: "Roster", icon: faCalendarDays, path: "/addshift" },
    { title: "Timestamp", icon: faClock, path: "/timestamp" },
    {
      title: "Screening",
      icon: faUserShield,
      onClick: () => handleShowScreening(),
    },
  ];

  const handleMenuClick = (item) => {
    if (item.path) {
      navigate(item.path);
    } else if (item.title === "SCREENING") {
      Swal.fire({
        title: "Screening",
        html: `
          <p style="color: red; font-size: 18px;">
            Restricted to Authorised Users Only
          </p>
          <h2>PS7858</h2>
        `,
        icon: "warning",
        confirmButtonText: "CLOSE",
      });
    } else if (item.title === "NOTIFICATIONS") {
      handleShowTimestampReport();
    }
  };

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const [showClientReport, setShowClientReport] = useState(false);
  const [showScreening, setShowScreening] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showApplication, setShowApplication] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showSubcontractors, setShowSubcontractors] = useState(false);
  const [showClientRoster, setShowClientRoster] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showDistance, setShowDistance] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const [showSiteDetails, setShowSiteDetails] = useState(false);
  const [showSiteList, setShowSiteList] = useState(false);
  const [showEmployeeRosterForm, setShowEmployeeRosterForm] = useState(false);
  const [showLatness, setShowLatness] = useState(false);
  const [showSubcontractorReport, setShowSubcontractorReport] = useState(false);
  const [showSiteRoster, setShowSiteRoster] = useState(false);
  const [showShiftReport, setShowShiftReport] = useState(false);
  const [showWeekTime, setShowWeekTime] = useState(false);
  const [showWeekRoster, setShowWeekRoster] = useState(false);
  const [showMasterRoster, setShowMasterRoster] = useState(false);
  const [showContracted, setShowContracted] = useState(false);
  const [showChaseReport, setShowChaseReport] = useState(false);
  const [showTimestampReport, setShowTimestampReport] = useState(false);

  const handleShowTimestampReport = () => setShowTimestampReport(true);
  const handleCloseTimestampReport = () => setShowTimestampReport(false);

  const handleShowMasterRoster = () => setShowMasterRoster(true);
  const handleCloseMasterRoster = () => setShowMasterRoster(false);

  const handleShowWeekRoster = () => setShowWeekRoster(true);
  const handleCloseWeekRoster = () => setShowWeekRoster(false);

  const handleShowWeekTime = () => setShowWeekTime(true);
  const handleCloseWeekTime = () => setShowWeekTime(false);

  const handleShowShiftReport = () => setShowShiftReport(true);
  const handleCloseShiftReport = () => setShowShiftReport(false);

  const handleShowSiteRoster = () => setShowSiteRoster(true);
  const handleCloseSiteRoster = () => setShowSiteRoster(false);

  const handleShowSubcontractorReport = () => setShowSubcontractorReport(true);
  const handleCloseSubcontractorReport = () =>
    setShowSubcontractorReport(false);

  const handleShowLatness = () => setShowLatness(true);
  const handleCloseLatness = () => setShowLatness(false);

  const handleShowEmployeeRosterForm = () => setShowEmployeeRosterForm(true);
  const handleCloseEmployeeRosterForm = () => setShowEmployeeRosterForm(false);

  const handleShowSiteList = () => setShowSiteList(true);
  const handleCloseSiteList = () => setShowSiteList(false);

  const handleShowSiteDetails = () => setShowSiteDetails(true);
  const handleCloseSiteDetails = () => setShowSiteDetails(false);

  const handleShowClientList = () => setShowClientList(true);
  const handleCloseClientList = () => setShowClientList(false);

  const handleShowClientDetails = () => setShowClientDetails(true);
  const handleCloseClientDetails = () => setShowClientDetails(false);

  const handleShowDistance = () => setShowDistance(true);
  const handleCloseDistance = () => setShowDistance(false);

  const handleShowEmployeeForm = () => setShowEmployeeForm(true);
  const handleCloseEmployeeForm = () => setShowEmployeeForm(false);

  const handleShowClientRoster = () => setShowClientRoster(true);
  const handleCloseClientRoster = () => setShowClientRoster(false);

  const handleShowScreening = () => setShowScreening(true);
  const handleCloseScreening = () => setShowScreening(false);

  const handleShowClientReport = () => setShowClientReport(true);
  const handleCloseClientReport = () => setShowClientReport(false);

  const handleSubMenuClick = (e, item) => {
    e.stopPropagation();
    if (item.path) {
      navigate(item.path);
    } else if (item.onClick) {
      item.onClick();
    }
  };
  
  const handleSignOut = () => {
    localStorage.removeItem("token");
    Swal.fire({
      icon: "success",
      title: "Logout successful",
      text: "You will be redirected to the sign in.",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    }).then(() => {
      handleLogout();
      navigate("/");
    });
  };

  const handleMenuHover = (menuTitle) => {
    setActiveMenu(menuTitle);
    setActiveSubMenus([]);
    setShowEmployeeDropdown(false);
    setShowSubcontractorDropdown(false);
  };

  const handleSubMenuHover = (subMenuPath) => {
    setActiveSubMenus(subMenuPath);
  };

  const renderSubMenu = (items, currentPath = [], level = 0, subMenu) => {
    if (!items) return null;
  
    return (
      <ul className={`submenu level-${level}`} >
        {items.map((item, index) => {
          if (hasAccessItem(item.role)) {
            const itemPath = [...currentPath, item.title];
            const isActive =
              activeSubMenus.length >= itemPath.length &&
              itemPath.every((item, i) => item === activeSubMenus[i]);
  
            return (
              <li
              // style={{overflowY:'hidden',zIndex:'999',position:'absolute'}}
                key={index}
                onMouseEnter={() => handleSubMenuHover(itemPath)}
                onClick={(e) => {
                  handleSubMenuClick(e, item);
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {level === 0 && (
                    <i
                      className="fas fa-caret-right me-2"
                      style={{ color: "black" }}
                    ></i>
                  )}
                  {level === 1 && (
                    <i
                      className="fas fa-circle-arrow-right me-2"
                      style={{ color: "black" }}
                    ></i>
                  )}
                  {level >= 2 && (
                    <i
                      className="fas fa-long-arrow-alt-right me-2"
                      style={{ color: "black" }}
                    ></i>
                  )}
                  {item.title}
                </span>
                {isActive &&
                  item.subMenu &&
                  renderSubMenu(item.subMenu, itemPath, level + 1, true)}
                {isActive && item.sunMenu && renderSunMenu(item, itemPath)}
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };
  
  const renderSunMenu = (item, itemPath) => {
    return (
      <div className="dropdown">
        <button
          className="dropdown-toggle"
          onClick={() => {
            if (item.title === "Employees") {
              setShowEmployeeDropdown(!showEmployeeDropdown);
              setShowSubcontractorDropdown(false);
            } else if (item.title === "Subcontractor") {
              setShowSubcontractorDropdown(!showSubcontractorDropdown);
              setShowEmployeeDropdown(false);
            }
          }}
        >
          ▼
        </button>
        {(item.title === "Employees" && showEmployeeDropdown) ||
        (item.title === "Subcontractor" && showSubcontractorDropdown) ? (
          <div className="dropdown-menu show">
            {item.sunMenu.map((sunItem, index) => (
              <a
                style={{ marginLeft: "0" }}
                key={index}
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (sunItem.title === "General Settings") {
                    navigate("/generalsetting");
                  } else if (sunItem.title === "Sub Staff Approval") {
                    navigate("/subcontractors");
                  }
                }}
              >
                <i
                  className="fas fa-circle-arrow-right me-2"
                  style={{ color: "black" }}
                ></i>
                {sunItem.title}
              </a>
            ))}
          </div>
        ) : null}
      </div>
    );
  };
  
  // const hasAccessItem = (role) => {
  //   return role.includes(loggedinrole);
  // };

  const hasAccessItem = (role) => {
    if (!role || !Array.isArray(role)) {
      return false; // If role is undefined or not an array, deny access
    }
    return role.includes(loggedinrole);
  };

 


  const customFontFace = `
  @font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  }
`;


  const Sidebar = ({ menuItems, showSidebar, toggleSidebar }) => {
    const [activeKeys, setActiveKeys] = useState([]);

    const toggleAccordion = (eventKey) => {
      setActiveKeys((prevKeys) =>
        prevKeys.includes(eventKey)
          ? prevKeys.filter((key) => key !== eventKey)
          : [...prevKeys, eventKey]
      );
    };

    const renderMenuItems = (items, level = 0) => {
      return items.map((item, index) => (
        <NavItem key={`${level}-${index}`}>
          {item.subMenu ? (
            <Accordion activeKey={activeKeys}>
              <Accordion.Item eventKey={`${level}-${index}`}>
                <Accordion.Header
                  onClick={() => toggleAccordion(`${level}-${index}`)}>
                  <i className={`${item.icon} me-2`}></i>
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>
                  <Nav className="flex-column">
                    {renderMenuItems(item.subMenu, level + 1)}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : item.sunMenu ? (
            <Accordion activeKey={activeKeys}>
              <Accordion.Item eventKey={`${level}-${index}`}>
                <Accordion.Header
                  onClick={() => toggleAccordion(`${level}-${index}`)}>
                  <i className={`${item.icon} me-2`}></i>
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>
                  <Nav className="flex-column">
                    {item.sunMenu.map((sunItem, sunIndex) => (
                      <NavLink
                        key={`${level}-${index}-${sunIndex}`}
                        href={sunItem.path}
                        className="d-flex align-items-center">
                        {sunItem.title}
                      </NavLink>
                    ))}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <NavLink href={item.path} className="d-flex align-items-center">
              {item.icon && <i className={`${item.icon} me-2`}></i>}
              {item.title}
            </NavLink>
          )}
        </NavItem>
      ));
    };

    return (
      <Offcanvas
        show={showSidebar}
        onHide={toggleSidebar}
        placement="start"
        className="w-75 w-sm-50">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">{renderMenuItems(menuItems)}</Nav>
        </Offcanvas.Body>
      </Offcanvas>
    );
  };

  const [notifications] = useState([
    {
      id: 1,
      icon: "🏆",
      title: "Congratulation Lottie",
      description: "Won the monthly best seller gold badge",
      time: "5h ago",
      color: "warning",
    },
    {
      id: 2,
      icon: "👤",
      title: "Charles Franklin",
      description: "Accepted your connection",
      time: "2d ago",
      color: "primary",
    },
    {
      id: 3,
      icon: "💬",
      title: "New Message",
      description: "You have new message from Natalie",
      time: "3d ago",
      color: "info",
    },
    {
      id: 4,
      icon: "🛒",
      title: "Whoo! You have new order",
      description: "ACME Inc. made new order $1,154",
      time: "1w ago",
      color: "success",
    },
    {
      id: 5,
      icon: "📄",
      title: "Application has been approved",
      description: "Your store application has been approved",
      time: "2w ago",
      color: "secondary",
    },
  ]);

  // const handleScroll = (arrow) => {
  //   console.log({arrow})
  // }

  return (
    <>
      <Modal show={showScreening} onHide={handleCloseScreening}>
        <Modal.Header closeButton>
          <Modal.Title>Screening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "red", fontSize: "15px" }}>
            Restricted to Authorise Users Only
          </p>
          <h2>PS7858</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseScreening}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMasterRoster} onHide={handleCloseMasterRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Master Roster Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>All Clients</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Site Groups</Form.Label>
              <Form.Select as="select">
                <option>All Site Groups</option>
                <option>Site Group</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select as="select">
                <option>All Sites</option>
                <option>Site</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select as="select">
                <option>Select Staff...</option>
                <option>Employee</option>
                <option>Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Show Remarks" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseMasterRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTimestampReport} onHide={handleCloseTimestampReport}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="d-flex align-items-center mb-3">
              <div
                className={`bg-${notification.color} text-white rounded-circle p-2 me-3`}
                style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {notification.icon}
              </div>
              <div>
                <h6 className="mb-0">{notification.title}</h6>
                <p className="mb-0 text-muted">{notification.description}</p>
                <small className="text-muted">{notification.time}</small>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCloseTimestampReport}
            className="w-100">
            View all notifications
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <style>{customFontFace}</style>
        <style>{`
        .header-container {
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        .menu-scroll-container {
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .menu-scroll-container::-webkit-scrollbar {
          display: none;
        }
        .scroll-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 1000;
        }
        .scroll-left { left: 0; }
        .scroll-right { right: 0; }
      `}</style>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"
          rel="stylesheet"
          type="text/css"
        />
        <div
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-enabled dashboard-header"
          style={{
            // backgroundColor: "rgba(0, 0, 0, 0.9)",
            // maxWidth: "98vw",
            // overflowX: "auto",
            // overflowY: "hidden",
            // zIndex:1,
            // position: "absolute",
          }}
          >
          <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper">
                <header
                  id="kt_header"
                  className="header align-items-stretch"
                  data-kt-sticky="true"
                  data-kt-sticky-name="header"
                  data-kt-sticky-offset='{"default": "200px", "lg": "300px"}'>
                  <div className="container-xxl d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center d-lg-none me-2">
                      <button
                        className="btn btn-icon btn-active-color-primary"
                        id="kt_aside_mobile_toggle"
                        onClick={toggleSidebar}>
                        <i className="fas fa-bars fs-1"></i>
                      </button>
                    </div>
                    <div
                      className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0"
                      style={{ marginRight: "20px" }}>
                      <img
                        alt="Logo"
                        src={SGS_LogoImg}
                        className="h-20px h-lg-35px "
                      />
                    </div>
                    {isMobile && (
                      <div className="mobile-notification">
                        <FontAwesomeIcon icon={faBell} className="text-white" />
                        {/* <i class="fa fa-chevron-right"></i> */}
                      </div>
                    )}

<div className="d-none d-lg-flex  align-items-stretch justify-content-between flex-lg-grow-1 " id="Headers">
      {/* {showScrollButtons && (
        <button className="btn btn-link text-decoration-none" onClick={() => handleScroll('left')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )} */}
      {/* <div
        ref={menuRef}
        className="d-flex align-items-stretch overflow-hidden"
        style={{ maxWidth: 'calc(80% - 60px)' }}
      > */}
        <nav    className="header-menu align-items-stretch" 
                        data-kt-drawer="true">
          <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                          id="kt_header_menu"
                          data-kt-menu="true">
            {menuItems.map((item) => (
              hasAccessItem(item.role) && (
                <div
                  key={item.title}
                  className="menu-item menu-lg-down-accordion me-lg-1"
                  onMouseEnter={() => handleMenuHover(item.title)}
                  onMouseLeave={() => setActiveMenu(null)}
                  onClick={() => handleMenuClick(item)}
                >
                  <span className="menu-link py-2">
                    <i className={`${item.icon} menu-icon`}></i>
                    <span className="menu-title">
                      {item.title}
                    </span>
                    <span className="menu-arrow d-lg-none"></span>
                  </span>
                  {activeMenu === item.title && renderSubMenu(item.subMenu, [item.title])}
                </div>
              )
            ))}
          </div>
        </nav>
      {/* </div> */}
      {/* {showScrollButtons && (
        <button className="btn btn-link text-decoration-none" onClick={() => handleScroll('right')}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )} */}
    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sidebar
        menuItems={menuItems}
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
      />

      <div className="mobile-footer">
        {isMobile &&
          mobileFooterItems.map((item, index) => (
            <Button
              key={index}
              variant="outline-light"
              onClick={() =>
                item.onClick ? item.onClick() : navigate(item.path)
              }
              className="mobile-footer-item">
              <FontAwesomeIcon icon={item.icon} />
              <div>{item.title}</div>
            </Button>
          ))}
      </div>
    </>
  );
};
export default Dashboard;
