import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation } from "react-router-dom";
import { Modal ,Form, Row, Col, InputGroup } from "react-bootstrap";
import "./Invoice.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "../../Dashboard/Dashboard"
import jsPDF from "jspdf";
import 'jspdf-autotable';
import NewSGSLogo from "../Invoice/patterns/logos/NewSGSLogo.jpeg"
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Visibility, Edit, Delete, Email, PictureAsPdf } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { CheckCircle as CheckCircleIcon, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';


   const ManagePayments = () => {
     const [payments, setPayments] = useState([]);
     const [filteredPayments, setFilteredPayments] = useState([]);
     const [invoiceData, setInvoiceData] = useState([]);
     const [showPaymentForm, setShowPaymentForm] = useState(false);
     const [currentPage, setCurrentPage] = useState(1);
     const [entriesPerPage, setEntriesPerPage] = useState(10);
     const [searchTerm, setSearchTerm] = useState("");
     const [errors, setErrors] = useState({});
     const [showEmail, setShowEmail] = useState(false);
     const [formData, setFormData] = useState({
       payment_inv_no: "",
       payment_tot_amount: 0,
       payment_due_amount: 0,
       payment_amount: 0,
       payment_date: "",
       payment_agreed: false,
       payment_notes: "",
       company_branch: 1
     });
     const [showSearchInvoice, setShowSearchInvoice] = useState(false);
     const [advancedSearchParams, setAdvancedSearchParams] = useState({
      payment_inv_no: "",
      payment_tot_amount: 0,
      payment_due_amount: 0,
      payment_amount: 0,
      payment_date: "",
      payment_agreed: false,
      payment_notes: "",
      company_branch: 1
     });
   
     const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
  

     const tableStyles = {
      tableWrapper: {
        margin: '20px 5px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        borderRadius: '8px',
        overflow: 'hidden',
      },
      table: {
        margin: '0',
        width: '100%',
      },
      tableHeader: {
        backgroundColor: '#f8f9fa',
        color: '#495057',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      tableCell: {
        verticalAlign: 'middle',
      },
      statusButton: {
        width: '100%',
        fontWeight: 'bold',
      },
    };

     useEffect(() => {
       fetchInvoicePayments();
     }, []);
   
    //  useEffect(() => {
    //    handleSearch();
    //  }, [searchTerm, payments]);


  const fetchInvoicePayments = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_inv_payment",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setPayments(response.data.data);
      setFilteredPayments(response.data.data);
    } catch (error) {
      console.error("Error fetching invoice payments:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
      payment_due_amount:
        name === 'payment_amount'
          ? prevFormData.payment_tot_amount - parseFloat(value)
          : prevFormData.payment_tot_amount - prevFormData.payment_amount,
    }));
  };

  useEffect(() => {
    const filtered = payments.filter((payment) =>
      Object.values(payment).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredPayments(filtered);
    setCurrentPage(0);
  }, [searchTerm, payments]);

  // const handleSearch = () => {
  //   const filtered = payments.filter((payment) =>
  //     Object.values(payment).some(
  //       (value) =>
  //         value &&
  //         value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredPayments(filtered);
  //   setCurrentPage(1);
  // };
  const handleAdvancedSearchChange = (e) => {
    const { name, value } = e.target;
    setAdvancedSearchParams(prevParams => ({
      ...prevParams,
      [name]: value
    }));
  };
  const handleAdvancedSearch = () => {
    const filtered = payments.filter((payment) => {
      return (
        (advancedSearchParams.payment_inv_no === "" || payment.payment_inv_no?.toLowerCase().includes(advancedSearchParams.payment_inv_no.toLowerCase())) &&
        (advancedSearchParams.payment_notes === "" || payment.payment_notes?.toLowerCase().includes(advancedSearchParams.payment_notes.toLowerCase())) &&
        (advancedSearchParams.payment_date === "" || new Date(payment.payment_date) >= new Date(advancedSearchParams.payment_date)) &&
        (advancedSearchParams.payment_amount === "" || new Date(payment.payment_amount) <= new Date(advancedSearchParams.payment_amount))
        (advancedSearchParams.payment_tot_amount === "" || new Date(payment.payment_tot_amount) <= new Date(advancedSearchParams.payment_tot_amount))
        (advancedSearchParams.payment_due_amount === "" || new Date(payment.payment_due_amount) <= new Date(advancedSearchParams.payment_due_amount))

      );
    });
    setFilteredPayments(filtered);
    setCurrentPage(1);
    setShowSearchInvoice(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.payment_amount || formData.payment_amount <= 0) {
      newErrors.payment_amount = "Payment amount must be a positive number";
    } else if (formData.payment_amount > formData.payment_tot_amount) {
      newErrors.payment_amount = "Payment amount cannot exceed the total amount";
    } else if (formData.payment_amount > formData.payment_due_amount) {
      newErrors.payment_amount = "Payment amount cannot exceed the due amount";
    }

    if (!formData.payment_date) {
      newErrors.payment_date = "Payment date is required";
    }

    if (!formData.payment_agreed) {
      newErrors.payment_agreed = "Payment agreement is required";
    }

    if (!formData.payment_inv_no) {
      newErrors.payment_inv_no = "Payment invoice number is required";
    }

    if (!formData.payment_notes) {
      newErrors.payment_notes = "Payment notes is required";
    }

    if (!formData.payment_tot_amount) {
      newErrors.payment_tot_amount = "Payment total Amount is required";
    }

    if (!formData.payment_due_amount) {
      newErrors.payment_due_amount = "Payment due Amount is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  
  const handleEditPayment = async (payment) => {
    try {
      const { value: updatedPaymentAmount } = await Swal.fire({
        title: `Edit Payment for Invoice #${payment.payment_inv_no}`,
        input: 'number',
        inputLabel: 'Update Payment Amount',
        inputValue: payment.payment_amount,
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
      });

      if (updatedPaymentAmount) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_inv_payment",
          {
            payment_id: payment.id,
            payment_amount: updatedPaymentAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Payment Updated',
            text: 'Payment has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          });
          fetchInvoicePayments(); // Refresh the list
        } else {
          console.error("Error updating payment:", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating payment:", error);
    }
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_inv_payment",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
  
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Payments Updated',
            text: 'Payments has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchInvoicePayments(); // Refresh the list
          setShowPaymentForm(false);
        } else {
          console.error("Error adding payment:", response.data.message);
        }
      } catch (error) {
        console.error("Error adding payment:", error);
      }
    }
  };
  
  const handleShowPaymentForm = (payment) => {
    setFormData({
      payment_inv_no: payment.InvoiceNumber,
      payment_tot_amount: payment.GrossAmount,
      payment_due_amount: payment.DueAmount,
      payment_amount: 0,
      payment_date: "",
      payment_agreed: false,
      payment_notes: "",
      company_branch: 1
    });
    setShowPaymentForm(true);
  };

  const [showEventDetail, setShowEventDetail] = useState(false);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);

  const handleshowPaymentForm = () => setShowPaymentForm(true);
  const handleClosePaymentForm = () => setShowPaymentForm(false);

  const handleShowEmail = () => setShowEmail(true);
  const handleCloseEmail = () => setShowEmail(false);

  const handleShowSearchInvoice = () => setShowSearchInvoice(true);
  const handleCloseSearchInvoice = () => setShowSearchInvoice(false);

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);

 

  const generatePDF = (payment) => {
    if (!payment) {
      console.error('Invalid payment data');
      return null;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471',margin,45)

    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('INVOICE', pageWidth - margin, 25, { align: 'right' });
  
    // Add payment details
    doc.autoTable({
      startY: 90,
      head: [['Invoice Number','Payment Date','Date']],
      body: [
        [` ${payment.payment_inv_no || 'N/A'}`,`${payment.payment_date || 'N/A'}`, `${new Date().toLocaleDateString()}`

        ],
        
      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Invoice#: ${payment.payment_inv_no || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Invoice Date: ${new Date().toLocaleDateString()}`, pageWidth - margin, 45, { align: 'right' });
    doc.text(`Notes: ${payment.payment_notes || 'N/A'}`, pageWidth - margin, 50, { align: 'right' });
  
    const finalY = doc.lastAutoTable.finalY || 120;
    doc.setFontSize(14);
    doc.text('Payment Details:', margin, finalY + 20);
  
    doc.autoTable({
      startY: finalY + 30,
      head: [['Description', 'Amount']],
      body: [
        ['Total Amount', `£${payment.payment_tot_amount || '0.00'}`],
        ['Due Amount', `£${payment.payment_due_amount || '0.00'}`],
        ['Paid Amount', `£${payment.payment_amount || '0.00'}`],
        ['Net Amount', `£${payment.net_amount || '0.00'}`],
        ['VAT @ 20%', `£${payment.vat_amount || '0.00'}`],
        ['Billable Expenses', `£${payment.billable_expenses || '0.00'}`],
        ['Gross Amount', `£${payment.gross_amount || '0.00'}`]

      ],
      theme: 'grid',
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
        fontStyle: 'bold'
      },
      bodyStyles: {
        textColor: 0,
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      margin: { top: 110 }
    });
  
    
      return doc.output('blob');};

  
  const previewPDF = (payment) => {
    const pdfBlob = generatePDF(payment);
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Invoice', bold: false },
      { name: 'Manage Payments', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredPayments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(workbook, "ManagePayment_data.xlsx");
  }

  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [creditData, setCreditData] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_invoice",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
        const options = successData.map(invoice => ({
          value: invoice.invoice_no,
          label: invoice.invoice_no,
        }));
        setInvoiceOptions(options);
      } else {
        console.error('Failed to fetch Invoices');
      }
    } catch (error) {
      console.error('Error fetching Invoices:', error);
    }
  };


  const handleInvoiceChange = async (selectedOption) => {
    if (selectedOption) {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/inv_payment_auto",
          { payment_inv_no: selectedOption.value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          const invoiceData = response.data.data[0];
          const totalCredit = invoiceData.credit.reduce((sum, credit) => sum + parseFloat(credit.credit_amount), 0);
          const totalAmount = parseFloat(invoiceData.gross_total) + totalCredit;

          setFormData((prevState) => ({
            ...prevState,
            payment_inv_no: invoiceData.invoice_no,
            payment_tot_amount: totalAmount.toFixed(2),
            payment_due_amount: totalAmount.toFixed(2),
          }));

          // Prepare credit data for the table
          const newCreditData = invoiceData.credit.map(credit => ({
            date: credit.credit_date,
            amount: credit.credit_amount,
            number: credit.credit_number,
            status: 'Credit',
            comment: credit.credit_description,
          }));

          setCreditData(newCreditData);
        } else {
          console.error("Failed to fetch invoice data");
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        payment_inv_no: "",
        payment_tot_amount: 0,
        payment_due_amount: 0,
      }));
      setCreditData([]);
    }
  };






 const tooltipStyles = {
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: '#fff',
    fontSize: '14px',
    padding: '8px 12px',
    borderRadius: '4px',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2 d-flex justify-content-between align-items-center"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>             
          <div className="d-flex" style={{marginRight:'90px'}}>
          
                 
            <Button
  variant="contained"
  color="primary"
  onClick={handleshowPaymentForm}
  sx={{ padding: '8px', marginLeft: '100px' }}
>
Add Payments
</Button>
          </div>
          <Modal show={showSearchInvoice} onHide={handleCloseSearchInvoice}>
            <Modal.Header closeButton>
              <Modal.Title>Search Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
                  <Form.Select style={{ width: "100%" }}>
                    <option>All Client...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
                  <Form.Select style={{ width: "100%" }}>
                    <option>All Site Group...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
                  <Form.Control
                    type="Date"
                    style={{ width: "100%" }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>End Date</Form.Label>
                  <Form.Control type="date" style={{ width: "100%" }} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary">SEARCH</Button>
              <Button variant="secondary" onClick={handleCloseSearchInvoice}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>

      </div>
    
      <Modal
      show={showPaymentForm}
      onHide={handleClosePaymentForm}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>MAKE INVOICE PAYMENTS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group className="mb-3">
  <Form.Label style={{ float: 'left' }}>
    <span className="text-danger" style={{ fontSize: '18px', verticalAlign: 'middle' }}>*</span>Invoice Number
  </Form.Label>
  <Select
    styles={{
      control: (provided) => ({
        ...provided,
        width: '100%',
      }),
    }}
    name="payment_inv_no"
    options={invoiceOptions}
    onChange={(selectedOption) => handleInvoiceChange(selectedOption)}
  />
  {errors.payment_inv_no && <span className="text-danger">{errors.payment_inv_no}</span>}
</Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{float:'left'}}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Total Amount</Form.Label>
            <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              type="number"
              name="payment_tot_amount"
              value={formData.payment_tot_amount}
              onChange={handleInputChange}
            />
             <InputGroup.Text>.00</InputGroup.Text>
             </InputGroup>
{errors.payment_tot_amount && <span className="text-danger">{errors.payment_tot_amount}</span>}

          </Form.Group>
          <Form.Group className="mb-3">
  <Form.Label style={{ float: 'left' }}>
    <span className="text-danger" style={{ fontSize: '18px', verticalAlign: 'middle' }}>*</span>Due Amount
  </Form.Label>
  <InputGroup>
  <InputGroup.Text>£</InputGroup.Text>
  <Form.Control
    type="number"
    name="payment_due_amount"
    value={formData.payment_due_amount}
    onChange={handleInputChange}
    readOnly
  />
          <InputGroup.Text>.00</InputGroup.Text>
          </InputGroup>
  {errors.payment_due_amount && (
    <span className="text-danger">{errors.payment_due_amount}</span>
  )}
</Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{float:'left'}}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Pay Amount</Form.Label>
            <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              type="number"
              name="payment_amount"
              value={formData.payment_amount}
              onChange={handleInputChange}
              required
            />
                    <InputGroup.Text>.00</InputGroup.Text>
                    </InputGroup>
{errors.payment_amount && <span className="text-danger">{errors.payment_amount}</span>}

          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{float:'left'}}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Payment Date</Form.Label>
            <Form.Control
              type="date"
              name="payment_date"
              value={formData.payment_date}
              onChange={handleInputChange}
              required
            />
{errors.payment_date && <span className="text-danger">{errors.payment_date}</span>}

          </Form.Group>
          <Form.Group className="mb-5 mt-5">
            <Form.Check
              type="checkbox"
              label="PAYMENT AGREED ?"
              name="payment_agreed"
              checked={formData.payment_agreed}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{float:'left'}}>NOTE (COMMENTS)</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="payment_notes"
              value={formData.payment_notes}
              onChange={handleInputChange}
            />
{errors.payment_notes && <span className="text-danger">{errors.payment_notes}</span>}

          </Form.Group>
        
        </Form>
        {creditData.length > 0 && (
          <div style={tableStyles.tableWrapper}>
            <Table striped bordered hover style={tableStyles.table}>
              <thead>
                <tr style={tableStyles.tableHeader}>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Number</th>
                  <th>Status</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {creditData.map((data, index) => (
                  <tr key={index}>
                    <td style={tableStyles.tableCell}>{data.date}</td>
                    <td style={tableStyles.tableCell}>${parseFloat(data.amount).toFixed(2)}</td>
                    <td style={tableStyles.tableCell}>{data.number}</td>
                    <td style={tableStyles.tableCell}>
                     
                        {data.status}
                 
                    </td>
                    <td style={tableStyles.tableCell}>{data.comment}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
      <Button  
      className="me-2"
       variant="contained"
  color="primary" type="submit" onClick={handleAddPayment}>
            ADD PAYMENT
          </Button>
        <Button   variant="contained"
  color="secondary" onClick={handleClosePaymentForm}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
        {/* <h3 style={{padding:'5px'}}>DUE AMOUNT : {filteredPayments.reduce((total, payment) => total + parseFloat(payment.payment_due_amount || 0), 0).toFixed(2)}</h3> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Invoice Number </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Invoice Date </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Total Amount</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Due Amount</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Paid Amount</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Notes</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Payment Status</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredPayments
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((payment, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell >{payment.payment_inv_no}</TableCell>
                    <TableCell >{payment.payment_date}</TableCell>
                    <TableCell >{payment.payment_tot_amount}</TableCell>
                    <TableCell >{payment.payment_due_amount}</TableCell>
                    <TableCell >{payment.payment_amount}</TableCell>
                    <TableCell >{payment.payment_notes}</TableCell>
                    <TableCell >
                      
                    <Button
    variant="contained"
    sx={{
      color: 'white',
      backgroundColor: payment.PaymentStatus === "Unpaid" ? "red" : "green",
      padding: "8px 15px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      marginLeft: '50px',
      '&:hover': {
        backgroundColor: payment.PaymentStatus === "Unpaid" ? "darkred" : "darkgreen",
      },
    }}
  >
    {payment.PaymentStatus === "Unpaid" ? "Unpaid" : "Paid"}
  </Button>
  </TableCell>
  <TableCell >
  <Tooltip
                        title="Edit Payment"
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: { sx: tooltipStyles.tooltip },
                          arrow: { sx: tooltipStyles.arrow },
                        }}
                      >
                        <IconButton
                          onClick={() => handleEditPayment(payment)}
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                            '&:hover': {
                              backgroundColor: "primary.dark",
                            },
                            padding: "6px",
                            marginRight: "8px",
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>  
  <Tooltip 
    title="View PDF" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    onClick={() => previewPDF(payment)}
    sx={{
      backgroundColor: "primary.main",
      color: "white",
      '&:hover': {
        backgroundColor: "primary.dark",
      },
      padding: "6px",
    }}
  >
    <PictureAsPdfIcon fontSize="small" />
  </IconButton>
  </Tooltip>
 
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredPayments.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ManagePayments;
