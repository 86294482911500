import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button as BootstrapButton, Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import Dashboard from "../../../Dashboard/Dashboard";

const HrDocumentreport = () => {
  const location = useLocation();
  const componentRef = useRef();
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [employeeName, setEmployeeName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/emp_document_report',
        {},
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      if (response.data.success) {
        setDocuments(response.data.data);
        setFilteredDocuments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      Swal.fire('Error', 'Failed to fetch documents', 'error');
    }
  };

  const handleFilter = () => {
    const filtered = documents.filter(doc => {
      const docDate = new Date(doc.created_at);
      const nameMatch = doc.employee.toLowerCase().includes(employeeName.toLowerCase());
      let dateMatch = true;
      if (startDate && endDate) {
        const docDate = new Date(doc.created_at);
        dateMatch = docDate >= startDate && docDate <= endDate;
      }
            return nameMatch && dateMatch;
    });
    setFilteredDocuments(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setDateRange([null, null]);
    setEmployeeName("");
    setFilteredDocuments(documents);
    setCurrentPage(0);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredDocuments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HR Documents");
    XLSX.writeFile(workbook, "HRDocuments.xlsx");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Hr Document report',
    onAfterPrint: () => alert('Print successful!'),
  });

  const getNavigationPath = () => [
    { name: 'Report', bold: false },
    { name: 'Hr Report', bold: false },
    { name: 'Hr Document report', bold: true },
  ];

  const navigationPath = getNavigationPath();

  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <div className="container w-100">
        <Form>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Employee Name</Form.Label>
                <Form.Control 
                  type="text" 
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  style={{width:'200px',padding:'5px'}} 
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Date</Form.Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  style={{width:'200px'}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootstrapButton type="button" variant="primary" className="me-2" style={{padding:'5px 20px'}} onClick={handleFilter}>Filter</BootstrapButton>
              <BootstrapButton type="button" variant="secondary" className="me-2" style={{padding:'5px 20px'}} onClick={handleReset}>Reset</BootstrapButton>
            </Col>
          </Row>
        </Form>
      </div>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'84%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
            </Button>
          </Box>
          <TableContainer ref={componentRef}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  {['S.NO', 'Employee', 'Document Type', 'Copy Type', 'Comments', 'Created At'].map((header) => (
                    <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocuments
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((doc, index) => (
                    <TableRow key={doc.id} hover>
                      <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.employee}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.type_document}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.copy_type}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{doc.comments}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{(doc.created_at)}</TableCell>
                
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredDocuments.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  )
}

export default HrDocumentreport;