import React,{useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';


const GDPRNotice = () => {


  const [formData, setFormData] = useState({
    employee_id: "1",
    categories: "", 
    consent: "", 
    time: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the request payload
    const payload = {
      employee_id: formData.employee_id,
      categories: formData.categories,
      consent: formData.consent,
      time: formData.time,
      date: formData.date
    };

    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_gdpr_notice', payload, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      
      console.log('Data submitted successfully:', response);
      Swal.fire({
        icon: 'success',
        title: 'GDPR Notice Updated',
        text: 'GDPR Notice has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error submitting data. Please try again.');
    }
  };

  return (
    <Container className="px-4 py-5">
      <Row>
        <Col md={12}>
          <h2>Who We Are</h2>
          <p>
            COMPANY gather and process your personal information in accordance
            with this privacy notice and in compliance with the relevant data
            protection Regulation and laws. This notice provides you with the
            necessary information regarding your rights and our obligations, and
            explains how, why and when we process your personal data.
          </p>
          <p>
            COMPANY registered office is at ADDRESS we are a company registered
            in England and Wales under company number QAZXCV12344. We are
            registered on the Information Commissioner's Office Register;
            registration number QAZXCV12344, and act as the data controller when
            processing your data. Our designated Data Protection
            Officer/Appointed Person is "Contact Person", who can be contacted
            at Tel: 1234567890
          </p>

          <h2>Information That We Collect</h2>
          <p>
            COMPANY processes your personal information to meet our legal,
            statutory and contractual obligations under the standard BS
            7858:2020 and the contractual obligation to provide the services to
            our client. We will never collect any unnecessary personal data from
            you and do not process your information in any way, other than as
            specified in this notice.
          </p>
          <p>The personal data that we collect from you is: -</p>
          <ul>
            <li>Name</li>
            <li>Date of Birth</li>
            <li>Home Address</li>
            <li>Personal Email</li>
            <li>Home Telephone Number</li>
            <li>Mobile Telephone Number</li>
            <li>National Insurance Number</li>
            <li>Passport Number</li>
            <li>Driver's License Number</li>
            <li>Previous Employment History for the Last Ten Years</li>
            <li>Educational History</li>
            <li>Personal References</li>
          </ul>

          <h2>We collect information in the below ways:</h2>
          <p>online form, website, employment CV's</p>

          <p>How We Use Your Personal Data (Legal Basis for Processing)</p>
          <p>
            COMPANY takes your privacy very seriously and will never disclose,
            share or sell your data without your consent; unless required to do
            so by law. We only retain your data for as long as is necessary and
            for the purpose(s) specified in this notice. Where you have
            consented to us advertise your name and achievements as reference
            for Tendering or company promotion, you are free to withdraw this
            consent at any time.
          </p>
          <p>
            The purposes and reasons for processing your personal data are
            detailed below: -
          </p>

          <h3>
            Employment and to provide our customer base with our security
            services
          </h3>
          <ul>
            <li>
              We collect your personal data in the performance of a contract or
              to provide a service required by our clients.
            </li>
            <li>
              We collect and store your personal data as part of our legal
              obligation for business accounting and tax purposes
            </li>
            <li>
              We have a legal obligation to share your personal data with
              Graydon, Graydon is a credit reference agency and provide us with
              financial background checks prior to you commencing employment
              with us
            </li>
            <li>
              We will occasionally send you information relating to the company
              and for your knowledge. Such information will be non-intrusive and
              is processed on the grounds of legitimate interests
            </li>
          </ul>

          <h2>Your Rights</h2>
          <p>
            You have the right to access any personal information that COMPANY
            processes about you and to request information about: -
          </p>
          <ul>
            <li>What personal data we hold about you</li>
            <li>The purposes of the processing</li>
            <li>The categories of personal data concerned</li>
            <li>
              The recipients to whom the personal data has/will be disclosed
            </li>
            <li>How long we intend to store your personal data for</li>
            <li>
              If we did not collect the data directly from you, information
              about the source
            </li>
          </ul>
          <p>
            If you believe that we hold any incomplete or inaccurate data about
            you, you have the right to ask us to correct and/or complete the
            information and we will strive to do so as quickly as possible;
            unless there is a valid reason for not doing so, at which point you
            will be notified.
          </p>

          <h2>Sharing and Disclosing Your Personal Information</h2>
          <p>
            We do not share or disclose any of your personal information without
            your consent, other than for the purposes specified in this notice
            or where there is a legal requirement. OAK SECURITY SERVICES LIMITED
            uses Graydon as a third-party to provide the below services and
            business functions; however, all processors acting on our behalf
            only process your data in accordance with instructions from us and
            comply fully with this privacy notice, the data protection laws and
            any other appropriate confidentiality and security measures.
          </p>

          <h2>GRAYDON</h2>
          <p>
            Vetting and screening all security staff in accordance with BS
            7858:2020
          </p>

          <h2>Clients</h2>
          <p>
            Provide SIA Licence Number and in some cases your nationality and
            right to your status in UK
          </p>
          <h2>Safeguarding Measures</h2>
          <p>
            COMPANY takes your privacy seriously and takes every reasonable
            measure and precaution to protect and secure your personal data. We
            work hard to protect your information from unauthorised access,
            alteration, disclosure or destruction and have several layers of
            security measures in place, including:-
          </p>
          <p>
            Restricted access, IT authentication, firewalls, anti-virus/malware
            etc.
          </p>

          <h2>How Long We Keep Your Data</h2>
          <p>
            COMPANY only ever retains personal information for as long as is
            necessary and we have strict review and retention policies in place
            to meet these obligations. We are required under UK tax law to keep
            your basic personal data (name, address, contact details) for a
            minimum of 6 years from the date of employment termination after
            which time it will be destroyed.
          </p>

          <h2>Special Categories Data</h2>
          <p>
            Owing to the products, services or treatments that we offer, COMPANY
            sometimes needs to process sensitive personal information (known as
            special category data) about your medical and physical condition, to
            review your ability to perform the required task without putting
            yourself and the others in danger.
          </p>
          <p>
            Where we rely on your consent for processing special category data,
            we will obtain your explicit consent through a signature. You can
            modify or withdraw consent at any time, which we will act on
            immediately, unless there is a legitimate or legal reason for not
            doing so.
          </p>

          <p>
            If you consent to us collecting and using your health/medical review
            annually for the purposes stated above, please sign below and print
            your name: -
          </p>
          <div className="checkbox">
            <input type="checkbox" id="agree-health"    name="categories"
          checked={formData.categories}
          onChange={handleChange}/>
            <label  htmlFor="agree-privacy">{<span style={{ marginLeft: '8px' }}>I Agree</span>} </label>

          </div>

          <h3 style={{ marginTop: "10px" }}>Consent</h3>
          <p>
            COMPANY take your privacy seriously and will only process your
            personal data with your consent and in accordance with the terms
            stated in our Privacy Notice. If you consent to us collecting and
            processing your personal data, please sign below: -
          </p>
          <p>
            I consent to COMPANY for processing my personal data as detailed in
            Privacy Notice:
          </p>
          <div className="checkbox">
            <input type="checkbox" id="agree-privacy"  name="consent"
          checked={formData.consent}
          onChange={handleChange}/>
            <label  htmlFor="agree-privacy">{<span style={{ marginLeft: '8px' }}>I Agree</span>} </label>

          </div>

          <div className="form-group" style={{ marginTop: "10px" }}>
            <label>Time:</label>
            <input type="time" name="time"
          value={formData.time}
          onChange={handleChange} />
          </div>

          <div className="form-group">
            <label>Date:</label>
            <input type="date" name="date"
          value={formData.date}
          onChange={handleChange} />
          </div>

          <button className=" btn btn-success mb-20" style={{ float: "right" }}  onClick={handleSubmit}>
            ADD
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default GDPRNotice;
