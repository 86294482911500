import React, { useState } from "react";
import "./Clients.css";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import { Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate,useLocation } from "react-router-dom";
import { InputGroup } from 'react-bootstrap';


const AddClient = () => {
  const [formData, setFormData] = useState({
    client_name: "",
    client_address: "",
    client_contact_person: "",
    client_contact_number: "",
    client_contact_fax: "",
    client_contact_email: "",
    client_invoice_terms: "",
    client_payment_terms: "",
    client_contract_start: "",
    client_contract_end: "",
    client_charge_guarding: "",
    client_charge_supervisor: "",
    client_vat_reg: "No",
    client_vat_number: "",
    client_status: "Active",
    company_branch: "1",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_store",
        formData,
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Form submitted successfully:", response.data.data);
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Client Details Added',
          text: 'New Client has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        navigate("/viewclient");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Clients', bold: false },
      { name: 'Add Clients', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>              
      </div>

      <div className="container mt-6">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left", marginTop: "20px" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Client Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="client_name"
                  style={{ width: "100%" }}
                  value={formData.client_name}
                  onChange={handleChange}
                  placeholder="Client Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="client_address"
                  style={{ width: "100%" }}
                  value={formData.client_address}
                  onChange={handleChange}
                  placeholder="Client Address"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Contact Person
                </Form.Label>
                <Form.Control
                  type="text"
                  name="client_contact_person"
                  style={{ width: "100%" }}
                  value={formData.client_contact_person}
                  onChange={handleChange}
                  placeholder="Contact Person"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                  Contact Number
                </Form.Label>
                <Form.Control
                  type="text"
                  name="client_contact_number"
                  style={{ width: "100%" }}
                  value={formData.client_contact_number}
                  onChange={handleChange}
                  placeholder="Contact Number"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Contact Fax</Form.Label>
                <Form.Control
                  type="text"
                  name="client_contact_fax"
                  style={{ width: "100%" }}
                  value={formData.client_contact_fax}
                  onChange={handleChange}
                  placeholder="Contact Fax"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Contact Email</Form.Label>
                <Form.Control
                  type="email"
                  name="client_contact_email"
                  style={{ width: "100%" }}
                  value={formData.client_contact_email}
                  onChange={handleChange}
                  placeholder="Contact Email"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left", marginTop: "20px" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Invoice Terms
                </Form.Label>
                <Form.Select
                  name="client_invoice_terms"
                  style={{ width: "100%" }}
                  value={formData.client_invoice_terms}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Invoice Term ...</option>
                  <option value="Fortnightly Invoice">
                    Fortnightly Invoice
                  </option>
                  <option value="Monthly Invoice">Monthly Invoice</option>
                  <option value="Weekly Invoice">Weekly Invoice</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Payment Terms</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={3}
                  name="client_payment_terms"
                  style={{ width: "100%" }}
                  value={formData.client_payment_terms}
                  onChange={handleChange}
                  placeholder="Payment Terms"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                  Contract Start
                </Form.Label>
                <Form.Control
                  type="date"
                  name="client_contract_start"
                  style={{ width: "100%" }}
                  value={formData.client_contract_start}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Contract End</Form.Label>
                <Form.Control
                  type="date"
                  name="client_contract_end"
                  style={{ width: "100%" }}
                  value={formData.client_contract_end}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
          <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
          Charge Rate (Guarding)
        </Form.Label>
        <InputGroup>
          <InputGroup.Text>£</InputGroup.Text>
          <Form.Control
            type="number"
            name="client_charge_guarding"
            value={formData.client_charge_guarding}
            onChange={handleChange}
            placeholder="0.00"
            step="0.01"
            min="0"
            required
          />
          <InputGroup.Text>.00</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>
          <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
          Charge Rate (Supervisor)
        </Form.Label>
        <InputGroup>
          <InputGroup.Text>£</InputGroup.Text>
          <Form.Control
            type="number"
            name="client_charge_supervisor"
            value={formData.client_charge_supervisor}
            onChange={handleChange}
            placeholder="0.00"
            step="0.01"
            min="0"
            required
          />
          <InputGroup.Text>.00</InputGroup.Text>
        </InputGroup>
      </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label={<span style={{ marginLeft: '8px' }}>VAT Registered</span>}

                  name="client_vat_reg"
                  checked={formData.client_vat_reg === "Yes"}
                  onChange={handleChange}
                />
              </Form.Group>
              {formData.client_vat_reg === "Yes" && (
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>VAT Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="client_vat_number"
                    style={{ width: "100%" }}
                    value={formData.client_vat_number}
                    onChange={handleChange}
                    placeholder="VAT Number"
                    required
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
          <Form.Group as={Row}>
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit" className="btn-primary mb-20" >
            SAVE
          </Button>
        </Col>
      </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default AddClient;
