import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FormControl,
  InputGroup,
  Table,
  Modal,
  Button,
  Form
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ClientSettings = () => {
  const [clientType, setClientType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editingClient, setEditingClient] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchClientTypes();
  }, []);

  const fetchClientTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/show_department", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        setError("Failed to fetch client types");
      }
    } catch (err) {
      setError("Error fetching client types");
    } finally {
      setLoading(false);
    }
  };

  const handleClientType = async () => {
    if (clientType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_department",
          {
            name: clientType,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data && response.data.success) {
          fetchClientTypes();
          setClientType("");
          Swal.fire({
            icon: 'success',
            title: 'Client Type Added',
            text: 'New Client type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add client type");
        }
      } catch (err) {
        setError("Error adding client type");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (client) => {
    setEditingClient(client);
    setEditModal(true);
  };

  const handleCloseEdit = () => {
    setEditModal(false);
    setEditingClient(null);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_department",
        {
          id: editingClient.id,
          name: editingClient.name,
          company_branch: editingClient.branch,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.success) {
        fetchClientTypes();
        handleCloseEdit();
        Swal.fire({
          icon: 'success',
          title: 'Department Updated',
          text: 'Department has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      } else {
        setError("Failed to update department");
      }
    } catch (err) {
      setError("Error updating department");
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteClient(id);
      }
    });
  };

  const deleteClient = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_department",
        { delete_id:id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.success) {
        fetchClientTypes();
        Swal.fire(
          'Deleted!',
          'Department has been deleted.',
          'success'
        );
      } else {
        setError("Failed to delete department");
      }
    } catch (err) {
      setError("Error deleting department");
    }
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Settings', bold: false },
      { name: 'Client Settings', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();

  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}
        </h3>  
      </div>
      <div className="page-container">
        <div className="common-container">
          <h3>Department Name</h3>
          <InputGroup className="Group">
            <FormControl
              type="text"
              placeholder="Department Name"
              value={clientType}
              onChange={(e) => setClientType(e.target.value)}
            />
            <Button variant="primary" onClick={handleClientType}>
              ADD
            </Button>
          </InputGroup>
        </div>
        <div className="table-container">
          <Table striped bordered hover>
            <thead>
              <tr style={{fontSize:'16px'}}>
                <th>ID</th>
                <th>Department Name</th>
                <th>Branch</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((client, index) => (
                <tr key={index} style={{ fontSize: '15px' }}>
                  <td>{client.id}</td>
                  <td>{client.name}</td>
                  <td>{client.branch}</td>
                  <td>
                    <Button variant="outline-primary" className="me-2" onClick={() => handleEdit(client)}>
                      <EditIcon />
                    </Button>
                    <Button variant="outline-danger" onClick={() => handleDelete(client.id)}>
                      <DeleteIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      
      <Modal show={editModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Department Name</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Department Name"
                value={editingClient?.name || ''}
                onChange={(e) => setEditingClient({...editingClient, name: e.target.value})}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}
            onClick={handleUpdate}
          >
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseEdit}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientSettings;