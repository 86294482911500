import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "../Dashboard/Dashboard";
import headerBackground from "../patterns/header-bg.png";
import {useLocation} from "react-router-dom";


const RosterSettings = () => {
  const [roster, setRoster] = useState(false);
  const [allow,setAllow] = useState(false);
  const [deleted,setDeleted] = useState(false);
  const [showWeek,setShowWeek] = useState(false);
  const [arrange,setArrange] = useState(false);

const handleAddArrange = ()=>{
  setArrange(true)
   }
   const handleCloseArrange = ()=>{
    setArrange(false)
   }
  const handleAddShowWeek = ()=>{
    setShowWeek(true)
   }
   const handleCloseShowWeek = ()=>{
    setShowWeek(false)
   }

  const handleAddDeleted = ()=>{
    setDeleted(true)
   }
   const handleCloseDeleted = ()=>{
    setDeleted(false)
   }

   const handleAddAllow = ()=>{
    setAllow(true)
   }
   const handleCloseAllow = ()=>{
    setAllow(false)
   }

  const handleAddRoster = () => {
    setRoster(true);
  };

  const handleCloseRoster = () => {
    setRoster(false);
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Settings', bold: false },
      { name: 'Roster Settings', bold: true },


    ];
  };
  
  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>         </div>
      <div className="container mt-4">
        <h3>Maximum Shift Hours Check</h3>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr style={{ fontSize: "16px" }}>
              <th>Branch Name</th>
              <th>Perform Check</th>
              <th>Maximum Shift Hours To Allow</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "15px" }}>
              <td className="text-center">Head Office</td>
              <td>Yes</td>
              <td>16</td>
              <td>
                <button className="btn-success" onClick={handleAddRoster}>
                  <i
                    className="fas fa-pen"
                    style={{
                      color: "white",
                      padding: "5px",
                      fontSize: "16px",
                    }}></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container mt-4">
        <h3>Allow Guard to work on multiple sites at once</h3>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr style={{ fontSize: "16px" }}>
              <th>Branch Name</th>
              <th>Allowed to Work</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "15px" }}>
              <td className="text-center">Head Office</td>
              <td>No</td>

              <td>
                <button className="btn-success" onClick={handleAddAllow}>
                  <i
                    className="fas fa-pen"
                    style={{
                      color: "white",
                      padding: "5px",
                      fontSize: "16px",
                    }}></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container mt-4">
        <h3>Show Deleted Shifts Button in Client Roster</h3>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr style={{ fontSize: "16px" }}>
              <th>Branch Name</th>
              <th>Show Deleted Shifts Button in Client Roster</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "15px" }}>
              <td className="text-center">Head Office</td>
              <td>Yes</td>

              <td>
                <button className="btn-success" onClick={handleAddDeleted}>
                  <i
                    className="fas fa-pen"
                    style={{
                      color: "white",
                      padding: "5px",
                      fontSize: "16px",
                    }}></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container mt-4">
        <h3>Show on Week Shifts Button in Dashboard</h3>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr style={{ fontSize: "16px" }}>
              <th>Branch Name</th>
              <th>Show on Week Shifts Button in Dashboard</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "15px" }}>
              <td className="text-center">Head Office</td>
              <td>No</td>
              <td>
                <button className="btn-success" onClick={handleAddShowWeek}>
                  <i
                    className="fas fa-pen"
                    style={{
                      color: "white",
                      padding: "5px",
                      fontSize: "16px",
                    }}></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container mt-4">
        <h3>
          Arrange Client, Sub Staff, Multiple Edit Rosters by Time Sloting
        </h3>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr style={{ fontSize: "16px" }}>
              <th>Branch Name</th>
              <th>Show Rosters in Time Slots</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "15px" }}>
              <td className="text-center">Head Office</td>
              <td>Yes</td>
              <td>

                <button className="btn-success" onClick={handleAddArrange}>
                  <i
                    className="fas fa-pen"
                    style={{
                      color: "white",
                      padding: "5px",
                      fontSize: "16px",
                    }}></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Modal show={roster} onHide={handleCloseRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Shift Hours Check Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
                Perform Check
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 mt-10">
              <Form.Label style={{ float: "left" }}>
                Maximum shift hours to allow:
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>14 Hours</option>
                <option>14 Hours</option>
                <option>15 Hours</option>
                <option>16 Hours</option>
                <option>17 Hours</option>
                <option>18 Hours</option>
                <option>19 Hours</option>
                <option>20 Hours</option>
                <option>21 Hours</option>
                <option>22 Hours</option>
                <option>23 Hours</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleted} onHide={handleCloseDeleted}>
        <Modal.Header closeButton>
          <Modal.Title>Show Deleted Shifts on Client SIte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
              Show Deleted Shifts 
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseAllow}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={allow} onHide={handleCloseAllow}>
        <Modal.Header closeButton>
          <Modal.Title>Allow Guard to Work on Multiple Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
              Allowed to Work 
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleted}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWeek} onHide={handleCloseShowWeek}>
        <Modal.Header closeButton>
          <Modal.Title>Show on Week Shifts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
              Show on Week Shifts
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseShowWeek}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={arrange} onHide={handleCloseArrange}>
        <Modal.Header closeButton>
          <Modal.Title> Arrange Client, Sub Staff, Multiple Edit Rostere</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
              Arrange Client, Sub Staff, Multiple Edit Roster
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseArrange}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RosterSettings;
