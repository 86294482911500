import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "../Dashboard/Dashboard";
import headerBackground from "../patterns/header-bg.png";
import {useLocation} from "react-router-dom";


const CallSettings = () => {
    const [call,setCall] = useState(false);

    const handleShowCall= ()=>{
        setCall(true)
    } 
    
    const handleCloseCall= ()=>{
        setCall(false)
    }
    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Settings', bold: false },
        { name: 'Set Automatic Book On / Off', bold: true },
  
  
      ];
    };
    
    const navigationPath = getNavigationPath();
    return (
      <>
        {/* <Dashboard /> */}
        <div
          className="toolbar py-2"
          id="kt_toolbar"
          style={{backgroundColor: '#BF0404' }}>
          <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
              <React.Fragment key={index}>
                <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                  {part.name}
                </span>
                {index < navigationPath.length - 1 && " / "}  
              </React.Fragment>
            ))}</h3>       </div>
    <div className="container mt-4">
      <Table striped bordered hover style={{ marginTop: "20px" }}>
        <thead>
          <tr style={{ fontSize: "16px" }}>
            <th>Branch Name</th>
            <th>Auto Book On Gap</th>
            <th>Auto Book off Gap</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "15px" }}>
            <td className="text-center">Head Office</td>
            <td>4 hours</td>
            <td>4 hours</td>
            <td>
              <button className="btn-success" onClick={handleShowCall}>
                <i
                  className="fas fa-pen"
                  style={{
                    color: "white",
                    padding: "5px",
                    fontSize: "16px",
                  }}></i>
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
      
      <Modal show={call} onHide={handleCloseCall}>
        <Modal.Header closeButton>
          <Modal.Title>Call Manager Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Branch</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Branch Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-10">
              <Form.Label style={{ float: "left" }}>
              Gap (Minutes / Hours)
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
              <option>2 Hours</option>
                <option>3 Hours</option>
                <option>4 Hours</option>
                <option>5 Hours</option>
                <option>6 Hours</option>
                <option>7 Hours</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 mt-10">
              <Form.Label style={{ float: "left" }}>
              Gap (Minutes / Hours)
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>2 Hours</option>
                <option>3 Hours</option>
                <option>4 Hours</option>
                <option>5 Hours</option>
                <option>6 Hours</option>
                <option>7 Hours</option>
               
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseCall}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
    </>
  )
}

export default CallSettings
