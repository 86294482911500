import React, { useState, useEffect } from "react";
import "./Clients.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from 'xlsx';
import { Modal,Table as BootstrapTable, Button as BootstrapButton, Form } from "react-bootstrap";
import {
  faFile,
  faEdit,
  faBars,
  faTrash,
  faUser,
  faFileAudio,
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "../Dashboard/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {
  Table as MuiTable,
    TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button as MuiButton,} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';


const EditClientForm = ({ client, onClose, onUpdate }) => {
  const [formData, setFormData] = useState(client);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>EDIT CLIENT DETAILS</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Client Name</label>
            <input
              type="text"
              name="client_name"
              value={formData.client_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="client_address"
              value={formData.client_address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Person</label>
            <input
              type="text"
              name="client_contact_person"
              value={formData.client_contact_person}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="number"
              name="client_contact_number"
              value={formData.client_contact_number}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>FAX</label>
            <input
              type="text"
              name="client_contact_fax"
              value={formData.client_contact_fax}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="client_contact_email"
              value={formData.client_contact_email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Invoice Terms</label>
            <input
              type="text"
              name="client_invoice_terms"
              value={formData.client_invoice_terms}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Payment Term</label>
            <input
              type="textarea"
              name="client_payment_terms"
              value={formData.client_payment_terms}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              name="client_contact_email"
              value={formData.client_contact_email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>End Date</label>
            <input
              type="date"
              name="client_contract_end"
              value={formData.client_contract_end}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label> Charge Rate (Guarding)</label>
            <input
              type="number"
              name="client_charge_guarding"
              value={formData.client_charge_guarding}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label> Charge Rate (Supervisor)</label>
            <input
              type="number"
              name="client_charge_supervisor"
              value={formData.client_charge_supervisor}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>VAT Number</label>
            <input
              type="number"
              name="client_vat_number"
              value={formData.client_vat_number}
              onChange={handleChange}
            />
          </div>

          <div className="form-actions">
            <button type="submit" className="btn btn-primary">
              UPDATE
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}>
              CLOSE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ViewDetails = () => {
  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        { id: id },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Client Details Response:", response.data);
      setClientData(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleEditClick = () => {
    setShowEditForm(true);
  };

  const handleCloseForm = () => {
    setShowEditForm(false);
  };

  const [showBannedGuardModal, setShowBannedGuardModal] = useState(false);
  const [showSubGuardModal, setShowSubGuardModal] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [addClientContact, setAddClientContact] = useState(false);
  const [showSiteGroupModal, setShowSiteGroupModal] = useState(false);

  const haldleShowSiteGroupModal = () => setShowSiteGroupModal(true);
  const handleCloseSiteGroupModal = () => setShowSiteGroupModal(false);

  const handleAddClientContact = () => setAddClientContact(true);
  const handleCloseAddClientContact = () => setAddClientContact(false);

  const handleShowContactDetails = () => setShowContactDetails(true);
  const handleCloseContactDetails = () => setShowContactDetails(false);

  const handleShowSubGuardModal = () => setShowSubGuardModal(true);
  const handleCloseSubGuardModal = () => setShowSubGuardModal(false);

  const handleShowBannedGuardModal = () => setShowBannedGuardModal(true);
  const handleCloseBannedGuardModal = () => setShowBannedGuardModal(false);

  const [showEditForm, setShowEditForm] = useState(false);

  const handleUpdateClient = async (updatedData) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_store",
        updatedData,
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Update response:", response);
      fetchClientData();
      setShowEditForm(false);
    } catch (error) {
      console.error("Error updating client data:", error);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const breadcrumbPaths = [
      { name: "Clients", bold: false },
      { name: "View Clients", path: "/viewclient", bold: false },
      { name: "Clients Details", bold: false },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1,
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };

  const [formClientData, setFormClientData] = useState({
    client_name: "",
    department_name: "",
    contact_name: "",
    contact_number: "",
    fax: "",
    email: "",
    // is_default: false,
  });
  const [contacts, setContacts] = useState([]);
  const [filterContacts,setFilterContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);


  const exportToContactExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client contacts");
    XLSX.writeFile(workbook, "Client contacts_data.xlsx");
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormClientData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [contactsCount, setContactsCount] = useState(0);
  const [siteGroupsCount, setSiteGroupsCount] = useState(0);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_client_contact",{ id: id },
        
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("fetch Client Contact:", response);
      if (response.data.success) {
        setContacts(response.data.data);
        setFilterContacts(response.data.data);
        setContactsCount(response.data.data.length);

      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    const filtered = contacts.filter((contact) =>
      Object.values(contact).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilterContacts(filtered);
    setCurrentPage(0);
  }, [searchTerm, contacts]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/store_client_contact",
        formClientData,
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Add ClientContact:", response);
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Client Contact Details Added',
          text: 'New Client Contact has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        handleAddClientContact(false);
        fetchContacts();
        // Reset form data
        setFormClientData({
          client_id: "1",
          client_name: "",
          department_name: "",
          contact_name: "",
          contact_number: "",
          fax: "",
          email: "",
        });
      }
    } catch (error) {
      console.error("Error adding contact:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add contact',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    }
  };

  // const filteredContacts = contacts.filter(contact =>
  //   contact.contact_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   contact.department_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  // const [addSiteGroup, setAddSiteGroup] = useState(false);
  const [formSiteData, setFormSiteData] = useState({
    client_name: "",
    client_rate_guard: "",
    client_rate_supervisor: "",
    site_group_name: "",
    group_rate_guarding: "",
    group_rate_supervisor: "",
    pay_rate_guarding: "",
    pay_rate_supervisor:""
  });

  const [siteGroup, setSiteGroup] = useState([]);
  const [filterSiteGroup,setFilterSiteGroup] = useState([]);
  const [searchSiteTerm, setSearchSiteTerm] = useState("");
  const [sitePerPage, setSitePerPage] = useState(10);
  const [currentSite, setCurrentSite] = useState(1);

  const exportToSiteExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(siteGroup);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SiteGroup");
    XLSX.writeFile(workbook, "SiteGroup_data.xlsx");
  }

  const handleSiteInputChange = (e) => {
    const { name, value, type,checked } = e.target;
    setFormSiteData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };



  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_site_group",
        {id:id},

        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("fetch Site Group:", response.data.data);
      if (response.data.success) {
        setSiteGroup(response.data.data);
        setFilterSiteGroup(response.data.data);
        setSiteGroupsCount(response.data.data.length);
      }
      showSiteGroupModal(false)
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };

  useEffect(() => {
    const filtered = siteGroup.filter((site) =>
      Object.values(site).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilterSiteGroup(filtered);
    setCurrentPage(0);
  }, [searchTerm, siteGroup]);

  const handleSiteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/store_site_group",
        formSiteData, 
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Add SiteGroup:", response);
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Site Group Details Added',
          text: 'New Site Group has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });      
        haldleShowSiteGroupModal(false);
        fetchSites(); 
      }
    } catch (error) {
      console.error("Error adding Site:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add Site',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    }
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}>
        <h3 className="text-white" style={{ fontSize: "15px" }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <Link
                to={part.path}
                onClick={(e) => handleClick(part.path, e)}
                style={{
                  fontWeight: part.bold ? "bold" : "normal",
                  color: "white",
                  fontSize: part.bold ? "18px" : "15px",
                  textDecoration: "none",
                }}>
                {part.name}
              </Link>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 style={{ fontSize: "20px", marginLeft: "20px" }}>
                  {clientData.client_name}
                </h4>
                <button className="Hawks-btn" onClick={handleEditClick}>
                  EDIT
                </button>
              </div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Client Name</th>
                      <td>{clientData.client_name}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Address</th>
                      <td>{clientData.client_address}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contact Person</th>
                      <td>{clientData.client_contact_person}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contact Number</th>
                      <td>{clientData.client_contact_number}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>FAX</th>
                      <td>{clientData.client_contact_fax}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Email</th>
                      <td>{clientData.client_contact_email}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Invoice Terms</th>
                      <td>{clientData.client_invoice_terms}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Payment Terms</th>
                      <td>{clientData.client_payment_terms}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contract Start</th>
                      <td>{clientData.client_contract_start}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Contract End</th>
                      <td>{clientData.client_contract_end}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Charge Rate (Guarding)</th>
                      <td>{clientData.client_charge_guarding}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>Charge Rate (Supervisor)</th>
                      <td>{clientData.client_charge_supervisor}</td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <th style={{fontWeight:'bold',fontSize:'17px'}}>VAT Number</th>
                      <td>{clientData.client_vat_number}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {[
             { title: "CLIENT CONTACTS", action: "ADD NEW CONTACT", count: contactsCount },
             { title: "CLIENT SITE GROUPS", action: "ADD NEW SITE GROUP", count: siteGroupsCount },
            ].map((item, index) => (
              <div className={`card ${index === 0 ? "mb-4" : ""}`} key={index}>
                <div className="card-body" style={{ marginTop: 60 }}>
                  <p
                    className="card-text"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "red",
                    }}>
                    {item.count}
                  </p>
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                    {item.title}
                  </h5>
                  <button
                    style={{ marginLeft: "20px" }}
                    className="btn btn-success"
                    onClick={() => {
                      if (item.title === "CLIENT SITE GROUPS") {
                        haldleShowSiteGroupModal();
                      } else if (item.title === "CLIENT CONTACTS") {
                        handleAddClientContact();
                      }
                    }}>
                    {item.action}
                  </button>
                </div>
              </div>
            ))}
          </div>

      <Modal show={showSiteGroupModal} onHide={handleCloseSiteGroupModal}>
            <Modal.Header closeButton>
              <Modal.Title>ADD SITE GROUP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form  onSubmit={handleSiteSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="client_name"
                    placeholder="Client Name"
                    value={formSiteData.client_name}
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Client Charge Rate (Guard)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="client_rate_guard"
                    placeholder="Client Charge rate (Guard)"
                    value={formSiteData.client_rate_guard}
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Client Charge Rate (Supervisor)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="client_rate_supervisor"
                    placeholder="Client Rate Supervisor"
                    value={formSiteData.client_rate_supervisor}
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Site Group Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="site_group_name"
                    value={formSiteData.site_group_name}
                    placeholder="Site Group Name"
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Site Group rate (Guarding)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="group_rate_guarding"
                    value={formSiteData.group_rate_guarding}
                    placeholder="Site Group Rate for Guards"
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  Site Group rate (Supervisor)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="group_rate_supervisor"
                    value={formSiteData.group_rate_supervisor}
                    placeholder="Site Group Rate for Supervisors"
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Site Payable Rate (Guarding)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="pay_rate_guarding"
                    value={formSiteData.pay_rate_guarding}
                    placeholder="Site Payable Rate for Guards"
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  Site Payable Rate (Supervisor)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="pay_rate_supervisor"
                    value={formSiteData.pay_rate_supervisor}
                    placeholder="Site Payable Rate for Supervisors"
                    style={{ width: "100%" }}
                    onChange={handleSiteInputChange}

                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton variant="secondary" onClick={handleCloseSiteGroupModal}>
                CLOSE
              </BootstrapButton>
              <BootstrapButton variant="primary" onClick={handleSiteSubmit}>ADD SITE GROUP
</BootstrapButton>            </Modal.Footer>
          </Modal>

          <Modal show={addClientContact} onHide={handleCloseAddClientContact}>
            <Modal.Header closeButton>
              <Modal.Title>Add Client Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    Client Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="client_name"
                    value={formClientData.client_name}
                    style={{ width: "100%" }}
                    onChange={handleInputChange}
placeholder="Client Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    Department Name
                  </Form.Label>
                  <Form.Select
                    name="department_name"
                    value={formClientData.department_name}
                    onChange={handleInputChange}
                    style={{ width: "100%" }}>
                    <option value="">Select Department ...</option>
                    <option value="Finance">Finance</option>
                    <option value="Operations">Operations</option>
                    <option value="Manager">Manager</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    value={formClientData.contact_name}
                    onChange={handleInputChange}
                    placeholder="Contact Person Name"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    value={formClientData.contact_number}
                    onChange={handleInputChange}
                    placeholder="Contact Person Number"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    FAX
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fax"
                    value={formClientData.fax}
                    onChange={handleInputChange}
                    placeholder="FAX"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left", marginLeft: 5 }}>
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formClientData.email}
                    onChange={handleInputChange}
                    placeholder="example@mail.com"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    name="is_default"
                    checked={formClientData.is_default}
                    onChange={handleInputChange}
                    id="defaultContact"
                    label="Make Default Contact"
                  />
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton variant="secondary" onClick={handleCloseAddClientContact}>
                CLOSE
              </BootstrapButton>
              <BootstrapButton onClick={handleSubmit}>Save</BootstrapButton>                </Modal.Footer>
          </Modal>
        </div>
      </div>

      {showEditForm && (
        <EditClientForm
          client={clientData}
          onClose={handleCloseForm}
          onUpdate={handleUpdateClient}
        />
      )}

      {/* <div className="container-fluid mt-4">
        <h2 style={{ paddingTop: 20 }}>SITE GROUP</h2>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
                <Button variant="primary" className="m-0">
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={searchSiteTerm}
                  onChange={(e) => setSearchSiteTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Client Name</th>
                    <th>Client Rate Guard</th>
                    <th>Client Rate Spuervisor</th>
                    <th>Site Name</th>
                    <th>Billable Guard Rate</th>
                    <th>Billable Supervisor Rate</th>
                    <th>Payable Guard Rate</th>
                    <th>Payable Supervisor Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {siteGroup.map((sites, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{sites.client_name}</td>
                        <td>{sites.client_rate_guard}</td>
                        <td>{sites.client_rate_supervisor}</td>
                        <td>{sites.site_group_name}</td>
                        <td>{sites.group_rate_guarding}</td>
                        <td>{sites.group_rate_supervisor}</td>
                        <td>{sites.pay_rate_guarding}</td>
                        <td>{sites.pay_rate_supervisor}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>  */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
        <h2 style={{ paddingTop: 20 }}>Site Group</h2>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToSiteExcel}
            >
              Export to Excel
            </MuiButton>
          </Box>
          <TableContainer>
            <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Rate Guard</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Rate Supervisor</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Billable Guard Rate</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Billable Supervisor Rate	</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Payable Guard Rate</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Payable Supervisor Rate</TableCell>

                </TableRow>
              </TableHead>
              <TableBody >
               {filterSiteGroup
                  .slice(currentSite * sitePerPage, currentSite * sitePerPage + sitePerPage)
                  .map((site, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell>{currentSite * sitePerPage + index + 1}</TableCell>
                    <TableCell>{site.client_name}</TableCell>
                    <TableCell>{site.client_rate_guard}</TableCell>
                    <TableCell>{site.client_rate_supervisor}</TableCell>
                    <TableCell>{site.site_group_name}</TableCell>
                    <TableCell>{site.group_rate_guarding}</TableCell>
                    <TableCell>{site.group_rate_supervisor}</TableCell>
                    <TableCell>{site.pay_rate_guarding}</TableCell>
                    <TableCell>{site.pay_rate_supervisor}</TableCell>

                    {/* <TableCell > */}
  {/* <IconButton   
    component={Link} 
    to="/viewdetails" 
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton> */}


  {/* <IconButton 
    onClick={() => handleDelete(client.id)}    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton> */}
{/* </TableCell> */}

                  </TableRow>
                ))} 
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filterSiteGroup.length}
            rowsPerPage={sitePerPage}
            page={currentSite}
            onPageChange={(event, newPage) => setCurrentSite(newPage)}
            onRowsPerPageChange={(event) => {
              setSitePerPage(parseInt(event.target.value, 10));
              setCurrentSite(0);
            }}
          />
        </Paper>
      </Box>

      {/* <div className="container-fluid mt-4">
        <h2 style={{ paddingTop: 20 }}>CLIENT CONTACTS</h2>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <BootstrapButton variant="primary" className="m-0">
                  Search
                </BootstrapButton>
                <input
                  type="text"
                  className="form-control ms-2"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Department</th>
                    <th>Fax</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
  {contacts.length > 0 ? (
    contacts.map((contact, index) => (
    <tr key={index}>
      <td>{index+1}</td>
      <td>{contact.contact_name}</td>
      <td>{contact.contact_number}</td>
      <td>{contact.email}</td>
      <td>{contact.department_name}</td>
      <td>{contact.fax}</td>
      <td>
        <BootstrapButton variant="info" size="sm" className="me-2">
          Edit
        </BootstrapButton>
        <BootstrapButton variant="danger" size="sm">
          Delete
        </BootstrapButton>
      </td>
      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No clients found</td>
                    </tr>
                  )}
</tbody>

              </table>
            </div>
          </div>
        </div>
      </div> */}


      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
        <h2 style={{ paddingTop: 20 }}>Client Contact</h2>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToContactExcel}
            >
              Export to Excel
            </MuiButton>
          </Box>
          <TableContainer>
            <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Person</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Department</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Fax</TableCell>
                  {/* <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody >
                {filterContacts
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((client, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{client.client_name}</TableCell>
                    <TableCell >{client.contact_number}</TableCell>
                    <TableCell >{client.contact_name}</TableCell>
                    <TableCell >{client.email}</TableCell>
                    <TableCell >{client.department_name}</TableCell>
                    <TableCell >{client.fax}</TableCell>

                    {/* <TableCell > */}
  {/* <IconButton 
    component={Link} 
    to="/viewdetails" 
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton> */}


  {/* <IconButton 
    onClick={() => handleDelete(client.id)}    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton> */}
{/* </TableCell> */}

                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filterContacts.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
      <Modal show={showContactDetails} onHide={handleCloseContactDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
              <Form.Control type="text" style={{ width: "100%" }} value="SGS" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Department Name</Form.Label>
              <Form.Control
                type="text"
                style={{ width: "100%" }}
                value="Manger"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Contact Name</Form.Label>
              <Form.Control
                type="text"
                style={{ width: "100%" }}
                value="henry"
              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ display: "block" }}>
              <Form.Label style={{ float: "left" }}>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value="343345484865"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>FAX</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value="56546675676"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value="one@gmail.com"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="defaultContact"
                label="Make Default Contact"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={handleCloseContactDetails}>
            CLOSE
          </BootstrapButton>
          <BootstrapButton variant="primary">Update</BootstrapButton>
        </Modal.Footer>
      </Modal>

      <Modal show={showBannedGuardModal} onHide={handleCloseBannedGuardModal}>
        <Modal.Header closeButton>
          <Modal.Title>ADD GUARD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Employee</Form.Label>
              <Form.Select>
                <option>Select employee...</option>
                <option>Lione</option>
                <option>Mark H</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" style={{ display: "block" }}>
              <Form.Label style={{ float: "left" }}>Banned Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Banned Date"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Event ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Event ID"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={handleCloseBannedGuardModal}>
            CLOSE
          </BootstrapButton>
          <BootstrapButton variant="primary">ADD</BootstrapButton>
        </Modal.Footer>
      </Modal>

      {/* <div className="container-fluid mt-4">
        <h2 style={{ paddingTop: 20 }}>Banned Guards (Employee)</h2>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select className="form-select d-inline-block w-auto mx-2" 
                  >
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">

              <Button
              onClick={handleShowBannedGuardModal}
                  variant="primary"
                  className="m-0"
              >                  
                  Add Guard
                </Button>
             
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
              <th>S.No</th>
              <th> Banned Guard</th>
              <th>Banned Date</th>
              <th>Event ID</th>
              <th>Actions</th>
            </tr>
                 
                </thead>
            
              </table>
            </div>

          </div>
        </div>
      </div> */}

      <Modal show={showSubGuardModal} onHide={handleCloseSubGuardModal}>
        <Modal.Header closeButton>
          <Modal.Title>ADD GUARD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Subcontractors</Form.Label>
              <Form.Select>
                <option>Select Subcontractor ...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Sub Employees</Form.Label>
              <Form.Select>
                <Form.Control type="text" />
                <option>Select Sub Employee ...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" style={{ display: "block" }}>
              <Form.Label style={{ float: "left" }}>Banned Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Banned Date"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Event ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Event ID"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={handleCloseSubGuardModal}>
            CLOSE
          </BootstrapButton>
          <BootstrapButton variant="primary">ADD</BootstrapButton>
        </Modal.Footer>
      </Modal>

      {/* 
        <div className="container-fluid mt-4">
        <h2 style={{ paddingTop: 20 }}>Banned Guards (Subcontractor)</h2>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select className="form-select d-inline-block w-auto mx-2" 
                  >
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">

              <Button
            onClick={handleShowSubGuardModal}
                  variant="primary"
                  className="m-0"
              >                  
                  Add Guard
                </Button>
             
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                <tr>
              <th>S.No</th>
              <th> Banned Guard</th>
              <th>Banned Date</th>
              <th>Event ID</th>
              <th>Actions</th>
            </tr>
                </thead>
            
              </table>
            </div>

          </div>
        </div>
      </div> */}
    </>
  );
};

export default ViewDetails;
