import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import { useLocation } from "react-router-dom";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton, 
  Typography, 
  Box
} from '@mui/material';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  '&.day-cell': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const MonthlyCalendar = () => {
  const location = useLocation();
  const [currentDate, setCurrentDate] = useState(new Date());

  const getNavigationPath = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    return [
      { name: "Employee", bold: false },
      { name: "Payroll", bold: false },
      { name: "Holiday", bold: false },
      { name: "Holidays Monthly Calendar", bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const firstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const renderCalendar = () => {
    const days = daysInMonth(currentDate);
    const firstDay = firstDayOfMonth(currentDate);
    const weeks = Math.ceil((days + firstDay) / 7);

    let calendarDays = [];
    let day = 1;

    for (let i = 0; i < weeks; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDay) || day > days) {
          week.push(<StyledTableCell key={`empty-${i}-${j}`} />);
        } else {
          week.push(
            <StyledTableCell key={`day-${day}`} className="day-cell">
              {day}
            </StyledTableCell>
          );
          day++;
        }
      }
      calendarDays.push(<TableRow key={`week-${i}`}>{week}</TableRow>);
    }

    return calendarDays;
  };

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}
      >
        <h3 className="text-white" style={{ fontSize: "15px", color: "white" }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span
                style={{
                  fontWeight: part.bold ? "bold" : "normal",
                  color: "white",
                  fontSize: part.bold ? "18px" : "15px",
                }}
              >
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={prevMonth} color="primary">
          <ChevronLeft />
        </IconButton>
        <Typography variant="h5" component="h2">
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </Typography>
        <IconButton onClick={nextMonth} color="primary">
          <ChevronRight />
        </IconButton>
      </Box>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                <StyledTableCell key={day}>{day}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCalendar()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </>
  );
};

export default MonthlyCalendar;










{/* <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <Button style={{padding:'8px'}} variant="info" className="me-2"><i className="fas fa-clock"></i></Button>
            <Button style={{padding:'8px'}} variant="success" className="me-2"><i className="fas fa-calendar"></i></Button>
            <Button style={{padding:'8px'}} variant="info"><i className="fas fa-redo"></i></Button>
          </div>
          <h2>{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</h2>
          <div>
            <Form.Control type="text" placeholder="Select Date" className="d-inline-block me-2" style={{width: 'auto'}} />
            <Button style={{padding:'8px'}} variant="primary"><i className="fas fa-search"></i></Button>
          </div>
        </div> */}