import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table } from "react-bootstrap";

const ShowPreviousEmployment = () => {
  const [employmentData, setEmploymentData] = useState([]);

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const fetchEmploymentData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/show_pervious_employment',
         {employee_id:"1"}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('API response data:', response.data); // Add this line
      setEmploymentData(response.data.data);
    } catch (error) {
      console.error('Error fetching employment data:', error);
    }
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;

};

    return (
        <>
          <h2>Previous Employment</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Company Name</th>
                <th>Job Description</th>
                <th>Address</th>
                <th>Reason for Leaving</th>
                <th>Contact Name</th>
                <th>Contact Number</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(employmentData) && employmentData.length > 0 ? (
                employmentData.map((job, index) => (
                  <tr key={index}>
                    <td>{job.job_title}</td>
                    <td>{job.company_name}</td>
                    <td>{job.job_description}</td>
                    <td>{job.address}</td>
                    <td>{job.reason_of_leaving}</td>
                    <td>{job.contact_name}</td>
                    <td>{job.contact_number}</td>
                    <td>{formatDate(job.start_date)}</td>
                    <td>{formatDate(job.end_date)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No employment data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
        
};

export default ShowPreviousEmployment;