import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import Dashboard from "../../Dashboard/Dashboard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  Chip,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

const ViewPayment = () => {
  const [employeeData, setEmployeeData] = useState({
    returnAmount: "",
    dueDate: "",
    comments: "",
  });

  const [totalReturnedAmount, setTotalReturnedAmount] = useState(0);
  const [remainingDueAmount, setRemainingDueAmount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [originalAmount, setOriginalAmount] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    if (id) {
      fetchPayments();
    }
  }, [id]);

  const fetchPayments = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/return_advance",
        { id: id },
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Advance Payments Data:",response)
      if (response.data.success) {
        const advanceData = response.data.data[0];
        const originalAmount = parseFloat(advanceData.adv_amount);

        const returnedTotal = advanceData.ReturnData.reduce(
          (sum, returnItem) => sum + parseFloat(returnItem.return_amount),
          0
        );
        setTotalReturnedAmount(returnedTotal);

        const remaining = originalAmount - returnedTotal;
        setRemainingDueAmount(remaining);

        const formattedPayments = advanceData.ReturnData.map(returnData => ({
          amount: parseFloat(returnData.return_amount),
          date: returnData.due_date,
          comments: returnData.comments,
          status: getPaymentStatus(parseFloat(advanceData.adv_amount), returnedTotal).label
        }));
        setPayments(formattedPayments);
        console.log("Payment fetch data:",formattedPayments)
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch payment details'
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getPaymentStatus = (originalAmount, returnedAmount) => {
    if (returnedAmount >= originalAmount) return { label: "Completed", color: "success" };
    if (returnedAmount > 0) return { label: "Payment", color: "warning" };
    return { label: "Pending", color: "error" };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const returnAmount = parseFloat(employeeData.returnAmount);
    if (returnAmount > remainingDueAmount) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Amount',
        text: `Return amount cannot be greater than remaining due amount (£${remainingDueAmount})`
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/save_return_advance", 
        {
          ...employeeData,
          advance_id: id
        }, 
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Return payment saved successfully'
        });
        setTotalAmount(prevTotal => prevTotal - returnAmount);
        
        // Add new payment to the list
        setPayments(prevPayments => [
          ...prevPayments,
          {
            amount: returnAmount,
            date: employeeData.dueDate,
            comments: employeeData.comments,
            status: "Return"
          }
        ]);
        
        fetchPayments();
        setEmployeeData({
          returnAmount: "",
          dueDate: "",
          comments: "",
        });
      }
    } catch (error) {
      console.error("Error saving return payment:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save return payment'
      });
    }
  };

  const getNavigationPath = () => {
    return [
      { name: 'Accounts', bold: false },
      { name: 'Payroll', bold: false },
      { name: 'Advance Payments', path: '/advancepayment', bold: false },
      { name: 'Advance Payments Management', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  return (
    <>
      {/* <Dashboard /> */}
      <div className="toolbar py-3" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize: '15px'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              {part.path ? (
                <Link
                  to={part.path}
                  style={{
                    fontWeight: part.bold ? 'bold' : 'normal',
                    color: 'white',
                    fontSize: part.bold ? '18px' : '15px',
                    textDecoration: 'none'
                  }}
                >
                  {part.name}
                </Link>
              ) : (
                <span style={{
                  fontWeight: part.bold ? 'bold' : 'normal',
                  fontSize: part.bold ? '18px' : '15px'
                }}>
                  {part.name}
                </span>
              )}
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>

      <div className="container mt-5">
        <Row className="justify-content-center">
          <Col md={15}>
            <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className="text-end">
                  Total Amount
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="number"
                    value={remainingDueAmount.toFixed(2)}
                    readOnly
                  />
                </Col>
              </Form.Group>
              {Object.entries(employeeData).map(([key, value]) => (
                <Form.Group as={Row} className="mb-3" key={key}>
                  <Form.Label column sm={3} className="text-end">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Form.Label>
                  <Col sm={9}>
                    {key === "comments" ? (
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Form.Control
                        type={key === "dueDate" ? "date" : key === "returnAmount" ? "number" : "text"}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                      />
                    )}
                  </Col>
                </Form.Group>
              ))}
              
              <Row className="justify-content-center mt-4">
                <Col md={8} className="text-center">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "green",
                      border: "0",
                      padding: "10px 100px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      marginRight: "200px",
                    }}
                  >
                    SAVE
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Box sx={{ width: '100%', mt: 5 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="payments table">
              <TableHead>
                <TableRow>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Return Amount</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                    <Chip 
                        label={payment.status}
                        color={payment.status === "Payment" ? "success" : "warning"}
                      />
                    </TableCell>
                    <TableCell>{payment.date}</TableCell>
                    <TableCell>£{payment.amount.toFixed(2)}</TableCell>
                    <TableCell>{payment.comments}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {/* <Typography variant="h6" component="div">
              Total Returned: 
              <span style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                marginLeft: '10px'
              }}>
                £{totalReturnedAmount.toFixed(2)}
              </span>
            </Typography> */}
            <Typography variant="h6" component="div">
              Remaining Due: 
              <span style={{
                backgroundColor: '#1976d2',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                marginLeft: '10px'
              }}>
                £{remainingDueAmount.toFixed(2)}
              </span>
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default ViewPayment;