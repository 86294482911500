import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import Swal from 'sweetalert2';
import Dashboard from "../../Dashboard/Dashboard";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const HolidayRequest = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvent, setFilteredEvent] = useState([]);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);
  const [showSearchEvent, setShowSearchEvent] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [connectToEmployeeOrSubcon, setConnectToEmployeeOrSubcon] =
    useState(false);
  const [connectToSite, setConnectToSite] = useState(false);
  const [eventsPerPage, setEventsPerPage] = useState(10);
  const [totalEvents, setTotalEvents] = useState(events);
  const initialEventState = {
    employee: '',
    from_date: '',
    to_date: '',
    reason: '',
    leave_type: '',
    comments: '',
  };
  const [newEvent, setNewEvent] = useState(initialEventState);
  const [editingEvent, setEditingEvent] = useState(null);
  const [editEvents,setEditEvents] = useState(false);
  const [searchParams, setSearchParams] = useState({
    employee: '',
    from_date: '',
    to_date: '',
    reason: '',
    leave_type: '',
    comments: '',
  });

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_holiday_request",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      if (response.data && response.data.success) {
        setEvents(response.data.data);
        setTotalEvents(response.data.data);
        setFilteredEvent(response.data.data);
      } else {
        setError("No event data found");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Error fetching events: " + error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleSearchInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setSearchParams({ ...searchParams, [name]: value });
  // };

  useEffect(() => {
    const filtered = events.filter((event) =>
      Object.values(event).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredEvent(filtered);
    setCurrentPage(0);
  }, [searchTerm, events]);

  // const handleSearch = () => {
  //   if (isSearching) {
  //     setTotalEvents(events);
  //     setSearchTerm("");
  //     setIsSearching(false);
  //     setCurrentPage(1);
  //   } else {
  //     const filtered = events.filter((event) =>
  //       Object.values(event).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //     setTotalEvents(filtered);
  //     setIsSearching(true);
  //     setCurrentPage(1);
  //   }
  // };

 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEventsPerPageChange = (e) => {
    setEventsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // const handleSearch = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://srltd.megworld.in/api/event_show",
  //       searchParams,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setEvents(response.data.data);
  //     handleCloseSearchEvent();
  //   } catch (error) {
  //     console.error("Error searching events:", error);
  //   }
  // };

  const handleShowSearchEvent = () => setShowSearchEvent(true);
  const handleCloseSearchEvent = () => setShowSearchEvent(false);

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prevEvent => ({
      ...prevEvent,
      [name]: value
    }));
  };
  
  const handleModalCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewEvent(prevEvent => ({
      ...prevEvent,
      [name]: checked ? 1 : 0
    }));
  };

 

    const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventDetail = (event) => {
    setSelectedEvent(event);
    setShowEventDetail(true);
  };

  const handleCloseEventDetail = () => {
    setShowEventDetail(false);
    setSelectedEvent(null);
  };

  const handleAddEvent = async () => {
    console.log(newEvent);
    setNewEvent(initialEventState);
    if (
      !newEvent.employee ||
      !newEvent.reason ||
      !newEvent.leave_type ||
      !newEvent.comments
    ) {
      alert("Please fill in all required fields");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/holiday_request",
        newEvent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Holiday Added',
          text: 'New Holiday has been added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        fetchEvents();
        handleCloseAddEventDetail();
        setNewEvent(response.data.data);
      } else {
        alert("Failed to Add Holiday: " + response.data.message);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      alert("An error occurred while adding the event. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const indexOfLastEntry = currentPage * eventsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - eventsPerPage;
  const currentEntries = totalEvents.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

// const [site,setSite] = useState([])

// const [employee, setEmployee] = useState([]);

// useEffect(() => {
//   fetchEmployees();
// }, []);

// const fetchEmployees = async () => {
//   try {
//     const response = await axios.post(
//       "https://srltd.megworld.in/api/employee_show",
//       {},
//       {
//         headers: {
//           Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
//         },
//       }
//     );
//     console.log("Response:", response.data);
//     if (response.data && response.data.success) {
//       const employeeData = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
//       const options = employeeData.map(employee => ({
//         value: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`,
//         label: `${employee.employee_forename}${employee.employee_middname ? ` ${employee.employee_middname}` : ''}${employee.employee_surname ? ` ${employee.employee_surname}` : ''}`
//       }));
      
//       setEmployee(options);
//       console.log("Employee Options:", options);
//     } else {
//       console.error('Failed to fetch employees');
//     }
//   } catch (error) {
//     console.error('Error fetching employees:', error);
//   }
// };
 
//   useEffect(() => {
//     fetchSiteTypes();
//   }, []);

//   const fetchSiteTypes = async (id) => {
//     try {
//       const response = await axios.post(
//         "https://srltd.megworld.in/api/site_show",
//         {id:id},
//         {
//           headers: {
//             Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
//           },
//         }
//       );
//       console.log("Response:", response.data);
//       if (response.data && response.data.success) {
//         const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
//         const Siteoptions = successData.map(type => ({
//           value: type.site_name,
//           label: type.site_name,}
//       ));
   
//       setSite(Siteoptions);
//         console.log("Employee Siteoptions:", Siteoptions);    

//       } else {
//         console.error('Failed to fetch Site types');
//       }
//     } catch (error) {
//       console.error('Error fetching Site types:', error);
//     }
//   };
  
// const handleSiaTypeChange = (selectedOption, { name }) => {
//   setNewEvent(prevEvent => ({
//     ...prevEvent,
//     [name]: selectedOption ? selectedOption.value : ''
//   }));
// };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setNewEvent({
      id: event.id,
      employee: event.employee || '',
      from_date: event.from_date || '',
      to_date: event.to_date || '',
      reason: event.reason || '',
      leave_type: event.leave_type || 0,
      comments: event.comments || '',
    });
    setEditEvents(true);
  };

  
const handleEditSubmit = async () => {
  try {
    setIsLoading(true);
    const response = await axios.post(
      "https://srltd.megworld.in/api/holiday_request",
      newEvent,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data && response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Holiday Updated',
        text: 'Holiday has been updated successfully.',
      });
      fetchEvents(); // Refresh the events list
      setEditEvents(false);
    } else {
      throw new Error(response.data.message || "Failed to update Holiday");
    }
  } catch (error) {
    console.error("Update Holiday Error:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message || 'An error occurred while updating the Holiday.'
    });
  } finally {
    setIsLoading(false);
  }
};
  
const location = useLocation();

const getNavigationPath = () => {
  const pathParts = location.pathname.split('/').filter(Boolean);
  return [
    { name: 'Employee', bold: false },
    { name: 'Payroll', bold: false },
    { name: 'Holiday', bold: false },
    { name: 'Current Holidays Request ', bold: true },
  ];
};

const navigationPath = getNavigationPath();

const exportToExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(events);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Holiday");
  XLSX.writeFile(workbook, "Holiday_RequestData.xlsx");
}
return (
  <>
    <Dashboard/>
    <div
      className="toolbar py-2 d-flex justify-content-between align-items-center"
      id="kt_toolbar"
      style={{backgroundColor: '#BF0404' }}>
      <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
          <React.Fragment key={index}>
            <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
              {part.name}
            </span>
            {index < navigationPath.length - 1 && " / "}  
          </React.Fragment>
        ))}</h3>               
          <div  className="d-flex" style={{marginRight:'90px'}}>
          

            <Button
  variant="contained"
  color="primary"
  onClick={handleShowAddEventDetail}
  sx={{ padding: '8px', marginLeft: '200px' }}
>
  Add Holiday
</Button>
           
          </div>
      </div>

      
      <Modal show={editEvents} onHide={() => setEditEvents(false)}>
  <Modal.Header closeButton>
    <Modal.Title style={{ float: "left" }}>Edit Holiday</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Employee</Form.Label>
        <Form.Control
          type="text"
          name="employee"
          value={newEvent?.employee || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>From Date</Form.Label>
        <Form.Control
          type="date"
          name="from_date"
          value={newEvent?.from_date || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>To Date</Form.Label>
        <Form.Control
          type="date"
          name="to_date"
          value={newEvent?.to_date || ''}
          onChange={handleInputChange}
        
      
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Reason</Form.Label>
        <Form.Control
          as="textarea"
          name="reason"
          value={newEvent?.reason || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Leave Type</Form.Label>
        <Form.Control
        type="text"
          name="leave_type"
          value={newEvent?.leave_type || ''}
          onChange={handleInputChange}
        />
      </Form.Group> <Form.Group className="mb-3">
        <Form.Label style={{ float: "left" }}>Comments</Form.Label>
        <Form.Control
          as="textarea"
          name="comments"
          value={newEvent?.comments || ''}
          onChange={handleInputChange}
        />
      </Form.Group>
     
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setEditEvents(false)}>
      Close
    </Button>
    <Button  variant="contained"
  color="primary" onClick={handleEditSubmit}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal>

      


      <Modal show={showAddEventDetail} onHide={handleCloseAddEventDetail}>
      <Modal.Header closeButton>
        <Modal.Title>Add Holiday</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Employee</Form.Label>
            <Form.Control
              type="text"
              name="employee"
              placeholder="Employee"
              // value={newEvent.event_date}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>From Date</Form.Label>
            <Form.Control
              type="date"
              name="from_date"
              placeholder="From date"

              // value={newEvent.event_time}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>To Date</Form.Label>
            <Form.Control
              type="date"
              name="to_date"
              placeholder="To date"

              // value={newEvent.event_type}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            
           
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Reason</Form.Label>
            <Form.Control
              as="textarea"
              name="reason"
              placeholder="Reason"

              // value={newEvent.event_details}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Leave Type</Form.Label>
            <Form.Control
              type="text"
              name="leave_type"
              placeholder="Leave Type"

              // value={newEvent.event_details}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Comments</Form.Label>
            <Form.Control
              as="textarea"
              name="comments"
              placeholder="Comments"

              // value={newEvent.event_details}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </Form.Group>
       
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button  variant="contained"
  color="primary" onClick={handleAddEvent}>
          Add Holiday
        </Button>
        <Button variant="secondary" onClick={handleCloseAddEventDetail}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={eventsPerPage}
                  onChange={(e) => {
                    handleEventsPerPageChange(e);
                  }}>
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
                <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={isSearching}
                />
              </div>
            </div>
            {isLoading ? (
              <p>Loading events...</p>
            ) : events && events.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>EVENT ID</th>
                      <th>EVENT DATE</th>
                      <th>EVENT TIME</th>
                      <th>EVENT TYPE</th>
                      <th>EVENT DESCRIPTION</th>
                      <th>CLIENT NAME</th>
                      <th>SITE NAME</th>
                      <th>STAFF</th>
                      <th>CREATED BY</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.length > 0 ? (
                      currentEntries.map((event, index) => (
                        <tr key={index} style={{ fontSize: "13px" }}>
                          <td>{index + 1}</td>
                          <td>{event.event_date}</td>
                          <td>{event.event_time}</td>
                          <td>{event.event_type}</td>
                          <td>{event.event_details}</td>
                          <td>{event.event_connect_emp_subcon}</td>
                          <td>{event.event_connect_site_mark}</td>
                          <td>{event.staff}</td>
                          <td>{event.created_by}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-success me-1"
                              onClick={() => handleEventDetail(event)}>
                              <i
                                className="fas fa-eye"
                                style={{ color: "white" }}></i>
                            </button>
                            <button className="btn btn-sm btn-primary"
                              onClick={() => handleEditEvent(event)}
                            
                          >
                              <i
                                className="fas fa-edit"
                                style={{ color: "white" }}></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No employees found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No events found.</p>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing {indexOfFirstEntry + 1} to{" "}
                {Math.min(indexOfLastEntry, totalEvents.length)} of{" "}
                {totalEvents.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  {Array.from({
                    length: Math.ceil(totalEvents.length / eventsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}>
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Employee</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>From Date</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>To Date</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Reason</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Leave Type</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Comments</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredEvent
                  .slice(currentPage * eventsPerPage, currentPage * eventsPerPage + eventsPerPage)
                  .map((event, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * eventsPerPage + index + 1}</TableCell>
                    <TableCell >{event.employee}</TableCell>
                    <TableCell >{event.from_date}</TableCell>
                    <TableCell >{event.to_date}</TableCell>
                    <TableCell >{event.reason}</TableCell>
                    <TableCell >{event.leave_type}</TableCell>
                    <TableCell >{event.comments}</TableCell>
                    <TableCell >
  <IconButton 
                              onClick={() => handleEventDetail(event)}
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>


  <IconButton 
 onClick={() => handleEditEvent(event)} 
  size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <EditIcon />
  </IconButton>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEvent.length}
            rowsPerPage={eventsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEventsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
      
      <Modal show={showEventDetail} onHide={handleCloseEventDetail}>
        <Modal.Header closeButton>
          <Modal.Title>Holiday Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent ? (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Employee</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEvent.employee}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>From Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEvent.from_date}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>To Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEvent.to_date}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Reason</Form.Label>
                <Form.Control
as="textarea"              
    value={selectedEvent.reason}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Leave Type</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEvent.leave_type}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  value={selectedEvent.comments}
                  style={{ width: "100%" }}
                />
              </Form.Group>
           
            </Form>
          ) : (
            <p>Loading Holiday details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEventDetail}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HolidayRequest;
