import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import Dashboard from "../Dashboard/Dashboard";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  Image,
  Table,
} from "react-bootstrap";
import "./CompanyPage.css";
import defaultPreviewLogo from "../Dashboard/assets/media/logos/previewimg.jpg";
import NewSGSLogo from "../Settings/logos/NewSGSLogo.jpeg"
import {useLocation} from "react-router-dom";
import Swal from 'sweetalert2';



const CompanyDetails  = ({ isLoading: initialLoading, error: initialError, onUpdate }) => {
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    company_address: "",
    company_city: "",
    company_pcode: "",
    company_contact: "",
    company_fax: "",
    company_email: "",
    company_web: "",
    company_accname: "",
    company_accno: "",
    company_accscode: "",
    company_reg: "",
    company_vat_reg: "",
    company_vat_no: "",
    company_vat_per: "",
    // payeReferencecenter: "",
    compant_str_yr: "",
    // company_logo: "",
  });

  const [formDetails, setFormDetails] = useState([]);
  const [vatRegistered, setVatRegistered] = useState(false);
  const [printBankDetails, setPrintBankDetails] = useState(false);
  // const [previewLogo, setPreviewLogo] = useState(defaultPreviewLogo);
  const [isLoading, setIsLoading] = useState(initialLoading);
  const [error, setError] = useState(initialError);
  const [validationErrors, setValidationErrors] = useState({});

  const _token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

 
  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://srltd.megworld.in/api/company_settings",
        {
          headers: {
            Authorization: `Bearer ${_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Company Settings Response:",response)
      if (response.data.success) {
        // const companyData = response.data.data;
        // // setCompanyDetails(companyData);
        // setFormDetails(companyData);
        setFormDetails(response.data.data[0])
        setVatRegistered(formDetails.company_vat_reg === "Yes");
        setPrintBankDetails(formDetails.company_acc_print === "Yes");
        // setPreviewLogo(
        //   `https://srltd.megworld.in/api/images/${companyData.company_logo}` || NewSGSLogo
        // );
      } else {
        Swal.fire('Error', 'Failed to fetch company details', 'error');
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
      Swal.fire('Error', 'Failed to fetch company details', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // const handleLogoChange = (e) => {
  //   const file = e.target.files[0];
  //   setFormDetails((prevDetails) => ({
  //     ...prevDetails,
  //     company_logo: file,
  //   }));
  //   setPreviewLogo(URL.createObjectURL(file));
  // };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    if (name === "vatRegistered") {
      setVatRegistered(value === "Yes");
      setCompanyDetails((prevDetails) => ({
        ...prevDetails,
        company_vat_reg: value,
      }));
    } else if (name === "printBankDetails") {
      setPrintBankDetails(value === "Yes");
      setCompanyDetails((prevDetails) => ({
        ...prevDetails,
        company_acc_print: value,
      }));
    }
  };



  const validateForm = () => {
    const errors = {};

    if (!companyDetails.company_name) errors.company_name = "Company name is required";
    if (!companyDetails.company_email) errors.company_email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(companyDetails.company_email)) errors.company_email = "Email is invalid";
    if (!companyDetails.company_address) errors.company_address = "Address is required";
    if (!companyDetails.company_city) errors.company_city = "City is required";
    if (!companyDetails.company_fax) errors.company_fax = "Fax is required";
    if (!companyDetails.company_web) errors.company_web = "Web is required";
    if (!companyDetails.company_contact) errors.company_contact = "Contact is required";
    if (!companyDetails.company_pcode) errors.company_pcode = "Post Code is required";
    if (!companyDetails.company_vat_reg) errors.company_vat_reg = "VAT registration status is required";
    if (vatRegistered && !companyDetails.company_vat_no) errors.company_vat_no = "VAT number is required";
    if (vatRegistered && !companyDetails.company_vat_per) errors.company_vat_per = "VAT percentage is required";
    if (vatRegistered && companyDetails.company_vat_per && (companyDetails.company_vat_per < 0 || companyDetails.company_vat_per > 100)) errors.company_vat_per = "VAT percentage must be between 0 and 100";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateCompany = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      Swal.fire('Error', 'Please fill in all required fields correctly', 'error');
      return;
    }

    // setIsLoading(true);
    setError(null);

    // const formData = new FormData();
    // Object.keys(formDetails).forEach((key) => {
    //   formData.append(key, formDetails[key]);
    // });

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/company_store",
        companyDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${_token}`,
          },
        }
      );
      if (response.data.success) {
        Swal.fire('Success', 'Company details updated successfully!', 'success');
        setCompanyDetails({...companyDetails});
        if (onUpdate) onUpdate(companyDetails);
        fetchCompanyDetails(); // Refresh the data after update
      } else {
        Swal.fire('Error', 'Failed to update company details', 'error');
      }
    } catch (err) {
      console.error("Error updating company details:", err);
      Swal.fire('Error', 'Error updating company details', 'error');
    } finally {
      setIsLoading(false);
    }
  };




  const renderForm = (isReadOnly) => {
    return (
    <Form>
      {/* <Form.Group as={Row} className="mb-3" onChange={handleLogoChange}>
        <Form.Label column sm={4}>
          Company Logo
        </Form.Label>
        <Col sm={8}>
          {isReadOnly ? (
          <Col sm={8}>
          <Image
            src={NewSGSLogo}
            style={{ width: "100px", height: "auto",marginBottom:'20px' }}
            alt="SGS Security Logo"
          />
        </Col>
          ) : (
            <Form.Control
              type="file"
               name="company_logo"
              onChange={handleLogoChange}
            />
          )}
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Name
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="company_name"
            placeholder="Enter company name"
            value={CompanyDetails.company_name}
            onChange={ handleFormChange}
                        isInvalid={!!validationErrors.company_name}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Address
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_address"
            type="text"
            placeholder="Enter company address"
            value={CompanyDetails.company_address }
            onChange={ handleFormChange}            isInvalid={!!validationErrors.company_address}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          City
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_city"
            type="text"
            placeholder="Enter city"
            value={CompanyDetails.company_city }
            onChange={ handleFormChange}                isInvalid={!!validationErrors.company_city}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Postcode
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_pcode"
            type="text"
            placeholder="Enter postcode"
            value={CompanyDetails.company_pcode }
            onChange={ handleFormChange}            isInvalid={!!validationErrors.company_pcode}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Contact
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_contact"
            type="number"
            placeholder="Enter contact"
            value={CompanyDetails.company_contact }
            onChange={ handleFormChange}                  isInvalid={!!validationErrors.company_contact}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Fax
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_fax"
            type="text"
            placeholder="Enter fax"
            value={CompanyDetails.company_fax }
            onChange={ handleFormChange}                isInvalid={!!validationErrors.company_fax}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Email
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_email"
            type="email"
            placeholder="Enter email"
            value={CompanyDetails.company_email }
            onChange={ handleFormChange}  
            isInvalid={!!validationErrors.company_email}

          />
        </Col>
      </Form.Group>
    
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Website
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_web"
            type="text"
            placeholder="Enter website"
            value={CompanyDetails.company_web }
            onChange={ handleFormChange}  
            isInvalid={!!validationErrors.company_web}


          />
        </Col>
      </Form.Group>

     <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Account Title
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_accname"
            type="text"
            placeholder="Enter account title"
            value={CompanyDetails.company_accname }
            onChange={ handleFormChange} 
            isInvalid={!!validationErrors.company_accname}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Account Number
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_accno"
            type="text"
            placeholder="Enter account number"
            value={CompanyDetails.company_accno }
            onChange={ handleFormChange} 
            isInvalid={!!validationErrors.company_accno}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Sort Code
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_accscode"
            type="text"
            placeholder="Enter sort code"
            value={CompanyDetails.company_accscode}
            onChange={ handleFormChange} 
            isInvalid={!!validationErrors.company_accscode}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Company Registration
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name="company_reg"
            type="text"
            placeholder="Enter company registration"
            value={CompanyDetails.company_reg }
            onChange={ handleFormChange} 
            isInvalid={!!validationErrors.company_reg}

          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Company VAT Registered
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            inline
            label="Yes"
            type="radio"
            name="vatRegistered"
            id="vat-yes"
            value="Yes"
            checked={vatRegistered}
            onChange={handleRadioChange}
            disabled={isReadOnly}
          />
          <Form.Check
            inline
            label="No"
            type="radio"
            name="vatRegistered"
            id="vat-no"
            value="No"
            checked={!vatRegistered}
            onChange={handleRadioChange}
            disabled={isReadOnly}
          />
        </Col>
      </Form.Group>

      {vatRegistered && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              VAT Number
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="company_vat_no"
                type="text"
                placeholder="Enter VAT number"
                value={CompanyDetails.company_vat_no}
                onChange={ handleFormChange} 
                isInvalid={!!validationErrors.company_vat_no}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              VAT %
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="company_vat_per"
                type="number"
                placeholder="Enter VAT percentage"
                value={CompanyDetails.company_vat_per}
                onChange={ handleFormChange} 
                isInvalid={!!validationErrors.company_vat_per}

              />
            </Col>
          </Form.Group>
        </>
      )}
{/* 
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Print bank details on invoice?
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            inline
            label="Yes"
            type="radio"
            name="printBankDetails"
            id="print-bank-yes"
            value="Yes"
            checked={printBankDetails}
            onChange={handleRadioChange}
            disabled={isReadOnly}
          />
          <Form.Check
            inline
            label="No"
            type="radio"
            name="printBankDetails"
            id="print-bank-no"
            value="No"
            checked={!printBankDetails}
            onChange={handleRadioChange}
            disabled={isReadOnly}
          />
        </Col>
      </Form.Group> */}

      {/* <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          PAYE Reference Center
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            // name="payeReferencecenter"
            // placeholder="Enter PAYE reference"
            // value={CompanyDetails.payeReferencecenter }
                onChange={ handleFormChange} 
            // isInvalid={!!validationErrors.payeReferencecenter}

          />
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Start of Holiday Year
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="compant_str_yr"
            placeholder="Enter start of holiday year"
            value={CompanyDetails.compant_str_yr}
            onChange={ handleFormChange} 
            isInvalid={!!validationErrors.compant_str_yr}

          />
        </Col>
      </Form.Group>

      {!isReadOnly && (
        <>
          {/* {isLoading && <p>Loading...</p>} */}

          <Alert variant="danger">
            This may affect the total number of holidays already in this or
            previous year and some cases booked holidays might increase total
            entitlement.
          </Alert>

          <Button 
          style={{ width: "100%" }}
          type="button"
          className="mt-3 mb-20"
          onClick={handleUpdateCompany}
        >
          Update Company Details
        </Button>
        </>
      )}
    </Form>
  )}

  const renderTable = () => {
    return (
      <Table striped bordered hover >
        <tbody>
          <tr>
            <td colSpan="2" className="text-center">
              {/* <Image
                src={previewLogo}
                style={{ width: "100px", height: "auto" }}
                alt="Company Logo"
              /> */}
               <Image
            src={NewSGSLogo}
            style={{ width: "100px", height: "auto",marginBottom:'20px' }}
            alt="SGS Security Logo"
          />
            </td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Company Name</th>
            <td>{formDetails.company_name}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Address</th>
            <td>{formDetails.company_address}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>City</th>
            <td>{formDetails.company_city}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Postcode</th>
            <td>{formDetails.company_pcode}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Contact</th>
            <td>{formDetails.company_contact}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Fax</th>
            <td>{formDetails.company_fax}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Email</th>
            <td>{formDetails.company_email}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Website</th>
            <td>{formDetails.company_web}</td>
          </tr>
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>VAT Registered</th>
            <td>{formDetails.company_vat_reg}</td>
          </tr>
         
              <tr >
                <th style={{fontWeight:'bold',fontSize:'15px'}}>VAT Number</th>
                <td>{formDetails.company_vat_no}</td>
              </tr>
              <tr >
                <th style={{fontWeight:'bold',fontSize:'15px'}}>VAT Percentage</th>
                <td>{formDetails.company_vat_per}%</td>
              </tr>
            
        
          {/* <tr>
            <th>Print Bank Details on Invoice</th>
            <td>{companyDetails.printBankDetails}</td>
          </tr>
          <tr>
            <th>PAYE Reference Center</th>
            <td>{companyDetails.payeReferencecenter}</td>
          </tr> */}
          <tr>
            <th style={{fontWeight:'bold',fontSize:'15px'}}>Start of Holiday Year</th>
            <td>{formDetails.compant_str_yr}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Settings', bold: false },
      { name: 'Company Details', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  return (
    <>
      {/* <Dashboard /> */}
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>         </div>
         <Container>
        {isLoading && <p>Loading company details...</p>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Row>
          <Col md={6}>
            <h2 className="mb-4 head1 mt-20">Current Company Details</h2>
            {renderTable()}
          </Col>
          <Col md={6}>
            <h2 className="mb-4 head1 mt-20">Edit Company Details</h2>
            {renderForm()}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CompanyDetails;
